/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable complexity */
/* eslint-disable id-length */
/* eslint-disable max-lines */
/* eslint-disable no-restricted-imports */
/* eslint-disable react-hooks/exhaustive-deps */
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import LibraryMusicRoundedIcon from '@mui/icons-material/LibraryMusicRounded'
import { Avatar, Button, Card, CardActionArea, Chip, Grid, IconButton, List, ListItemAvatar, ListItemButton, ListItemText, Skeleton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import ReactDevicePreview from 'react-device-preview'
import { NavLink } from 'react-router-dom'

import { getSubscriberCount } from 'src/api/allLinkAnalytics'
import { getAnalyticsStatement, getNetOverall } from 'src/api/analytics'
import { getAnalyticsArtist, getAnalyticsArtistFree, getAnalyticsCurrentArtist, getAnalyticsCurrentArtistFree, getAnalyticsCurrentLabel, getAnalyticsLabel } from 'src/api/analyticsCloud'
import { createFirstBioLink, createMarketingLink, getBioLink } from 'src/api/links'
import { getProjects } from 'src/api/projects'
import FollowersDashboard from 'src/components/pages/Dashboard/Analytics/FollowersDashboard'
import StreamsDashboard from 'src/components/pages/Dashboard/Analytics/StreamsDashboard'
import TasksCard from 'src/components/pages/Dashboard/Tasks/TaskCard'
import { convertMonthToString } from 'src/components/pages/Earnings/EarningsDistribution/Overview/EarningsOverviewNewChart'
import EditLink from 'src/components/pages/Links/EditLink/EditLink'
import EditLinkRightTab from 'src/components/pages/Links/EditLink/EditLinkRightTab'
import UpgradeCTAButtons from 'src/components/pages/Menu/UpgradeCTA/UpgradeCTAButtons'
import NewProjectModal from 'src/components/pages/Projects/EditProject/NewProjectModal'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { AnalyticsCurrentData, AnalyticsHistoricData, AnalyticsMonthlyChart } from 'src/models/Analytics'
import type { MarketingAnalyticsString } from 'src/models/Marketing'
import { MarketingLink } from 'src/models/Marketing'
import { Permissions } from 'src/models/Organisation'
import type Project from 'src/models/Project'
import darkTheme from 'src/styles/dark.theme'
import lightTheme from 'src/styles/light.theme'
import SPACING from 'src/styles/spacing'

type Props = {
  readonly currentTheme: string
}

const Dashboard = (props: Props) => {
  const { currentAccountPermissions, currentOrganisation, currentAccount } = useAuth()
  const themeItem = useTheme()
  const mobile = useMediaQuery(themeItem.breakpoints.down('md'))
  const [_loading, setLoading] = useState(false)
  const [newProjectModalOpen, setNewProjectModalOpen] = useState(false)
  const [data, setData] = useState<Project[]>([])

  const [analyticsMonthlyChart, setAnalyticsMonthlyChart] = useState<AnalyticsMonthlyChart[]>([])
  const [netIncome, setNetIncome] = useState<AnalyticsMonthlyChart[]>([])
  const [subscribers, setSubscribers] = useState<MarketingAnalyticsString[]>([])

  const arrayGross = analyticsMonthlyChart.map(item => ({
    x: `01${item._id.slice(2).toUpperCase()}`,
    y: item.amount,
  })).sort((a, b) => Number(new Date(a.x)) - Number(new Date(b.x)))

  const arrayNet = netIncome.map(item => {
    const dateItem = new Date(item._id)
    const newDate = new Date(dateItem.setMonth(dateItem.getMonth() - 2))
    const stringItem = `01-${convertMonthToString((newDate.getMonth() + 1).toString())}-${newDate.getFullYear()}`

    return {
      x: stringItem,
      y: item.amount,
    }
  }).sort((a, b) => Number(new Date(a.x)) - Number(new Date(b.x)))

  const arrayTotal = arrayGross.map(item => {
    const amountItem = arrayNet.find(arrayItem => arrayItem.x === item.x)?.y ?? 0
    return {
      x: item.x,
      y: amountItem,
    }
  })

  const [linkData, setLinkData] = useState<MarketingLink>()
  const [editLinkModal, setEditLinkModal] = useState(false)
  const [biolinkLoading, setBiolinkLoading] = useState(false)

  const activeTheme = linkData?.palette !== 'Dark'
    ? lightTheme
    : darkTheme

  const totalEarnings = arrayTotal.map(item => item.y)
    .reduce((partialSum, a) => partialSum + a, 0)

  const getBiolinkItem = async () => {
    setBiolinkLoading(true)
    await getBioLink()
      .then(setLinkData)
      .finally(() => setBiolinkLoading(false))
      .catch(() => setBiolinkLoading(false))
  }

  const clickBioLink = () => {
    if (!biolinkLoading && !linkData) {
      void createBioLink()
    } else if (!biolinkLoading) {
      setEditLinkModal(true)
    }
  }

  const createBioLink = async () => {
    if (currentOrganisation) {
      setBiolinkLoading(true)
      await createMarketingLink(new MarketingLink({
        type: 'Biolink',
        title: currentOrganisation.name,
        domain: '',
        descriptionText: 'This is my bio!',
        artworkUrl: currentOrganisation.logoUrl,
        useOrgDefaultSocialIcons: true,
        theme: 'Subtle',
        url: '',
        releaseDate: new Date(),
        useDefaultPixels: currentOrganisation.membershipTier === 'CREATOR_PRO' ||
        currentOrganisation.membershipTier === 'CREATOR_PRO_YEARLY' ||
        currentOrganisation.membershipTier === 'ENTERPRISE' ||
        currentOrganisation.membershipTier === 'ENTERPRISE_YEARLY',
      }))
        .then(() => {
          void createFirstBioLink()
            .catch(() => null)
          void getBiolinkItem()
            .then(() => setEditLinkModal(true))
        })
        .finally(() => setBiolinkLoading(false))
    }
  }

  useEffect(() => {
    void getBiolinkItem()
  }, [currentOrganisation?.id])

  useEffect(() => {
    void getNetOverall()
      .then(item => setNetIncome(item))
      .catch(() => null)
    void getAnalyticsStatement([])
      .then(item => setAnalyticsMonthlyChart(item))
      .catch(() => null)
  }, [])

  useEffect(() => {
    void getSubscriberCount()
      .then(setSubscribers)
      .catch(() => null)
  }, [currentOrganisation?.id])

  useEffect(() => {
    setLoading(true)
    getProjects().then(result => {
      setData(result.slice(0, 5))
    }).finally(() => setLoading(false))
  }, [currentOrganisation?.id])

  const [historicData, setHistoricData] = useState<AnalyticsHistoricData>()
  const [currentData, setCurrentData] = useState<AnalyticsCurrentData>()

  useEffect(() => {
    if (currentOrganisation?.analyticsOnboarded &&
      currentOrganisation.membershipTier !== 'FREE') {
      getData()
    } else {
      getDataFree()
    }
  }, [currentOrganisation])

  const getData = () => {
    if (currentOrganisation?.analyticsOnboarded &&
      currentOrganisation.analyticsType === 'artist' &&
      currentOrganisation.analyticsArtistId &&
      currentOrganisation.analyticsArtistId.length > 0) {
      void getAnalyticsArtist()
        .then(result =>
          setHistoricData(result))
        .catch(() => null)
      void getAnalyticsCurrentArtist()
        .then(resultCurrent =>
          setCurrentData(resultCurrent))
        .catch(() => null)
    } else if (currentOrganisation?.analyticsOnboarded &&
      currentOrganisation.analyticsType === 'label' &&
      currentOrganisation.analyticsLabelId &&
      currentOrganisation.analyticsLabelId.length > 0) {
      void getAnalyticsLabel()
        .then(result =>
          setHistoricData(result))
        .catch(() => null)
      void getAnalyticsCurrentLabel()
        .then(resultCurrent =>
          setCurrentData(resultCurrent))
        .catch(() => null)
    }
  }

  const getDataFree = () => {
    void getAnalyticsArtistFree()
      .then(result =>
        setHistoricData(result))
      .catch(() => null)
    void getAnalyticsCurrentArtistFree()
      .then(resultCurrent =>
        setCurrentData(resultCurrent))
  }

  const totalSubsString = subscribers.map(item => item.count)
    .reduce((partialSum, a) => partialSum + a, 0)
    .toLocaleString()
  const totalEarningsString = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
    .format(totalEarnings)

  const stringItem = currentAccountPermissions?.includes(Permissions.VIEW_EARNINGS) &&
    currentAccountPermissions.includes(Permissions.VIEW_MARKETING_LINKS)
    ? `You got ${totalSubsString} new subscribers and generated ${totalEarningsString} since joining Releese`
    : currentAccountPermissions?.includes(Permissions.VIEW_EARNINGS) &&
        !currentAccountPermissions.includes(Permissions.VIEW_MARKETING_LINKS)
      ? `You generated ${totalEarningsString} since joining Releese`
      : currentAccountPermissions?.includes(Permissions.VIEW_MARKETING_LINKS) &&
        !currentAccountPermissions.includes(Permissions.VIEW_EARNINGS)
        ? `You got ${totalSubsString} new subscribers since joining Releese`
        : 'We hope you enjoy your day'

  return (
    <>
      <Stack
        alignItems='center'
        height={1}
        overflow='auto'
        paddingBottom={2}
        sx={{
          padding: SPACING.DASHBOARD_CONTOUR,
        }}
        width={1}
      >
        <Stack
          alignItems='center'
          spacing={2}
          sx={{
            paddingBottom: SPACING.RESPONSIVE_CONTOUR,
            paddingRight: SPACING.RESPONSIVE_CONTOUR,
          }}
          width={1}
        >
          <Stack direction={mobile ? 'column-reverse' : 'row'} spacing={2} width={1}>
            <Grid
              container
              height='fit-content'
              spacing={2}
              sx={{
                marginTop: '-16px!important',
                marginLeft: '-16px!important',
              }}
            >
              {!mobile &&
              <Grid item xs={12}>
                <Card
                  sx={{
                    boxShadow: 'none',
                    background: props.currentTheme === 'dark'
                      ? '#271d42'
                      : '#e0d6fa',
                  }}
                >
                  <Stack
                    direction={mobile ? 'column' : 'row'}
                    padding={2}
                    spacing={2}
                  >
                    <Stack
                      marginY='auto'
                    >
                      <Avatar
                        src={currentOrganisation?.logoUrl}
                        sx={{
                          height: 128,
                          width: 128,
                        }}
                      />
                    </Stack>
                    <Stack
                      height={1}
                      marginY='auto'
                      spacing={1}
                      width={1}
                    >
                      <Typography
                        lineHeight={1.2}
                        variant='h2'
                      >
                        Welcome back,
                        {' '}
                        {currentAccount.firstName}
                      </Typography>
                      <Typography
                        color={themeItem.palette.text.primary}
                        lineHeight={1.2}
                        variant='h4'
                      >
                        {stringItem}
                      </Typography>
                      <Typography
                        color={themeItem.palette.text.secondary}
                        lineHeight={1.2}
                        variant='body2'
                      >
                        To increase your streams, start collecting Subscribers on your Smart Links, Pre-saves Links and
                        Bio Link. Subscribers pre-save all your future releases in one click.
                      </Typography>
                      <Button
                        component={NavLink}
                        disabled={!currentAccountPermissions?.includes(Permissions.VIEW_MARKETING_LINKS)}
                        endIcon={<ArrowForwardIosRoundedIcon />}
                        sx={{
                          width: 'fit-content',
                        }}
                        to='/links'
                      >
                        Visit marketing suite
                      </Button>
                    </Stack>
                  </Stack>
                </Card>
              </Grid>}

              {currentAccountPermissions?.includes(Permissions.VIEW_ANALYTICS) &&
              <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
                <Card sx={{ boxShadow: 'none' }}>
                  <Stack direction='row' justifyContent='space-between' padding={2}>
                    <Stack alignItems='center' direction='row' spacing={1}>
                      <Stack>
                        <Typography lineHeight={1.2} variant='h3'>
                          Followers
                        </Typography>
                        <Typography
                          color={themeItem.palette.text.secondary}
                          lineHeight={1.2}
                          variant='body2'
                        >
                          All time
                        </Typography>
                      </Stack>
                      {currentOrganisation &&
                    (currentOrganisation.membershipTier === 'FREE' || !currentOrganisation.analyticsOnboarded)
                        ? <Chip
                          color='primary'
                          label='Sample data'
                          size='small'
                        />
                        : null}
                    </Stack>

                    <IconButton component={NavLink} to='/audience/overview'>
                      <ArrowForwardIosRoundedIcon />
                    </IconButton>
                  </Stack>
                  {currentData && historicData
                    ? <FollowersDashboard
                      currentData={currentData}
                      historicData={historicData}
                    />
                    : <Stack width={1}>
                      <Typography lineHeight={1.1} textAlign='center' variant='h2'>
                        <Skeleton sx={{ marginX: 'auto' }} width='140px' />
                      </Typography>

                      <Skeleton height='240px' variant='rounded' width='100%' />
                    </Stack>}
                </Card>
              </Grid>}

              {currentAccountPermissions?.includes(Permissions.VIEW_ANALYTICS) &&
              <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
                <Card sx={{ boxShadow: 'none' }}>
                  <Stack direction='row' justifyContent='space-between' padding={2}>
                    <Stack alignItems='center' direction='row' spacing={1}>
                      <Stack>
                        <Typography lineHeight={1.2} variant='h3'>
                          Streams
                        </Typography>
                        <Typography
                          color={themeItem.palette.text.secondary}
                          lineHeight={1.2}
                          variant='body2'
                        >
                          All time
                        </Typography>
                      </Stack>
                      {currentOrganisation &&
                    (currentOrganisation.membershipTier === 'FREE' || !currentOrganisation.analyticsOnboarded)
                        ? <Chip
                          color='success'
                          label='Sample data'
                          size='small'
                        />
                        : null}
                    </Stack>
                    <IconButton component={NavLink} to='/audience/overview'>
                      <ArrowForwardIosRoundedIcon />
                    </IconButton>
                  </Stack>
                  {currentData && historicData
                    ? <StreamsDashboard
                      currentData={currentData}
                      historicData={historicData}
                    />
                    : <Stack width={1}>
                      <Typography lineHeight={1.1} textAlign='center' variant='h2'>
                        <Skeleton sx={{ marginX: 'auto' }} width='140px' />
                      </Typography>

                      <Skeleton height='240px' variant='rounded' width='100%' />
                    </Stack>}
                </Card>
              </Grid>}

              {currentAccountPermissions?.includes(Permissions.VIEW_TASKBOARDS) &&
              <Grid item xs={12}>
                <Card sx={{ boxShadow: 'none' }}>
                  <Stack padding={2}>
                    <Typography lineHeight={1.2} variant='h3'>
                      Tasks
                    </Typography>
                  </Stack>

                  <TasksCard />
                </Card>
              </Grid>}
            </Grid>

            <Stack direction='column' spacing={2} width={mobile ? 1 : 420}>
              {mobile &&
              <Card
                sx={{
                  boxShadow: 'none',
                  background: props.currentTheme === 'dark'
                    ? '#271d42'
                    : '#e0d6fa',
                }}
              >
                <Stack
                  direction='column'
                  padding={2}
                  spacing={2}
                >
                  <Stack
                    marginY='auto'
                  >
                    <Avatar
                      src={currentOrganisation?.logoUrl}
                      sx={{
                        height: 64,
                        width: 64,
                      }}
                    />
                  </Stack>
                  <Stack
                    height={1}
                    marginY='auto'
                    spacing={1}
                    width={1}
                  >
                    <Typography
                      lineHeight={1.2}
                      variant='h2'
                    >
                      Welcome back,
                      {' '}
                      {currentAccount.firstName}
                    </Typography>
                    <Typography
                      color={themeItem.palette.text.primary}
                      lineHeight={1.2}
                      variant='h4'
                    >
                      {stringItem}
                    </Typography>
                    <Typography
                      color={themeItem.palette.text.secondary}
                      lineHeight={1.2}
                      variant='body2'
                    >
                      To increase your streams, start collecting Subscribers on your Smart Links, Pre-saves Links and
                      Bio Link. Subscribers pre-save all your future releases in one click.
                    </Typography>
                    <Button
                      component={NavLink}
                      disabled={!currentAccountPermissions?.includes(Permissions.VIEW_MARKETING_LINKS)}
                      endIcon={<ArrowForwardIosRoundedIcon />}
                      fullWidth={mobile}
                      sx={{
                        width: mobile ? 1 : 'fit-content',
                      }}
                      to='/links'
                    >
                      Visit marketing suite
                    </Button>
                    {mobile &&
                      <UpgradeCTAButtons />}
                  </Stack>
                </Stack>
              </Card>}

              {currentAccountPermissions?.includes(Permissions.VIEW_PROJECTS) &&
              <Card sx={{ boxShadow: 'none' }}>
                <Stack paddingTop={2}>
                  <Typography lineHeight={1.2} sx={{ paddingX: 2 }} variant='h2'>
                    Releases
                  </Typography>

                  <List>
                    {data.map(project =>
                      <ListItemButton
                        component={NavLink}
                        key={`project-${project.id}`}
                        sx={{
                          borderRadius: '0px!important',
                          borderBottom: theme => `1px solid ${theme.palette.divider}`,
                        }}
                        to={`/project/${project.id}`}
                      >
                        <ListItemAvatar>
                          <Avatar src={project.artworkPreviewUrl} sx={{ borderRadius: '4px' }} variant='rounded'>
                            <LibraryMusicRoundedIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={project.title}
                          secondary={project.primaryArtists.map(item => item.name).join(', ')}
                        />
                        <IconButton size='small'>
                          <ArrowForwardIosRoundedIcon />
                        </IconButton>
                      </ListItemButton>)}
                    <ListItemButton
                      component={NavLink}
                      sx={{
                        borderRadius: '0px!important',
                        borderBottom: theme => `1px solid ${theme.palette.divider}`,
                      }}
                      to='/projects/projects'
                    >
                      <ListItemText
                        primary='View all'
                      />
                      <IconButton size='small'>
                        <ArrowForwardIosRoundedIcon />
                      </IconButton>
                    </ListItemButton>
                    <ListItemButton
                      disabled={!currentAccountPermissions.includes(Permissions.CREATE_EDIT_REMOVE_PROJECTS)}
                      onClick={() => setNewProjectModalOpen(true)}
                      sx={{
                        borderRadius: '0px!important',
                      }}
                    >
                      <ListItemText
                        primary='Create release'
                      />
                      <IconButton size='small'>
                        <AddRoundedIcon />
                      </IconButton>
                    </ListItemButton>
                  </List>
                </Stack>
              </Card>}

              {currentAccountPermissions?.includes(Permissions.MANAGE_PAYOUTS) &&
              <Card sx={{ boxShadow: 'none' }}>
                <Stack padding={2} spacing={2}>
                  <Typography lineHeight={1.2} variant='h2'>
                    Balance
                  </Typography>

                  <Card
                    elevation={0}
                    sx={{
                      background: 'linear-gradient(150deg, rgba(251,66,131,1) 0%, rgba(255,144,46,1) 91%)',
                    }}
                  >
                    <Stack padding={2} spacing={2}>
                      <Typography sx={{ color: theme => theme.palette.primary.contrastText }} variant='body3'>
                        RELEESE INNOVATIONS
                      </Typography>
                      <Typography sx={{ color: theme => theme.palette.primary.contrastText }} variant='h2'>
                        {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                          .format(currentOrganisation?.balance ?? 0)}
                      </Typography>
                      <Typography sx={{ color: theme => theme.palette.primary.contrastText }} variant='body3'>
                        {'Member since '}
                        {dayjs(currentAccount.createdAt).format('MMMM YYYY')}
                      </Typography>
                    </Stack>
                  </Card>

                  <Button
                    component={NavLink}
                    disabled={(currentOrganisation?.balance ?? 0) === 0}
                    to='/wallet'
                  >
                    Withdraw
                  </Button>
                </Stack>
              </Card>}

              {currentAccountPermissions?.includes(Permissions.VIEW_MARKETING_LINKS) &&
              <Card sx={{ boxShadow: 'none' }}>
                <CardActionArea onClick={event => {
                  event.stopPropagation()
                  event.preventDefault()
                  clickBioLink()
                }}
                >
                  <Stack
                    height={460}
                    padding={2}
                    spacing={2}
                    sx={{
                      pointerEvents: 'none',
                    }}
                  >
                    <Typography lineHeight={1.2} variant='h2'>
                      Biolink
                    </Typography>
                    <Stack
                      alignItems='flex-start'
                      direction='row'
                      flexShrink={0}
                      height='100%'
                      paddingBottom={3}
                      paddingX={2}
                      position='relative'
                      right={0}
                      sx={{
                        marginLeft: 'auto!important',
                        marginRight: 'auto!important',
                      }}
                      width={327}
                    >
                      <Stack
                        height={665}
                        width='100%'
                      >
                        <ReactDevicePreview
                          device='iphonex'
                          scale='0.7'
                        >
                          {biolinkLoading
                            ? <Stack
                              alignItems='center'
                              flexGrow={0}
                              flexShrink={0}
                              height={750}
                              position='relative'
                              sx={{
                                overflowY: 'auto',
                                overflowX: 'hidden',
                                '::-webkit-scrollbar': {
                                  display: 'none',
                                },
                              }}
                              top={0}
                              width={1}
                            >
                              <Skeleton
                                sx={{
                                  width: '100%',
                                  height: 750,
                                  backgroundColor: theme => theme.palette.background.elevatedCard,
                                  position: 'relative',
                                  transform: 'none!important',
                                }}
                              />
                            </Stack>
                            : <>
                              {linkData?.theme !== 'Vibrant' && <Stack
                                sx={{
                                  position: 'absolute',
                                  height: '100%',
                                  width: '100%',
                                  background: `url(${linkData?.artworkUrl ?? currentOrganisation?.logoUrl})`,
                                  backgroundSize: 'cover',
                                  backgroundPosition: 'center',
                                  filter: 'blur(60px)',
                                  zIndex: 2,
                                  transform: 'scale(1.5)',
                                  opacity: 0.6,
                                  minWidth: 1920,
                                  left: 'calc(50% - 980px)',
                                  backgroundPositionX: 'center',
                                  backgroundPositionY: 'center',
                                  backgroundRepeat: 'no-repeat',
                                }}
                              />}
                              <Stack
                                alignItems='center'
                                flexGrow={0}
                                flexShrink={0}
                                height={750}
                                position='relative'
                                sx={{
                                  background: linkData?.theme !== 'Vibrant'
                                    ? activeTheme.palette.background.default
                                    : linkData?.backgroundColor,
                                  overflowY: 'auto',
                                  overflowX: 'hidden',
                                  '::-webkit-scrollbar': {
                                    display: 'none',
                                  },
                                }}
                                top={0}
                                width={1}
                              >
                                {currentOrganisation &&
                                <EditLinkRightTab
                                  artworkPreview={linkData?.artworkUrl ?? currentOrganisation.logoUrl}
                                  organisation={currentOrganisation}
                                  smartLinkData={linkData ?? new MarketingLink({
                                    title: currentOrganisation.name,
                                    artworkUrl: currentOrganisation.logoUrl,
                                    type: 'Biolink',
                                    theme: 'Subtle',
                                    palette: 'Light',
                                  })}
                                />}
                              </Stack>
                            </>}
                        </ReactDevicePreview>
                      </Stack>
                    </Stack>
                  </Stack>
                </CardActionArea>
              </Card>}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      {newProjectModalOpen && currentAccountPermissions?.includes(Permissions.CREATE_EDIT_REMOVE_PROJECTS) &&
      <NewProjectModal
        close={() => setNewProjectModalOpen(false)}
        open={newProjectModalOpen}
      />}
      {editLinkModal && linkData &&
      <EditLink
        close={() => setEditLinkModal(false)}
        link={linkData}
        open={editLinkModal}
        tab={0}
        updateLinks={getBiolinkItem}
      />}
    </>
  )
}

export default Dashboard
