/* eslint-disable max-len */
/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Skeleton, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'

import { getAnalyticsStatement, getAnalyticsStatementStores, getAnalyticsStatementTerritory, getAnalyticsStatementTopReleases } from 'src/api/analytics'
import { getDistributionByProjectId } from 'src/api/distribution'
import AnalyticsLocation from 'src/components/pages/Analytics/AnalyticsDistribution/Overview/AnalyticsLocation'
import AnalyticsStatementArea from 'src/components/pages/Analytics/AnalyticsDistribution/Overview/AnalyticsStatementArea'
import AnalyticsCloudChart from 'src/components/pages/Analytics/AnalyticsDistribution/Overview/AnalyticsTableChart'
import AnalyticsDistributionOverview from 'src/components/pages/Analytics/AnalyticsDistribution/Overview/AnalyticsTotalOverview'
import DistributionStoreChart from 'src/components/pages/Analytics/AnalyticsDistribution/Overview/DistributionStoreChart'
import SelectPeriod from 'src/components/pages/Links/ViewLink/ViewLinkComponents/TimePeriodSelect/SelectEarningsPeriod'
import SelectServices from 'src/components/pages/Links/ViewLink/ViewLinkComponents/TimePeriodSelect/SelectService'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { AnalyticsMonthlyChart } from 'src/models/Analytics'
import type Project from 'src/models/Project'
import type Recording from 'src/models/Recording'
import SPACING from 'src/styles/spacing'

type Props = {
  project?: Project
  selectedRecording?: string
}

const AnalyticsStatement = (props: Props) => {
  const [loadingMonth, setLoadingMonth] = useState(false)
  const [loadingTerritory, setLoadingTerritory] = useState(false)
  const [loadingStores, setLoadingStores] = useState(false)

  const selectedRecording = props.selectedRecording ?? ''

  const { currentOrganisation } = useAuth()
  const [distributionItems, setDistributionItems] = useState<Recording[]>()
  const [stores, setStores] = useState<string[]>([])
  const [periods, setPeriods] = useState<string[]>([])

  const [analyticsMonthlyChart, setAnalyticsMonthlyChart] = useState<AnalyticsMonthlyChart[]>([])
  const [analyticsTerritoryChart, setAnalyticsTerritoriesChart] = useState<AnalyticsMonthlyChart[]>([])
  const [analyticsStoresChart, setAnalyticsStoresChart] = useState<AnalyticsMonthlyChart[]>([])
  const [topReleases, setTopReleases] = useState<AnalyticsMonthlyChart[]>([])

  const filteredItems = distributionItems?.filter(item => item.masterRightsHolders.some(object => object.account.id === currentOrganisation?.id))

  useEffect(() => {
    if (props.project?.distributed) {
      void getDistributionByProjectId(props.project.id)
        .then(item => {
          setDistributionItems(item.recordings)
        })
    }
  }, [props.project, currentOrganisation?.id])

  useEffect(() => {
    getData()
  }, [stores, distributionItems, periods, currentOrganisation?.id])

  const getData = () => {
    if (selectedRecording.length > 0) {
      setLoadingMonth(true)
      setLoadingTerritory(true)
      setLoadingStores(true)

      void getAnalyticsStatement(stores, [selectedRecording])
        .then(item => setAnalyticsMonthlyChart(item))
        .then(() => setLoadingMonth(false))

      void getAnalyticsStatementTerritory(stores, periods, [selectedRecording])
        .then(item => setAnalyticsTerritoriesChart(item))
        .then(() => setLoadingTerritory(false))

      if (stores.length === 0) {
        void getAnalyticsStatementStores(periods, [selectedRecording])
          .then(item => setAnalyticsStoresChart(item))
          .then(() => setLoadingStores(false))
      }
      setTopReleases([])
    } else if (distributionItems && filteredItems && filteredItems.length > 0 && props.project) {
      setLoadingMonth(true)
      setLoadingTerritory(true)
      setLoadingStores(true)

      void getAnalyticsStatement(stores, filteredItems.map(item => item.ISRC), props.project.id)
        .then(item => setAnalyticsMonthlyChart(item))
        .then(() => setLoadingMonth(false))

      void getAnalyticsStatementTerritory(stores, periods, filteredItems.map(item => item.ISRC), props.project.id)
        .then(item => setAnalyticsTerritoriesChart(item))
        .then(() => setLoadingTerritory(false))

      void getAnalyticsStatementTopReleases(stores, periods, filteredItems.map(item => item.ISRC), props.project.id)
        .then(item => setTopReleases(item))

      if (stores.length === 0) {
        void getAnalyticsStatementStores(periods, filteredItems.map(item => item.ISRC), props.project.id)
          .then(item => setAnalyticsStoresChart(item))
          .then(() => setLoadingStores(false))
      }
    } else if (!props.project) {
      setLoadingMonth(true)
      setLoadingTerritory(true)
      setLoadingStores(true)

      void getAnalyticsStatement(stores)
        .then(item => setAnalyticsMonthlyChart(item))
        .then(() => setLoadingMonth(false))

      void getAnalyticsStatementTerritory(stores, periods)
        .then(item => setAnalyticsTerritoriesChart(item))
        .then(() => setLoadingTerritory(false))

      void getAnalyticsStatementTopReleases(stores, periods)
        .then(item => setTopReleases(item))

      if (stores.length === 0) {
        void getAnalyticsStatementStores(periods)
          .then(item => setAnalyticsStoresChart(item))
          .then(() => setLoadingStores(false))
      }
    }
  }

  return (
    <Stack alignItems='flex-start' direction='row' height={1} width={1}>
      <Stack
        alignItems='stretch'
        boxSizing='border-box'
        direction='column'
        height={1}
        minWidth={0}
        padding={0}
        width={1}
      >
        <Stack height={1} width={1}>

          <Stack height={1} overflow='auto' width={1}>
            <Stack
              alignItems='center'
              justifyContent='center'
              marginLeft='auto'
              marginRight='auto'
              maxWidth={1332}
              padding={SPACING.CONTENT_PADDING}
              width={1}
            >
              <Stack direction='row' paddingBottom={2} spacing={1} width={1}>
                <SelectServices
                  destinations={analyticsStoresChart.map(item => item._id)}
                  services={stores}
                  setServices={setStores}
                />
                <SelectPeriod
                  periodOptions={analyticsMonthlyChart.map(item => item._id)}
                  periods={periods}
                  setPeriods={setPeriods}
                />
              </Stack>

              <Stack width={1}>
                <Typography variant='h3'>
                  Analytics
                </Typography>

                {loadingMonth
                  ? <Skeleton height={400} variant='rounded' width='100%'>
                    <div />
                  </Skeleton>
                  : <AnalyticsStatementArea
                    data={analyticsMonthlyChart}
                    source=''
                  />}

                {loadingMonth
                  ? <Stack paddingY={2} spacing={3} width={1}>
                    <Grid container spacing={2} sx={{ marginLeft: '-16px!important' }}>
                      {Array.from({ length: 4 }, () =>
                        <Grid item lg={3} md={6} sm={12} xs={12}>
                          <Skeleton variant='rounded' width='100%'>
                            <div style={{ paddingTop: '57%' }} />
                          </Skeleton>
                        </Grid>)}
                    </Grid>
                    <Stack spacing={2} width={1}>
                      <Typography variant='h3'>
                        <Skeleton variant='rounded' width='128px' />
                      </Typography>
                      <Skeleton variant='rounded' width='100%' />
                      <Skeleton variant='rounded' width='100%' />
                      <Skeleton variant='rounded' width='100%' />
                      <Skeleton variant='rounded' width='100%' />
                      <Skeleton variant='rounded' width='100%' />
                      <Skeleton variant='rounded' width='100%' />
                      <Skeleton variant='rounded' width='100%' />
                    </Stack>
                  </Stack>
                  : <AnalyticsDistributionOverview
                    data={analyticsMonthlyChart}
                    periods={periods}
                    selectedRecording={selectedRecording}
                    topReleases={topReleases}
                  />}

              </Stack>
              {stores.length === 0 &&
              <>
                {loadingStores
                  ? <Stack paddingY={2} spacing={2} width={1}>
                    <Typography variant='h3'>
                      <Skeleton variant='rounded' width='128px' />
                    </Typography>
                    <Skeleton height={400} variant='rounded' width='100%'>
                      <div />
                    </Skeleton>
                  </Stack>
                  : <DistributionStoreChart
                    data={analyticsStoresChart}
                  />}
                {loadingStores
                  ? <Stack paddingY={2} spacing={2} width={1}>
                    <Typography variant='h3'>
                      <Skeleton variant='rounded' width='128px' />
                    </Typography>
                    <Skeleton variant='rounded' width='100%' />
                    <Skeleton variant='rounded' width='100%' />
                    <Skeleton variant='rounded' width='100%' />
                    <Skeleton variant='rounded' width='100%' />
                    <Skeleton variant='rounded' width='100%' />
                    <Skeleton variant='rounded' width='100%' />
                    <Skeleton variant='rounded' width='100%' />
                  </Stack>
                  : <AnalyticsCloudChart
                    data={analyticsStoresChart}
                    stores
                  />}
              </>}

              {loadingTerritory
                ? <Stack paddingY={2} spacing={2} width={1}>
                  <Typography variant='h3'>
                    <Skeleton variant='rounded' width='128px' />
                  </Typography>
                  <Skeleton height={400} variant='rounded' width='100%'>
                    <div />
                  </Skeleton>
                </Stack>
                : <AnalyticsLocation
                  data={analyticsTerritoryChart}
                />}

              {loadingTerritory
                ? <Stack paddingY={2} spacing={2} width={1}>
                  <Typography variant='h3'>
                    <Skeleton variant='rounded' width='128px' />
                  </Typography>
                  <Skeleton variant='rounded' width='100%' />
                  <Skeleton variant='rounded' width='100%' />
                  <Skeleton variant='rounded' width='100%' />
                  <Skeleton variant='rounded' width='100%' />
                  <Skeleton variant='rounded' width='100%' />
                  <Skeleton variant='rounded' width='100%' />
                  <Skeleton variant='rounded' width='100%' />
                </Stack>
                : <AnalyticsCloudChart
                  data={analyticsTerritoryChart}
                  location
                />}

            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default AnalyticsStatement
