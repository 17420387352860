/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable no-duplicate-imports */
/* eslint-disable react/no-unstable-nested-components */
import { useTheme } from '@mui/material'
import type { ReactourStep, ReactourStepContentArgs } from 'reactour'
import Tour from 'reactour'

import TourStep from 'src/components/pages/Tour/TourComponents/TourStep'
import Image1 from 'src/images/Onboarding/Marketing/marketing-1.webp'
import Image2 from 'src/images/Onboarding/Marketing/marketing-2.webp'
import Image3 from 'src/images/Onboarding/Marketing/marketing-3.webp'

type Props = {
  open: boolean
  closeTour: () => void
  finalEvent: () => void
  loading: boolean
}

const MarketingTour = (props: Props) => {
  const theme = useTheme()

  const steps: ReactourStep[] = [
    {
      content: ({ close, goTo, step }: ReactourStepContentArgs) =>
        <TourStep
          close={close}
          description='Smart Links are the interface between your fans and your releases: You can
          send followers to their preferred service, engage them with pre-saves or redirect them with short links.'
          final={false}
          finalAction={props.finalEvent}
          finalText='Got it'
          first
          goTo={goTo}
          item={<img
            alt='Dashboard'
            height='1562px'
            loading='lazy'
            src={Image1}
            style={{
              marginBottom: '-16px',
              width: '100%',
              height: 'auto',
              transform: 'translateZ(0)',
            }}
            width='2083px'
          />}
          loading={props.loading}
          step={step}
          title='Reach your fans'
        />
      ,
      position: 'right',
      selector: '#project-tabs',
      style: {
        width: 500,
        maxWidth: 500,
        backgroundColor: 'transparent',
        padding: 0,
      },
    },
    {
      content: ({ close, goTo, step }: ReactourStepContentArgs) =>
        <TourStep
          close={close}
          description='Discover where your fans are, which songs they interact with, and how they engage with you
          using your tracking dashboard. Use this data to plan your advertising and marketing strategies.'
          final={false}
          finalAction={props.finalEvent}
          finalText='Got it'
          goTo={goTo}
          item={<img
            alt='Dashboard'
            height='1562px'
            loading='lazy'
            src={Image2}
            style={{
              marginBottom: '-16px',
              width: '100%',
              height: 'auto',
              transform: 'translateZ(0)',
            }}
            width='2083px'
          />}
          loading={props.loading}
          step={step}
          title='Understand your fanbase'
        />
      ,
      position: 'right',
      selector: '#project-tabs',
      style: {
        width: 500,
        maxWidth: 500,
        backgroundColor: 'transparent',
        padding: 0,
      },
    },
    {
      content: ({ close, goTo, step }: ReactourStepContentArgs) =>
        <TourStep
          close={close}
          description='Leverage Pre-saves and Subscriptions to turn your followers into monetized fans:
          Every pre-save event will make your social fans automatically follow you on Spotify, Apple Music,
          or Deezer profile.'
          final
          finalAction={props.finalEvent}
          finalText='Create a landing page'
          goTo={goTo}
          item={<img
            alt='Dashboard'
            height='1562px'
            loading='lazy'
            src={Image3}
            style={{
              marginBottom: '-16px',
              width: '100%',
              height: 'auto',
              transform: 'translateZ(0)',
            }}
            width='2083px'
          />}
          loading={props.loading}
          step={step}
          title='Grow your fanbase'
        />
      ,
      position: 'right',
      selector: '#project_todo',
      style: {
        width: 500,
        maxWidth: 500,
        backgroundColor: 'transparent',
        padding: 0,
      },
    },
  ]

  return (
    <Tour
      accentColor={theme.palette.primary.main}
      closeWithMask={false}
      disableDotsNavigation
      disableFocusLock
      disableKeyboardNavigation
      isOpen={props.open}
      onRequestClose={props.closeTour}
      rounded={16}
      showButtons={false}
      showCloseButton={false}
      showNavigation={false}
      showNavigationNumber={false}
      showNumber={false}
      steps={steps}
    />
  )
}

export default MarketingTour
