/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable sonarjs/no-duplicate-string */

import { Card, CardActionArea, CardMedia, Stack, Typography, useTheme } from '@mui/material'
import extractUrls from 'extract-urls'
import { getApp } from 'firebase/app'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { useEffect, useState } from 'react'

type dataResponse = {
  data: {
    metadata: {
      title: string
      description: string
      siteName: string
      image: string
      url: string
    }
  }
}

const emptyData: dataResponse = {
  data: {
    metadata: {
      title: '',
      description: '',
      siteName: '',
      image: '',
      url: '',
    },
  },
}

const GetLink = (message: string) => {
  const themeItem = useTheme()
  const functions = getFunctions(getApp())
  const urls: string[] = extractUrls(message)
  const [data, setData] = useState(emptyData)

  const functionGetData = () => {
    if (urls[0] && urls[0].length > 0) {
      const getUrlInfo = httpsCallable(functions, 'generateLinkPreviewFunction')
      getUrlInfo({ data: { link: urls[0] } })
        .then((result: any) => setData(result))
        .catch(() => null)
    }
  }

  useEffect(
    functionGetData, []
  )

  return (
    <Stack>
      {data.data.metadata.siteName.length > 0 &&
      <Stack paddingTop={0.5}>
        <Card
          elevation={0}
          sx={{
            maxWidth: 336,
            backgroundColor: theme => theme.palette.background.input,
            color: theme => `${theme.palette.primary.contrastText}!important`,
          }}
        >
          <CardActionArea
            href={data.data.metadata.url}
            rel='noreferrer'
            style={{ color: 'inherit' }}
            target='_blank'
          >

            {data.data.metadata.image.length > 0 &&
            <CardMedia
              image={data.data.metadata.image}
              sx={{
                height: 150,
              }}
            />}
            <Stack padding={2} spacing={1}>
              {data.data.metadata.siteName.length > 0 &&
              <Typography color={themeItem.palette.text.primary} sx={{ wordWrap: 'break-word' }} variant='body2'>
                {data.data.metadata.siteName}
              </Typography>}

              {data.data.metadata.description.length > 0 &&
              <Typography sx={{ wordWrap: 'break-word' }} variant='helperText'>
                {data.data.metadata.description}
              </Typography>}
            </Stack>
          </CardActionArea>
        </Card>
      </Stack>}
    </Stack>
  )
}

export default GetLink
