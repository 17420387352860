/* eslint-disable no-multi-str */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import PaidRoundedIcon from '@mui/icons-material/PaidRounded'
import { Card, Stack, Typography } from '@mui/material'

import type { AnalyticsMonthlyChart } from 'src/models/Analytics'

type Props = {
  data: AnalyticsMonthlyChart[]
  selectedRecording: string
  periods: string[]
}

const EarningsDistributionLifetime = (props: Props) => {
  const totalEarnings = props.data.filter(item => props.periods.length === 0 || props.periods.includes(item._id))
    .flatMap(({ amount }) => amount).reduce((partialSum, a) => partialSum + a, 0) ?? 0
  return (
    <Stack height={1} width={1}>
      <Card
        elevation={0}
        sx={{
          height: 1,
          background: theme => theme.palette.background.elevatedCard,
        }}
      >
        <Stack height={1} justifyContent='space-between' padding={2} spacing={3}>
          <Stack direction='row' spacing={2} width={1}>
            <Stack spacing={2} width={1}>
              <Typography variant='body1'>
                Gross income
              </Typography>
              <Typography variant='h3'>
                {new Intl.NumberFormat('en-US',
                  { style: 'currency', currency: 'USD' }).format(totalEarnings)}
              </Typography>
            </Stack>
            <Stack spacing={2}>
              <PaidRoundedIcon color='secondary' sx={{ fontSize: 48 }} />
            </Stack>
          </Stack>
          <Typography variant='textLabel'>
            {props.selectedRecording.length > 0
              ? 'Total distribution earnings since the release of this recording'
              : 'Total distribution earnings'}
          </Typography>
        </Stack>
      </Card>
    </Stack>
  )
}

export default EarningsDistributionLifetime

