/* eslint-disable complexity */
/* eslint-disable max-lines */
/* eslint-disable sonarjs/no-duplicate-string */
import 'src/styles/Custom/quill.css'

import AddRoundedIcon from '@mui/icons-material/AddRounded'
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded'
import CloudDownloadRoundedIcon from '@mui/icons-material/CloudDownloadRounded'
import CloudSyncRoundedIcon from '@mui/icons-material/CloudSyncRounded'
import CloudUploadRoundedIcon from '@mui/icons-material/CloudUploadRounded'
import CreateRoundedIcon from '@mui/icons-material/CreateRounded'
import InfoRoundedIcon from '@mui/icons-material/InfoRounded'
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded'
import { LoadingButton } from '@mui/lab'
import { Avatar, Button, Card, CircularProgress, darken, Grid, IconButton, Input, Stack, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material'
import type { ChangeEvent } from 'react'
import { useEffect, useState } from 'react'
import FocusWithin from 'react-focus-within'
import ReactQuill from 'react-quill'

import { baseUrlLinksApi } from 'src/api/files'
import { getLinksFromApi, savePartialProject } from 'src/api/projects'
import PitchModal from 'src/components/pages/Projects/PitchModal/PitchModal'
import ViewPitchModal from 'src/components/pages/Projects/PitchModal/ViewPitchModal'
import EditProjectDetailsComponent from 'src/components/pages/Projects/ViewProject/EditDetails/EditDetailsComponent'
import EditLinksProjectModal from 'src/components/pages/Projects/ViewProject/EditDetails/EditLinksModal'
import GenerateArtworkModal from 'src/components/pages/Projects/ViewProject/EditDetails/GenerateArtwork'
import GetIconLink from 'src/components/pages/Projects/ViewProject/EditDetails/GetIconLink'
import InviteCollaborators from 'src/components/pages/Projects/ViewProject/InviteCollaborators/InviteCollaboratorsModal'
import ShareModal from 'src/components/pages/Projects/ViewProject/ShareModal/ShareModal'
import CollaboratorItemDetails from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/DetailsTab/CollaboratorItemDetail'
import CollaboratorItemDetailsOwner from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/DetailsTab/CollaboratorItemOwner'
import ProjectProgress from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/DetailsTab/ViewProjectProgress'
import { GetColorLink } from 'src/components/pages/SmartLink/Content/GetColorLink'
import { formats } from 'src/components/pages/Task/TaskDrawer/TaskDrawerRows/TaskDrawerDescriptionRow'
import RichInputToolbar from 'src/components/pages/Task/TaskDrawer/ToolBarQuill'
import type { Distribution } from 'src/models/Distribution'
import type { Pitch } from 'src/models/Pitch'
import type Project from 'src/models/Project'
import { PROJECT_STATUS } from 'src/models/Project'
import type Recording from 'src/models/Recording'
import QuillComponentTheme from 'src/styles/Custom/quillComponentStyle'
import SPACING from 'src/styles/spacing'

export function getColor(selected: string) {
  switch (selected) {
    case PROJECT_STATUS[0].toString():
      return 'success'
    case PROJECT_STATUS[1].toString():
      return 'warning'
    case PROJECT_STATUS[2].toString():
      return 'secondary'
    case PROJECT_STATUS[3].toString():
      return 'error'
    case PROJECT_STATUS[4].toString():
      return 'primary'
    default:
      return 'primary'
  }
}

const maximumCollaboratorsVisible = 5

type Props = {
  project?: Project
  mobile?: boolean
  pitch?: Pitch
  setProject: React.Dispatch<React.SetStateAction<Project | undefined>>
  setPitchItem: React.Dispatch<React.SetStateAction<Pitch | undefined>>
  setUpdateBoard: React.Dispatch<React.SetStateAction<boolean>>
  setDistributeModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  recordings: Recording[]
  setCurrentTab: React.Dispatch<React.SetStateAction<string>>
  distribution?: Distribution
}

const DetailsTab = (props: Props) => {
  const [shareModal, setShareModal] = useState(false)
  const [editLinks, setEditLinks] = useState(false)
  const [collaboratorModal, setCollaboratorModal] = useState(false)
  const [pitchModal, setPitchModal] = useState(false)
  const [description, setDescription] = useState(props.project?.description ?? '')
  const [loadingImage, setLoadingImage] = useState(false)
  const [artworkUrl, setArtworkUrl] = useState<string>()
  const [artworkPreviewUrl, setArtworkPreviewUrl] = useState<string>()
  const [loadingScan, setLoadingScan] = useState(false)
  const [openGpt, setOpenGpt] = useState(false)

  const themeItem = useTheme()
  const mobile = useMediaQuery(themeItem.breakpoints.down('lg'))

  useEffect(() => {
    setArtworkUrl(props.project?.projectArtworkUrl)
  }, [props.project?.projectArtworkUrl])

  useEffect(() => {
    setArtworkPreviewUrl(props.project?.artworkPreviewUrl)
  }, [props.project?.artworkPreviewUrl])

  const getLinks = async () => {
    if (props.project?.id) {
      setLoadingScan(true)
      await getLinksFromApi(props.project.id)
        .then(item => {
          props.setProject(item)
        })
        .finally(() => setLoadingScan(false))
    }
  }

  return (
    <>
      {openGpt && props.project &&
      <GenerateArtworkModal
        close={() => setOpenGpt(false)}
        open={openGpt}
        project={props.project}
        setProject={props.setProject}
      />}
      {props.project &&
      <ShareModal
        close={() => setShareModal(false)}
        open={shareModal}
        project={props.project}
      />}
      {props.project &&
      <InviteCollaborators
        close={() => setCollaboratorModal(false)}
        open={collaboratorModal}
        project={props.project}
        setProject={props.setProject}
      />}
      {props.pitch && props.project &&
      <ViewPitchModal
        close={() => setPitchModal(false)}
        open={pitchModal}
        pitch={props.pitch}
        project={props.project}
      />}
      {props.project && !props.pitch &&
      <PitchModal
        close={() => setPitchModal(false)}
        open={pitchModal}
        project={props.project}
        setPitchItem={props.setPitchItem}
      />}
      {props.project &&
      <EditLinksProjectModal
        close={() => setEditLinks(false)}
        open={editLinks}
        project={props.project}
        setProject={props.setProject}
        setUpdateBoard={props.setUpdateBoard}
      />}
      <QuillComponentTheme />
      <Stack
        alignItems='center'
        marginX='auto'
        maxWidth='lg'
        sx={{
          overflowY: 'auto',
          overflowX: 'inherit',
        }}
        width={1}
      >
        <Stack
          direction='column'
          flexShrink={0}
          paddingX={props.mobile ? SPACING.CONTENT_PADDING : 0}
          paddingY={2}
          spacing={2}
          width={props.mobile ? 1 : 256}
        >
          <Stack direction='column' spacing={4} width={1}>
            <Stack direction='column' spacing={1} width={1}>
              <FocusWithin>
                {({ focusProps, isFocused }) =>
                  <Card
                    {...focusProps}
                    sx={{
                      borderColor: theme => isFocused
                        ? theme.palette.primary.main
                        : theme.palette.divider,
                    }}
                    variant='outlined'
                  >
                    <Stack direction='column' padding={2} spacing={2} width={1}>
                      <ReactQuill
                        formats={formats}
                        modules={{
                          toolbar: {
                            container: '#toolbarItem',
                          },
                        }}
                        onBlur={async () => {
                          if (props.project) {
                            await savePartialProject({ ...props.project, description })
                              .then(project => props.setProject(project))
                          }
                        }}
                        onChange={value => setDescription(value)}
                        placeholder='Welcome to your project! You can use this box to set the tone of how
                        you&apos;ll work together in Releese. Add details, questions and other important information.'
                        theme='snow'
                        value={description}
                      />
                      <Stack sx={{ opacity: isFocused ? 1 : 0.7 }}>
                        <RichInputToolbar />
                      </Stack>
                    </Stack>
                  </Card>}
              </FocusWithin>
            </Stack>

            <Stack
              direction={mobile ? 'column' : 'row'}
              spacing={mobile ? 4 : 2}
              width={1}
            >
              <Stack direction='column' width={1}>
                <ProjectProgress
                  distribute={() => props.setDistributeModalOpen(true)}
                  distribution={props.distribution}
                  pitch={() => setPitchModal(true)}
                  project={props.project}
                  recordings={props.recordings}
                  setCurrentTab={props.setCurrentTab}
                />
              </Stack>
            </Stack>

            <Stack direction='column' id='collaborators' width={1}>
              <Stack alignItems='center' direction='row' spacing={1} width={1}>
                <Typography variant='h4'>
                  Collaborators
                </Typography>
                <Tooltip title='Collaborators gain access your release&apos;s files, recordings, analytics,
                    tasks and messages. They cannot view financial data unless they are assigned a split.'
                >
                  <InfoRoundedIcon color='action' fontSize='small' />
                </Tooltip>
              </Stack>

              <Grid container spacing={1}>
                <Grid
                  item
                  lg={3}
                  md={4}
                  sm={6}
                  xl={3}
                  xs={12}
                >
                  <Button
                    color='inherit'
                    fullWidth
                    onClick={() => setCollaboratorModal(true)}
                    startIcon={<AddRoundedIcon
                      sx={{
                        fontSize: '40px!important',
                        border: '2px dashed',
                        borderRadius: 64,
                      }}
                    />}
                    sx={{
                      height: 'fit-content',
                      color: theme => theme.palette.text.label,
                      justifyContent: 'flex-start',
                    }}
                    variant='subtle'
                  >
                    <Typography textAlign='start' variant='subtitle1'>
                      {props.project?.collaborators && props.project.collaborators.length > 0
                        ? 'Manage collaborators'
                        : 'Add collaborators'}
                    </Typography>
                  </Button>
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={4}
                  sm={6}
                  xl={3}
                  xs={12}
                >
                  <Button
                    color='inherit'
                    fullWidth
                    sx={{
                      height: 'fit-content',
                    }}
                    variant='subtle'
                  >
                    {props.project?.organisation &&
                      <CollaboratorItemDetailsOwner
                        collaborator={props.project.organisation}
                      />}
                  </Button>
                </Grid>
                {props.project?.collaborators.slice(0, maximumCollaboratorsVisible).map(collaborator =>
                  <Grid
                    item
                    key={collaborator.account.id}
                    lg={3}
                    md={4}
                    sm={6}
                    xl={3}
                    xs={12}
                  >
                    <Button
                      color='inherit'
                      fullWidth
                      sx={{
                        height: 'fit-content',
                      }}
                      variant='subtle'
                    >
                      <CollaboratorItemDetails
                        collaborator={collaborator}
                      />
                    </Button>
                  </Grid>)}
                {props.project && props.project.collaborators.length > maximumCollaboratorsVisible &&
                <Grid
                  item
                  lg={3}
                  md={4}
                  sm={6}
                  xl={3}
                  xs={12}
                >
                  <Button
                    color='inherit'
                    fullWidth
                    onClick={() => setCollaboratorModal(true)}
                    startIcon={<PeopleAltRoundedIcon
                      sx={{
                        fontSize: '40px!important',
                      }}
                    />}
                    sx={{
                      height: '100%',
                      color: theme => theme.palette.text.label,
                      justifyContent: 'flex-start',
                    }}
                    variant='subtle'
                  >
                    <Typography textAlign='start' variant='body1'>
                      +
                      {props.project.collaborators.length - maximumCollaboratorsVisible}
                      {' '}
                      more
                    </Typography>
                  </Button>
                </Grid>}
              </Grid>
            </Stack>

            <Stack direction='column' spacing={2} width={1}>
              <Stack alignItems='center' direction='row' spacing={1} width={1}>
                <Typography variant='h4'>
                  Links
                </Typography>
                <Tooltip title='Once your release is distributed, you can scan for links to quickly view it stores.'>
                  <InfoRoundedIcon color='action' fontSize='small' />
                </Tooltip>
              </Stack>
              <Stack direction='row' flexWrap='wrap' spacing={1}>
                {props.project?.linkItems.map(link =>
                  <IconButton
                    href={link.link}
                    key={link.id}
                    size='medium'
                    sx={{
                      backgroundColor: GetColorLink(link.link),
                      ':hover': {
                        backgroundColor: darken(GetColorLink(link.link), 0.2),
                      },
                      'svg': {
                        color: 'white!important',
                      },
                      width: 43,
                      height: 43,
                    }}
                    target='_blank'
                  >
                    {GetIconLink(link.link)}
                  </IconButton>)}
                <Tooltip title='Edit links'>
                  <IconButton
                    onClick={() => setEditLinks(true)}
                    size='medium'
                    sx={{
                      border: theme => `1px solid ${theme.palette.action.disabled}`,
                    }}
                  >
                    <CreateRoundedIcon />
                  </IconButton>
                </Tooltip>
                {props.project && props.project.linkItems.length === 0 &&
                <Stack alignItems='center' direction='row' spacing={1}>
                  {!props.project.UPC || props.project.UPC.length === 0
                    ? <div>
                      <Tooltip title='Please add your UPC code to your project to scan for links'>
                        <div>
                          <LoadingButton
                            disabled={loadingScan || !props.project.UPC || props.project.UPC.length === 0}
                            loading={loadingScan}
                            onClick={() => getLinks()}
                            rounded
                            startIcon={<CloudSyncRoundedIcon />}
                            variant='contained'
                          >
                            Scan
                          </LoadingButton>
                        </div>
                      </Tooltip>
                    </div>
                    : <LoadingButton
                      disabled={loadingScan || !props.project.UPC || props.project.UPC.length === 0}
                      loading={loadingScan}
                      onClick={() => getLinks()}
                      rounded
                      startIcon={<CloudSyncRoundedIcon />}
                      variant='contained'
                    >
                      Scan
                    </LoadingButton>}
                </Stack>}
              </Stack>
            </Stack>

            <Stack direction='column' id='metadataEdit' paddingTop={2} spacing={2} width={1}>
              <Stack alignItems='center' direction='row' spacing={1} width={1}>
                <Typography variant='h4'>
                  Metadata
                </Typography>
                <Tooltip title='This section represents your release&apos;s album-level metadata. Only the fields with
                a "*" are required for distribution.'
                >
                  <InfoRoundedIcon color='action' fontSize='small'  />
                </Tooltip>
              </Stack>

              {props.project &&
              <EditProjectDetailsComponent
                project={props.project}
                setProject={props.setProject}
                updatedFields={value => props.setProject(value)}
              />}
            </Stack>

            <Stack direction='column' id='artworkEdit' spacing={2} width={1}>
              <Stack alignItems='center' direction='row' spacing={1} width={1}>
                <Typography variant='h4'>
                  Artwork
                </Typography>
                <Tooltip title='We use this file when distributing your release to stores, our system will automatically
                convert the file to the right format.'
                >
                  <InfoRoundedIcon color='action' fontSize='small'  />
                </Tooltip>
              </Stack>

              {loadingImage
                ? <Avatar
                  sx={{
                    width: 128,
                    height: 128,
                    transition: '0.3s',
                    background: theme => theme.palette.background.input,
                  }}
                  variant='rounded'
                >
                  <CircularProgress color='primary' sx={{ width: 128, height: 128 }} />
                </Avatar>
                : <Avatar
                  src={artworkPreviewUrl}
                  sx={{
                    width: 128,
                    height: 128,
                    transition: '0.3s',
                    background: theme => theme.palette.background.input,
                  }}
                  variant='rounded'
                >
                  <MusicNoteRoundedIcon
                    sx={{
                      fontSize: 128,
                      color: theme => theme.palette.action.disabled,
                    }}
                  />
                </Avatar>}

              <Stack direction='row' spacing={1} width={1}>
                <Button
                  color='primary'
                  disabled={!artworkUrl}
                  endIcon={<CloudDownloadRoundedIcon />}
                  fullWidth
                  href={`${baseUrlLinksApi}/files/download?fileName=${encodeURIComponent(artworkUrl ?? '')}`}
                  sx={{
                    width: 'fit-content',
                  }}
                  target='_blank'
                  variant='contained'
                >
                  Download
                </Button>

                <label htmlFor='artwork-button-file-artwork' >
                  <LoadingButton
                    color='secondary'
                    component='span'
                    disabled={!props.project || props.project.distributed}
                    endIcon={<CloudUploadRoundedIcon />}
                    fullWidth
                    loading={loadingImage}
                    variant='contained'
                  >
                    Replace
                  </LoadingButton>
                  <Input
                    disabled={!props.project || props.project.distributed}
                    id='artwork-button-file-artwork'
                    onChange={async (event: ChangeEvent<HTMLInputElement>) => {
                      const file = event.target.files?.[0]
                      if (file && props.project &&
                        ((/\.(jpg)$/i).test(file.name) ||
                        (/\.(jpeg)$/i).test(file.name) ||
                        (/\.(png)$/i).test(file.name) ||
                        (/\.(png)$/i).test(file.name) ||
                        (/\.(jpg)$/i).test(file.name) ||
                        (/\.(jpeg)$/i).test(file.name))) {
                        setLoadingImage(true)
                        await savePartialProject({ ...props.project, projectArtwork: file })
                          .then(item => props.setProject(item))
                          .finally(() => setLoadingImage(false))
                      }
                    }}
                    sx={{ display: 'none', zIndex: '100000' }}
                    type='file'
                  />
                </label>

                <LoadingButton
                  color='info'
                  component='span'
                  disabled={!props.project || props.project.distributed}
                  endIcon={<AutoAwesomeRoundedIcon />}
                  fullWidth
                  loading={loadingImage}
                  onClick={() => setOpenGpt(true)}
                  sx={{
                    width: 'fit-content',
                  }}
                  variant='contained'
                >
                  AI generate
                </LoadingButton>
              </Stack>
            </Stack>

          </Stack>
        </Stack>
      </Stack>
    </>
  )
}
export default DetailsTab
