
import PublicRoundedIcon from '@mui/icons-material/PublicRounded'
import { Avatar, Chip, ListItemText, Stack, TableCell, TableRow, Typography } from '@mui/material'
import dayjs from 'dayjs'
import countries from 'i18n-iso-countries'

import { setAnalyticsTitle } from 'src/components/pages/Analytics/AnalyticsDistribution/AnalyticsDistributionAppbar'
import { GetColorLink } from 'src/components/pages/SmartLink/Content/GetColorLink'
import GetIcon from 'src/components/pages/SmartLink/Content/GetIcon'

type Props = {
  name: string
  email: string
  platform: string
  country: string
  createdAt: Date
  key: string
}

const SubscriberItem = (props: Props) =>
  <TableRow
    hover
    key={props.key}
    sx={{
      '.MuiTableCell-root': {
        borderBottomColor: 'divider',
        paddingY: 2,
      },
      ':hover': {
        '.MuiTableCell-root': {
          background: theme => `${theme.palette.tableHoverBg}!important`,
        },
      },
    }}
  >
    <TableCell
      sx={{
        position: 'sticky',
        background: theme => theme.palette.background.default,
        borderRight: theme => `1px solid ${theme.palette.divider}`,
        left: 0,
        zIndex: 999,
      }}
    >
      <ListItemText
        primary={props.name}
        secondary={props.email.length > 0 ? props.email : 'No email found'}
      />
    </TableCell>
    <TableCell>
      <Chip
        icon={GetIcon(props.platform)}
        label={setAnalyticsTitle(props.platform)}
        size='medium'
        sx={{
          color: 'white',
          background: GetColorLink(props.platform),
          '.MuiSvgIcon-root': {
            color: 'white',
            marginLeft: '12px',
          },
          borderRadius: 2,
          width: 'fit-content',
        }}
      />
    </TableCell>
    <TableCell>
      <Stack alignItems='center' direction='row' spacing={1}>
        <Avatar
          src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/
${props.country.toUpperCase()}.svg`}
          sx={{
            background: theme => theme.palette.info.main,
            height: 23,
            width: 35,
            borderRadius: '4px',
          }}
          variant='rounded'
        >
          <PublicRoundedIcon />
        </Avatar>
        <ListItemText
          primary={countries.getName(props.country, 'en')
            ? countries.getName(props.country, 'en')
            : 'Unknown'}
        />
      </Stack>
    </TableCell>
    <TableCell>
      <Typography variant='body2'>
        {dayjs(props.createdAt).format('LL')}
      </Typography>
    </TableCell>
  </TableRow>

export default SubscriberItem
