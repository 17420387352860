/* eslint-disable react-hooks/exhaustive-deps */
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import CircleTwoToneIcon from '@mui/icons-material/CircleTwoTone'
import { IconButton, Stack, Tooltip, Typography, useTheme } from '@mui/material'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import Confetti from 'react-confetti'

type Props = {
  done: boolean
  title: string
  action: () => void
}

const CheckMarkItemTour = (props: Props) => {
  const themeItem = useTheme()
  const [firstRender, setFirstRender] = useState(false)
  const [loadConfetti, setLoadConfetti] = useState(false)

  useEffect(() => {
    if (!firstRender) {
      setFirstRender(true)
    } else {
      setLoadConfetti(true)
    }
  }, [props.done])

  return (
    <Stack alignItems='center' direction='row' spacing={1} width={1}>
      {props.done
        ? <IconButton
          color='primary'
          disabled={props.done}
        >
          <CheckCircleRoundedIcon fontSize='large' />
        </IconButton>
        : <IconButton
          sx={{ color: theme => theme.palette.text.label }}
        >
          <CircleTwoToneIcon fontSize='large' sx={{ color: theme => theme.palette.text.label }} />
        </IconButton>}
      {loadConfetti &&
      <Confetti
        height={428}
        numberOfPieces={64}
        recycle={false}
        width={340}
      />}
      <Typography
        color={props.done
          ? themeItem.palette.text.disabled
          : themeItem.palette.text.secondary}
        sx={{
          width: 1,
          textDecoration: props.done
            ? 'line-through'
            : 'none',
        }}
        variant='body2'
      >
        {props.title}
      </Typography>
      <Tooltip title='Try it'>
        <IconButton
          color='default'
          disabled={props.done}
          onClick={() => props.action()}
        >
          <ArrowForwardRoundedIcon />
        </IconButton>
      </Tooltip>
    </Stack>
  )
}

export default CheckMarkItemTour
