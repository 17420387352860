import CopyrightRoundedIcon from '@mui/icons-material/CopyrightRounded'
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded'
import LibraryMusicRoundedIcon from '@mui/icons-material/LibraryMusicRounded'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import PhonelinkRoundedIcon from '@mui/icons-material/PhonelinkRounded'
import PlaylistAddCheckCircleRoundedIcon from '@mui/icons-material/PlaylistAddCheckCircleRounded'
import StorageRoundedIcon from '@mui/icons-material/StorageRounded'
import { alpha, Box, Button, Card, CardActionArea, Divider, Stack, Typography, useTheme } from '@mui/material'

type Props = {
  readonly activeBilling: number
  readonly billingCycle: number
  readonly handleBilling: (step: number) => () => void
  readonly creator: number
  readonly month: number
  readonly disabled: boolean
  readonly seats: number
}

const CreatorCard = (props: Props) => {
  const themeItem = useTheme()
  return (
    <Card
      sx={{
        borderColor: theme => props.activeBilling === props.creator
          ? `${theme.palette.primary.main}!important`
          : null,
      }}
      variant='outlined'
    >
      <CardActionArea
        disabled={!props.disabled || props.seats > 1}
        onClick={props.handleBilling(props.creator)}
        sx={{ height: 1 }}
      >
        {!props.disabled &&
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: 1,
            bgcolor: theme => alpha(theme.palette.action.disabled, 0.25),
            height: 1,
          }}
        />}
        <Stack alignItems='flex-start' direction='column' height={1} padding={2} spacing={2}>
          <Stack>
            <Typography fontWeight='normal' variant='h2'>
              Essential
            </Typography>
            <Stack alignItems='flex-end' direction='row' spacing={1}>
              <Typography fontFamily='Domaine-Display' lineHeight={1} variant='h1'>
                <span style={{ fontFamily: 'Matter' }}>
                  $
                </span>
                {props.billingCycle * props.creator}
              </Typography>
              {props.billingCycle === props.month
                ? <Typography variant='body1'>
                  / month
                </Typography>
                : <Typography variant='body1'>
                  / year
                </Typography>}
            </Stack>
          </Stack>
          <Typography
            color={themeItem.palette.text.secondary}
            variant='body2'
          >
            Essential tools to manage your entire career from one place.
          </Typography>
          <Button
            color='primary'
            disabled={!props.disabled || props.seats > 1}
            fullWidth
          >
            Select Essential
          </Button>
          <Stack direction='column'>
            <Typography lineHeight={1.4} variant='h3'>
              Features
            </Typography>
            <Typography color={themeItem.palette.text.secondary} variant='body2'>
              Everything in Free and...
            </Typography>
          </Stack>
          <Divider sx={{ width: 1 }} />
          <Stack direction='column' spacing={3}>
            <Stack alignItems='center' direction='row' spacing={1}>
              <StorageRoundedIcon color='action' fontSize='small' />
              <Typography color='text.secondary' variant='body2'>
                1 Tb Storage
              </Typography>
            </Stack>
            <Stack alignItems='center' direction='row' spacing={1}>
              <PersonRoundedIcon color='action' fontSize='small' />
              <Typography color='text.secondary' variant='body2'>
                1 Person
              </Typography>
            </Stack>
            <Stack alignItems='center' direction='row' spacing={1}>
              <LibraryMusicRoundedIcon color='action' fontSize='small' />
              <Typography color='text.secondary' variant='body2'>
                Unlimited Distribution
              </Typography>
            </Stack>
            <Stack alignItems='center' direction='row' spacing={1}>
              <PhonelinkRoundedIcon color='action' fontSize='small' />
              <Typography color='text.secondary' variant='body2'>
                Smart Links & Pre-Saves
              </Typography>
            </Stack>
            <Stack alignItems='center' direction='row' spacing={1}>
              <CopyrightRoundedIcon color='action' fontSize='small' />
              <Typography color='text.secondary' variant='body2'>
                Rights Collection
              </Typography>
            </Stack>
            <Stack alignItems='center' direction='row' spacing={1}>
              <DashboardRoundedIcon color='action' fontSize='small' />
              <Typography color='text.secondary' variant='body2'>
                Advanced Release Planning
              </Typography>
            </Stack>
            <Stack alignItems='center' direction='row' spacing={1}>
              <PlaylistAddCheckCircleRoundedIcon color='action' fontSize='small' />
              <Typography color='text.secondary' variant='body2'>
                Playlist & Chart Tracking
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </CardActionArea>
    </Card>
  )
}

export default CreatorCard
