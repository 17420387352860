/* eslint-disable sonarjs/no-duplicate-string */
import HelpRoundedIcon from '@mui/icons-material/HelpRounded'
import PublishRoundedIcon from '@mui/icons-material/PublishRounded'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Grid, IconButton, Stack, Tab, Typography, useTheme } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import WorldMap from 'react-svg-worldmap'

import { useContainerDimensions } from 'src/components/ContainerDimensions'
import ViewDistributionSupport from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/DistributionTab/ViewDistributionSupport'
import { GetColorLink } from 'src/components/pages/SmartLink/Content/GetColorLink'
import GetIcon from 'src/components/pages/SmartLink/Content/GetIcon'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { Distribution } from 'src/models/Distribution'
import type Project from 'src/models/Project'

type Props = {
  distributionItem: Distribution | undefined
  project: Project
}

const ViewDistribution = (props: Props) => {
  const { currentOrganisation } = useAuth()
  const [currentTab, setCurrentTab] = useState('distribution')
  const theme = useTheme()
  const componentRef = useRef<HTMLDivElement>(null)
  const { width } = useContainerDimensions(componentRef)

  const territoryList = props.distributionItem?.territories.map(({ iso }) =>
    ({ country: iso.toUpperCase(), value: 1 })) ?? []

  const search = useLocation().search
  const goto = new URLSearchParams(search).get('tab')

  useEffect(() => {
    if (goto === 'support') {
      setCurrentTab('support')
    }
  }, [goto])

  return (
    <Stack height={1} width={1}>
      <TabContext value={currentTab}>
        <Stack direction='row' sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList
            aria-label='Current projects tab'
            onChange={(event, value: string) => setCurrentTab(value)}
          >
            <Tab
              icon={<PublishRoundedIcon />}
              label='Overview'
              value='distribution'
            />
            <Tab
              disabled={currentOrganisation?.id !== props.project.organisation?.id ||
                props.distributionItem?.status !== 'Distributed'}
              icon={<HelpRoundedIcon />}
              label='Support'
              value='support'
            />
          </TabList>
        </Stack>
        <TabPanel sx={{ width: 1, height: 1, padding: 0, overflow: 'auto' }} value='distribution'>
          <Stack marginX='auto' maxWidth={900} paddingBottom={4} paddingTop={2} paddingX={2} spacing={1} width={1}>
            <Typography variant='h3'>
              Territories
            </Typography>
            <Stack alignItems='center' ref={componentRef} sx={{ pointerEvents: 'none' }} width={1}>
              {props.distributionItem &&
              <WorldMap
                backgroundColor='transparent'
                borderColor={theme.palette.divider}
                color={theme.palette.primary.main}
                data={territoryList}
                size={width > 800 ? width / 1.2 : width}
                strokeOpacity={1}
                valueSuffix=''
              />}
            </Stack>
            <Typography variant='h3'>
              Stores
            </Typography>
            <Grid container spacing={2} width={1}>
              {props.distributionItem?.platforms.map(platform =>
                <>
                  <Grid item key={platform.name} lg={4} md={4} sm={6} xl={3} xs={6}>
                    <Stack alignItems='center' direction='row' justifyContent='flex-start' spacing={1} width={1}>
                      <IconButton
                        disableRipple
                        sx={{
                          backgroundColor: GetColorLink(platform.name),
                          color: 'white!important',
                          ':hover': {
                            backgroundColor: GetColorLink(platform.name),
                            cursor: 'default',
                          },
                          '& .MuiSvgIcon-root': {
                            color: 'white!important',
                          },
                        }}
                      >
                        {GetIcon(platform.name)}
                      </IconButton>
                      <Typography variant='body1'>
                        {platform.name}
                      </Typography>
                    </Stack>
                  </Grid>
                  {platform.name === 'Facebook' &&
                  <Grid item key='Instagram' lg={4} md={4} sm={6} xl={3} xs={6}>
                    <Stack alignItems='center' direction='row' justifyContent='flex-start' spacing={1} width={1}>
                      <IconButton
                        disableRipple
                        sx={{
                          backgroundColor: GetColorLink('Instagram'),
                          color: 'white!important',
                          ':hover': {
                            backgroundColor: GetColorLink('Instagram'),
                            cursor: 'default',
                          },
                          '& .MuiSvgIcon-root': {
                            color: 'white!important',
                          },
                        }}
                      >
                        {GetIcon('Instagram')}
                      </IconButton>
                      <Typography variant='body1'>
                        Instagram
                      </Typography>
                    </Stack>
                  </Grid>}
                  {platform.name === 'iTunes & Apple Music' &&
                  <Grid item key='Shazam' lg={4} md={4} sm={6} xl={3} xs={6}>
                    <Stack alignItems='center' direction='row' justifyContent='flex-start' spacing={1} width={1}>
                      <IconButton
                        disableRipple
                        sx={{
                          backgroundColor: GetColorLink('Shazam'),
                          color: 'white!important',
                          ':hover': {
                            backgroundColor: GetColorLink('Shazam'),
                            cursor: 'default',
                          },
                          '& .MuiSvgIcon-root': {
                            color: 'white!important',
                          },
                        }}
                      >
                        {GetIcon('Shazam')}
                      </IconButton>
                      <Typography variant='body1'>
                        Shazam
                      </Typography>
                    </Stack>
                  </Grid>}
                </>)}
            </Grid>
          </Stack>
        </TabPanel>
        {currentOrganisation?.id === props.project.organisation?.id &&
        <TabPanel sx={{ width: 1, height: 1, padding: 0, overflow: 'auto' }} value='support'>
          <ViewDistributionSupport
            distributionItem={props.distributionItem}
            project={props.project}
          />
        </TabPanel>}
      </TabContext>
    </Stack>
  )
}

export default ViewDistribution
