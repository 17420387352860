import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import { Button, Card, Dialog, Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'

import BillingModal from 'src/components/pages/Account/Billing/BillingModal'
import DistributionBubbles from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/DistributionTab/DistributionBubbles'
import { useAuth } from 'src/components/providers/AuthProvider'

type Props = {
  close: () => void
  open: boolean
}

const premiumFeatures = [
  'Claim an artist or label profile',
  'Track playlist placements',
  'View your current and historic charts',
  'Get better audience insights',
  'Track TikTok and Instagram videos',
  'Track YouTube and Soundcloud reach',
  'Spotify, Apple Music, Deezer, Amazon, Shazam, and more',
  'Share beautiful stories and posts',
  'Enterprise members can remove branding',
]

const AnalyticsModalCta = (props: Props) => {
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))

  const [billingModal, setBillingModalOpen] = useState(false)
  const { currentOrganisation } = useAuth()
  return (
    <>
      {billingModal && currentOrganisation &&
      <BillingModal
        account={currentOrganisation}
        close={() => setBillingModalOpen(false)}
        open={billingModal}
      />}
      <Dialog
        BackdropProps={{
          timeout: 500,
        }}
        closeAfterTransition
        fullScreen={fullScreen}
        fullWidth
        maxWidth='md'
        onClose={props.close}
        open={props.open}
        sx={{
          justifyContent: 'center',
        }}
      >
        <Stack height={1} overflow='auto' padding={3} spacing={fullScreen ? 1 : 2} width={1}>
          <Stack justifyContent='center' width={1}>
            <Typography lineHeight={1.2} variant='h3'>
              Track Your Success
            </Typography>
            <Typography color='textLabel' variant='body1'>
              Subscribe to a paid plan to unlock your analytics
            </Typography>
          </Stack>

          <Grid
            columnSpacing={fullScreen ? 0 : 2}
            container
            rowSpacing={2}
            width={1}
          >
            <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
              <Stack direction='column' spacing={1} width={1}>
                {premiumFeatures.map(item =>
                  <Stack alignItems='center' direction='row' justifyContent='space-between' key={item} width={1}>
                    <Typography variant='body2'>
                      {item}
                    </Typography>
                    <CheckCircleRoundedIcon color='success' fontSize='medium' />
                  </Stack>)}
              </Stack>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
              <Card
                elevation={0}
                sx={{
                  background: theme => theme.palette.background.input,
                  maxHeight: 340,
                }}
              >
                <DistributionBubbles />
              </Card>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
              <Stack direction='column' justifyContent='flex-end' spacing={1} width={1}>
                <Button
                  onClick={() => setBillingModalOpen(true)}
                >
                  Explore plans
                </Button>
                <Button
                  onClick={props.close}
                  variant='text'
                >
                  Close
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Dialog>
    </>
  )
}

export default AnalyticsModalCta
