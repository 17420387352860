/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable complexity */
/* eslint-disable max-lines */
import EmailRoundedIcon from '@mui/icons-material/EmailRounded'
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded'
import MailLockRoundedIcon from '@mui/icons-material/MailLockRounded'
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded'
import { LoadingButton } from '@mui/lab'
import { Alert, Backdrop, Button, Card, Chip, Grid, Paper, Stack, Typography, useTheme } from '@mui/material'
import { getApp } from 'firebase/app'
import type { AuthError } from 'firebase/auth'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { useState } from 'react'

import EditProjectDetailsComponent from 'src/components/pages/Projects/ViewProject/EditDetails/EditDetailsComponent'
import { generateTitleRecording } from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/RecordingsTab/Components/EditRecordingComponent'
import EmailVerificationSentModal from 'src/components/pages/Settings/Account/AccountTabs/AccountComponents/EmailVerificationSentModal'
import { useAuth } from 'src/components/providers/AuthProvider'
import { NavLink } from 'src/components/SafeRouterLink'
import { distributionErrorCopyright } from 'src/data/distributionErrors'
import firebaseErrorMessages from 'src/data/firebaseAuthErrorCodes'
import type { Platform, Territory } from 'src/models/Distribution'
import type Project from 'src/models/Project'
import type Recording from 'src/models/Recording'
import { auth } from 'src/utils/firebase'

type Props = {
  territories: Territory[]
  stores: Platform[]
  project?: Project
  recordings: Recording[]
  errorHandling: boolean
  recordingTitlesStartWithNumbers: boolean
  loading: boolean
  errorMessage: string
  setProject: React.Dispatch<React.SetStateAction<Project | undefined>>
}

const SubmitDistribute = (props: Props) => {
  const themeItem = useTheme()
  const functions = getFunctions(getApp())
  const { currentFirebaseUser } = useAuth()
  const [loadingVerifyEmail, setLoadingVerifyEmail] = useState(false)
  const [openEmailVerificationModal, setOpenEmailVerificationModal] = useState(false)
  const [errorMessageVerification, setErrorMessageVerification] = useState('')

  const handleVerifyEmail = () => {
    if (!auth.currentUser!.emailVerified) {
      setLoadingVerifyEmail(true)
      const sendVerifycationEmail = httpsCallable(functions, 'verifyEmailFunction')

      sendVerifycationEmail({ data: { email: currentFirebaseUser.email } })
        .then(() => {
          setLoadingVerifyEmail(false)
          setOpenEmailVerificationModal(true)
        })
        .catch((error: AuthError) =>  {
          setErrorMessageVerification(firebaseErrorMessages.find(errorCode => errorCode.code === error.code)!.message)
          setLoadingVerifyEmail(false)
        })
    }
  }

  const currentDate = new Date()
  const releaseDate = props.project?.releaseDate ? new Date(props.project.releaseDate) : new Date()

  const diffTime = releaseDate.getTime() - currentDate.getTime()
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

  const uniqueArtists = [...new Set(props.recordings.flatMap(record =>
    record.primaryArtists.map(artist => artist.name)))]

  const duplicateIsrcError =
    new Set(props.recordings.map(item => item.ISRC).filter(item => item.length > 0)).size !==
    props.recordings.map(item => item.ISRC).filter(item => item.length > 0).length

  const allTitles = props.recordings.map(item => generateTitleRecording(undefined, item))

  const duplicateTitleError = new Set(allTitles).size !== allTitles.length

  return (
    <>
      <Backdrop
        open={props.loading || props.errorMessage.length > 0}
        sx={{
          zIndex: 2,
        }}
      >
        {props.errorMessage.length > 0 &&
        <Card
          sx={{
            minWidth: 300,
            minHeight: 240,
            maxWidth: 600,
          }}
        >
          <Stack alignItems='center' padding={2} spacing={4} width={1}>
            {props.errorMessage === distributionErrorCopyright
              ? <VisibilityRoundedIcon
                color='primary'
                sx={{
                  fontSize: 64,
                }}
              />
              : <ErrorRoundedIcon
                color='error'
                sx={{
                  fontSize: 64,
                }}
              />}

            <Typography textAlign='center'>
              {props.errorMessage}
            </Typography>
            <Button
              component={NavLink}
              to='/projects/projects'
            >
              Go back to projects
            </Button>
          </Stack>
        </Card>}
      </Backdrop>
      <Backdrop
        open={!currentFirebaseUser.emailVerified}
        sx={{
          zIndex: 2,
        }}
      >
        {!currentFirebaseUser.emailVerified &&
        <Card
          sx={{
            minWidth: 300,
            minHeight: 240,
            maxWidth: 600,
          }}
        >
          <Stack alignItems='center' padding={4} width={1}>
            <Paper
              elevation={0}
              sx={{
                backgroundColor: theme => theme.palette.primary.subtleResting,
                borderRadius: 16,
              }}
            >
              <Stack
                alignItems='center'
                justifyContent='center'
                padding={4}
              >
                <MailLockRoundedIcon
                  sx={{
                    color: theme => theme.palette.primary.main,
                    fontSize: '144px',
                  }}
                />
              </Stack>
            </Paper>
            <Typography
              color={themeItem.palette.text.secondary}
              sx={{
                marginTop: 1,
              }}
              textAlign='center'
              variant='h4'
            >
              Verify Your Email
            </Typography>
            <Typography
              color={themeItem.palette.text.label}
              textAlign='center'
              variant='body1'
            >
              We need to make sure you are a human before distributing your projects
            </Typography>
            <Stack
              alignItems='center'
              direction='row'
              justifyContent='center'
              marginTop={2}
              spacing={1}
              width={1}
            >
              <LoadingButton
                color='primary'
                disabled={currentFirebaseUser.emailVerified}
                loading={loadingVerifyEmail}
                onClick={handleVerifyEmail}
                startIcon={<EmailRoundedIcon />}
                variant='contained'
              >
                Send verification link
              </LoadingButton>
              <Button
                color='inherit'
                component={NavLink}
                to='/projects/projects'
                variant='text'
              >
                Close
              </Button>
            </Stack>
            {errorMessageVerification &&
              <Typography color='error'>{errorMessageVerification}</Typography>}
          </Stack>
          {openEmailVerificationModal &&
          <EmailVerificationSentModal
            close={() => {
              setOpenEmailVerificationModal(false)
            }}
            open={openEmailVerificationModal}
          />}
        </Card>}
      </Backdrop>

      <Stack marginBottom={4} spacing={4} width={1}>
        <Stack spacing={1} width={1}>
          {duplicateIsrcError &&
          <Stack width={1}>
            <Alert
              severity='error'
              variant='filled'
            >
              Your release has 2 releases with the same ISRC code, please change them. Albums cannot contain duplicate
              products.
            </Alert>
          </Stack>}

          {duplicateTitleError &&
          <Stack width={1}>
            <Alert
              severity='error'
              variant='filled'
            >
              Your release has 2 releases with the same title, please set a different subversion for these titles.
            </Alert>
          </Stack>}

          {props.recordingTitlesStartWithNumbers &&
          <Stack width={1}>
            <Alert
              severity='error'
              variant='filled'
            >
              Your recording titles cannot start or end with numbers, the order your recordings set in your release will
              dictate the order of the songs in your album.
            </Alert>
          </Stack>}

          {diffDays < 13 &&
          <Stack width={1}>
            <Alert
              severity='info'
              variant='filled'
            >
              Your release date is less than 14 days away, you might not be able to submit your release on Spotify for
              Artists and your release might not reach all stores by your desired release date.
            </Alert>
          </Stack>}

          {uniqueArtists.length > 4 &&
            <Stack width={1}>
              <Alert
                severity='info'
                variant='filled'
              >
                Your release has more than 4 Primary Artists, it will be marked as a Various Artists release on most
                platforms.
              </Alert>
            </Stack>}

          <Stack width={1}>
            <Stack
              alignItems='flex-start'
              direction='row'
              justifyContent='space-between'
              width={1}
            >
              <Typography variant='h3'>
                Overview
              </Typography>
              {props.errorHandling &&
              <Chip color='error' label='Errors found' size='small' variant='filled' />}
            </Stack>
            <Card
              variant='outlined'
            >
              <Stack direction='column' padding={2} width={1}>
                <Stack padding={2} spacing={1}>
                  {props.project?.artworkPreviewUrl
                    ? <Card
                      elevation={0}
                      sx={{
                        padding: 0,
                        background: 'transparent',
                      }}
                    >
                      <img
                        alt='Artwork'
                        height='160px'
                        src={props.project.artworkPreviewUrl}
                        style={{
                          borderRadius: 16,
                          objectFit: 'cover',
                        }}
                        width='160px'
                      />
                    </Card>
                    : <Paper
                      elevation={0}
                      sx={{
                        height: 160,
                        width: 160,
                        backgroundColor: theme => theme.palette.background.input,
                      }}
                    >
                      <MusicNoteRoundedIcon
                        sx={{
                          fontSize: 160,
                          color: theme => theme.palette.action.disabled,
                        }}
                      />
                    </Paper>}
                  {!props.project?.artworkPreviewUrl &&
                  <Chip
                    color='error'
                    label='No artwork provided'
                    size='small'
                    sx={{ width: 'fit-content' }}
                    variant='filled'
                  />}
                </Stack>

                <Stack paddingX={2}>
                  {props.project &&
                  <EditProjectDetailsComponent
                    distributing
                    project={props.project}
                    setProject={props.setProject}
                    updatedFields={value => props.setProject(value)}
                  />}
                </Stack>
              </Stack>
            </Card>
          </Stack>
        </Stack>

        <Stack width={1}>
          <Stack alignItems='center' direction='row' spacing={1}>
            <Typography variant='h3'>
              Territories
            </Typography>
            {props.territories.length > 0
              ? <Chip color='success' label='Ready' size='small' variant='filled' />
              : <Chip color='error' label='Error' size='small' variant='filled' />}
          </Stack>

          <Card variant='outlined'>
            <Stack padding={4} width={1}>
              <Grid container rowSpacing={2} width={1}>
                <Grid item lg={6} md={6} sm={6} xl={6} xs={12}>
                  <Stack alignItems='center' direction='row' justifyContent='space-between' spacing={1} width={0.8}>
                    <Stack width={1}>
                      <Typography color={themeItem.palette.text.label} variant='body2'>
                        Number of territories
                      </Typography>
                    </Stack>
                    <Stack width={1}>
                      <Typography variant='body2'>
                        {props.territories.length.toString()}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
          </Card>
        </Stack>

        <Stack width={1}>
          <Stack alignItems='center' direction='row' spacing={1}>
            <Typography variant='h3'>
              Stores
            </Typography>
            {props.stores.length > 0
              ? <Chip color='success' label='Ready' size='small' variant='filled' />
              : <Chip color='error' label='Error' size='small' variant='filled' />}
          </Stack>
          <Card variant='outlined'>
            <Stack padding={4} width={1}>
              <Grid container rowSpacing={2} width={1}>

                <Grid item lg={6} md={6} sm={6} xl={6} xs={12}>
                  <Stack alignItems='center' direction='row' justifyContent='space-between' spacing={1} width={0.8}>
                    <Stack width={1}>
                      <Typography color={themeItem.palette.text.label} variant='body2'>
                        Number of stores
                      </Typography>
                    </Stack>
                    <Stack width={1}>
                      <Typography variant='body2'>
                        {props.stores.length.toString()}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
          </Card>
        </Stack>
      </Stack>
    </>
  )
}

export default SubmitDistribute
