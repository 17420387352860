import { Paper, Stack, useMediaQuery, useTheme } from '@mui/material'
import type { DateRange } from 'mui-daterange-picker'
import { useEffect, useState } from 'react'

import { getAllClicksPerBrowserLinkProject, getAllClicksPerOsLinkProject, getAllViewsPerBrowserLinkProject, getAllViewsPerOsLinkProject, getClicksLinkProject, getClicksPerCityLinkProject, getClicksPerCountryLinkProject, getClicksPerSourceLinkProject, getDestinationsLinkProject, getDestinationsPresavesLinkProject, getPresavesLinkProject, getViewsLinkProject, getViewsPerCityLinkProject, getViewsPerCountryLinkProject, getViewsPerSourceLinkProject } from 'src/api/allLinkProjectAnalytics'
import SelectServices from 'src/components/pages/Links/ViewLink/ViewLinkComponents/TimePeriodSelect/SelectService'
import TimeSelectAnalytics from 'src/components/pages/Links/ViewLink/ViewLinkComponents/TimePeriodSelect/TimeSelect'
import ViewLinkAppbar from 'src/components/pages/Links/ViewLink/ViewLinkComponents/ViewLinkAppbar'
import ViewLinkDevices from 'src/components/pages/Links/ViewLink/ViewLinkComponents/ViewLinkTypes/ViewLinkDevices'
import ViewLinkLocations from 'src/components/pages/Links/ViewLink/ViewLinkComponents/ViewLinkTypes/ViewLinkLocations'
import ViewLinkOverview from 'src/components/pages/Links/ViewLink/ViewLinkComponents/ViewLinkTypes/ViewLinkOverview'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { MarketingAnalyticsDates, MarketingAnalyticsString } from 'src/models/Marketing'

type Props = {
  projectId: string
}

const LinkAnalyticsProject = (props: Props) => {
  const themeItem = useTheme()
  const mobile = useMediaQuery(themeItem.breakpoints.down('md'))

  const { currentOrganisation } = useAuth()
  const [currentTab, setCurrentTab] = useState('overview')
  const [_, setLoading] = useState(false)
  const [clicks, setClicks] = useState<MarketingAnalyticsDates[]>([])
  const [views, setViews] = useState<MarketingAnalyticsDates[]>([])
  const [presaves, setPresaves] = useState<MarketingAnalyticsDates[]>([])
  const [destinations, setDestinations] = useState<MarketingAnalyticsString[]>([])
  const [destinationsPresaves, setDestinationsPresaves] = useState<MarketingAnalyticsString[]>([])
  const [sourcesClicks, setSourcesClicks] = useState<MarketingAnalyticsString[]>([])
  const [sourcesViews, setSourcesViews] = useState<MarketingAnalyticsString[]>([])
  const [countryClicks, setCountryClicks] = useState<MarketingAnalyticsString[]>([])
  const [countryViews, setCountryViews] = useState<MarketingAnalyticsString[]>([])
  const [cityClicks, setCityClicks] = useState<MarketingAnalyticsString[]>([])
  const [cityViews, setCityViews] = useState<MarketingAnalyticsString[]>([])
  const [browserClicks, setBrowserClicks] = useState<MarketingAnalyticsString[]>([])
  const [browserViews, setBrowserViews] = useState<MarketingAnalyticsString[]>([])
  const [osClicks, setOsClicks] = useState<MarketingAnalyticsString[]>([])
  const [osViews, setOsViews] = useState<MarketingAnalyticsString[]>([])

  const currentDate = new Date()
  const lastSevenDays = new Date()
  lastSevenDays.setDate(currentDate.getDate() - 7)
  const lastTwentyEightDays = new Date()
  lastTwentyEightDays.setDate(currentDate.getDate() - 28)

  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: lastTwentyEightDays,
    endDate: currentDate,
  })

  const [services, setServices] = useState<string[]>([])

  useEffect(() => {
    if (dateRange.startDate && dateRange.endDate) {
      setLoading(true)
      void getClicksLinkProject(dateRange.startDate, dateRange.endDate, services, props.projectId)
        .then(setClicks)
        .finally(() => setLoading(false))
      void getViewsLinkProject(dateRange.startDate, dateRange.endDate, props.projectId)
        .then(setViews)
        .finally(() => setLoading(false))
      void getPresavesLinkProject(dateRange.startDate, dateRange.endDate, services, props.projectId)
        .then(setPresaves)
        .finally(() => setLoading(false))
      void getDestinationsLinkProject(dateRange.startDate, dateRange.endDate, props.projectId)
        .then(setDestinations)
        .finally(() => setLoading(false))
      void getDestinationsPresavesLinkProject(dateRange.startDate, dateRange.endDate, props.projectId)
        .then(setDestinationsPresaves)
        .finally(() => setLoading(false))
      void getViewsPerSourceLinkProject(dateRange.startDate, dateRange.endDate, props.projectId)
        .then(setSourcesViews)
        .finally(() => setLoading(false))
      void getClicksPerSourceLinkProject(dateRange.startDate, dateRange.endDate, services, props.projectId)
        .then(setSourcesClicks)
        .finally(() => setLoading(false))
      void getClicksPerCityLinkProject(dateRange.startDate, dateRange.endDate, services, props.projectId)
        .then(setCityClicks)
        .finally(() => setLoading(false))
      void getViewsPerCityLinkProject(dateRange.startDate, dateRange.endDate, props.projectId)
        .then(setCityViews)
        .finally(() => setLoading(false))
      void getClicksPerCountryLinkProject(dateRange.startDate, dateRange.endDate, services, props.projectId)
        .then(setCountryClicks)
        .finally(() => setLoading(false))
      void getViewsPerCountryLinkProject(dateRange.startDate, dateRange.endDate, props.projectId)
        .then(setCountryViews)
        .finally(() => setLoading(false))
      void getAllClicksPerBrowserLinkProject(dateRange.startDate, dateRange.endDate, services, props.projectId)
        .then(setBrowserClicks)
        .finally(() => setLoading(false))
      void getAllViewsPerBrowserLinkProject(dateRange.startDate, dateRange.endDate, props.projectId)
        .then(setBrowserViews)
        .finally(() => setLoading(false))
      void getAllClicksPerOsLinkProject(dateRange.startDate, dateRange.endDate, services, props.projectId)
        .then(setOsClicks)
        .finally(() => setLoading(false))
      void getAllViewsPerOsLinkProject(dateRange.startDate, dateRange.endDate, props.projectId)
        .then(setOsViews)
        .finally(() => setLoading(false))
    }
  }, [currentOrganisation?.id, dateRange.startDate, dateRange.endDate, services, props.projectId])

  return (
    <Paper
      sx={{
        width: 1,
        height: 1,
        overflow: 'hidden',
        borderRadius: 0,
        background: 'transparent',
      }}
    >
      <Stack
        alignItems='flex-start'
        direction='row'
        height={1}
        width={1}
      >
        <Stack
          alignItems='stretch'
          boxSizing='border-box'
          direction='column'
          height={1}
          minWidth={0}
          padding={0}
          width={1}
        >
          <ViewLinkAppbar
            currentTab={currentTab}
            overall
            setCurrentTab={setCurrentTab}
          />
          <Stack alignItems='center' height={1} overflow='auto' width={1}>
            <Stack
              marginLeft='auto'
              marginRight='auto'
              maxWidth={1332}
              paddingBottom={10}
              paddingTop={1}
              paddingX={2}
              spacing={2}
              width={1}
            >
              <Stack direction={mobile ? 'column' : 'row'} spacing={1} width={1}>
                <TimeSelectAnalytics
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                />
                <Stack marginTop={mobile ? '8px!important' : '16px!important'}>
                  <SelectServices
                    destinations={destinations.map(item => item._id)}
                    services={services}
                    setServices={setServices}
                  />
                </Stack>
              </Stack>
              {(() => {
                switch (currentTab) {
                  case 'locations':
                    return <ViewLinkLocations
                      cityClicks={cityClicks}
                      cityViews={cityViews}
                      countryClicks={countryClicks}
                      countryViews={countryViews}
                    />
                  case 'devices':
                    return <ViewLinkDevices
                      browserClicks={browserClicks}
                      browserViews={browserViews}
                      osClicks={osClicks}
                      osViews={osViews}
                    />
                    // eslint-disable-next-line sonarjs/no-duplicated-branches
                  default:
                    return <ViewLinkOverview
                      clicks={clicks}
                      destinations={destinations}
                      destinationsPresaves={destinationsPresaves}
                      presaves={presaves}
                      sourcesClicks={sourcesClicks}
                      sourcesViews={sourcesViews}
                      views={views}
                    />
                }
              })()}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  )
}

export default LinkAnalyticsProject
