/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable no-duplicate-imports */
/* eslint-disable react/no-unstable-nested-components */
import 'src/styles/Custom/tour.css'

import { useTheme } from '@mui/material'
import type { ReactourStep, ReactourStepContentArgs } from 'reactour'
import Tour from 'reactour'

import TourStep from 'src/components/pages/Tour/TourComponents/TourStep'
import Image1 from 'src/images/Onboarding/Workspace/workspace-1.webp'
import Image2 from 'src/images/Onboarding/Workspace/workspace-2.webp'
import Image3 from 'src/images/Onboarding/Workspace/workspace-3.webp'

type Props = {
  open: boolean
  closeTour: () => void
  finalEvent: () => void
  loading: boolean
}

const TaskboardTour = (props: Props) => {
  const theme = useTheme()

  const steps: ReactourStep[] = [
    {
      content: ({ close, goTo, step }: ReactourStepContentArgs) =>
        <TourStep
          close={close}
          description='Plan, manage, and monitor key work happening accross your music business with Releese.'
          final={false}
          finalAction={props.finalEvent}
          finalText='Got it!'
          first
          goTo={goTo}
          item={<img
            alt='Dashboard'
            height='1562px'
            loading='lazy'
            src={Image1}
            style={{
              marginBottom: '-16px',
              width: '100%',
              height: 'auto',
              transform: 'translateZ(0)',
            }}
            width='2083px'
          />}
          loading={props.loading}
          step={step}
          title='Welcome to your Workspace'
        />
      ,
      position: 'right',
      selector: '#project-tabs',
      style: {
        width: 500,
        maxWidth: 500,
        backgroundColor: 'transparent',
        padding: 0,
      },
    },
    {
      content: ({ close, goTo, step }: ReactourStepContentArgs) =>
        <TourStep
          close={close}
          description='Organize what you need to get done with tasks, and never forget what you need to get done by
          the end of the week.'
          final={false}
          finalAction={props.finalEvent}
          finalText='Got it!'
          goTo={goTo}
          item={<img
            alt='Dashboard'
            height='1562px'
            loading='lazy'
            src={Image2}
            style={{
              marginBottom: '-16px',
              width: '100%',
              height: 'auto',
              transform: 'translateZ(0)',
            }}
            width='2083px'
          />}
          loading={props.loading}
          step={step}
          title='Save time by planning'
        />
      ,
      position: 'right',
      selector: '#collaborators',
      style: {
        width: 500,
        maxWidth: 500,
        backgroundColor: 'transparent',
        padding: 0,
      },
    },
    {
      content: ({ close, goTo, step }: ReactourStepContentArgs) =>
        <TourStep
          close={close}
          description='Open the "Getting started" Document or the "Becoming an expert" Taskboard to learn how to
          manage your music business like a pro.'
          final
          finalAction={props.finalEvent}
          finalText='Got it!'
          goTo={goTo}
          item={<img
            alt='Dashboard'
            height='1562px'
            loading='lazy'
            src={Image3}
            style={{
              marginBottom: '-16px',
              width: '100%',
              height: 'auto',
              transform: 'translateZ(0)',
            }}
            width='2083px'
          />}
          loading={props.loading}
          step={step}
          title='Explore your Workspace'
        />
      ,
      position: 'right',
      selector: '#collaborators',
      style: {
        width: 500,
        maxWidth: 500,
        backgroundColor: 'transparent',
        padding: 0,
      },
    },
  ]

  return (
    <Tour
      accentColor={theme.palette.primary.main}
      closeWithMask={false}
      disableDotsNavigation
      disableFocusLock
      disableKeyboardNavigation
      isOpen={props.open}
      onRequestClose={props.closeTour}
      rounded={16}
      showButtons={false}
      showCloseButton={false}
      showNavigation={false}
      showNavigationNumber={false}
      showNumber={false}
      steps={steps}
    />
  )
}

export default TaskboardTour
