/* eslint-disable react-hooks/exhaustive-deps */
import { Paper, Stack } from '@mui/material'
import type { DateRange } from 'mui-daterange-picker'
import { useEffect, useState } from 'react'

import { getClicksPerBrowser, getClicksPerCity, getClicksPerCountry, getClicksPerId, getClicksPerOs, getClicksPerSource, getDestinations, getDestinationsPresaves, getFans, getPresavesPerId, getViewsPerBrowser, getViewsPerCity, getViewsPerCountry, getViewsPerId, getViewsPerOs, getViewsPerSource } from 'src/api/linksAnalytics'
import SelectServices from 'src/components/pages/Links/ViewLink/ViewLinkComponents/TimePeriodSelect/SelectService'
import TimeSelectAnalytics from 'src/components/pages/Links/ViewLink/ViewLinkComponents/TimePeriodSelect/TimeSelect'
import ViewLinkAppbar from 'src/components/pages/Links/ViewLink/ViewLinkComponents/ViewLinkAppbar'
import ViewLinkDevices from 'src/components/pages/Links/ViewLink/ViewLinkComponents/ViewLinkTypes/ViewLinkDevices'
import ViewLinkFans from 'src/components/pages/Links/ViewLink/ViewLinkComponents/ViewLinkTypes/ViewLinkFans'
import ViewLinkLocations from 'src/components/pages/Links/ViewLink/ViewLinkComponents/ViewLinkTypes/ViewLinkLocations'
import ViewLinkOverview from 'src/components/pages/Links/ViewLink/ViewLinkComponents/ViewLinkTypes/ViewLinkOverview'
import type { MarketingAnalyticsDates, MarketingAnalyticsString, MarketingLink, MarketingLinkFan } from 'src/models/Marketing'

type Props = {
  link: MarketingLink
}

const LinkView = (props: Props) => {
  const [currentTab, setCurrentTab] = useState('overview')
  const [_, setLoading] = useState(false)
  const [fans, setFans] = useState<MarketingLinkFan[]>([])
  const [clicks, setClicks] = useState<MarketingAnalyticsDates[]>([])
  const [views, setViews] = useState<MarketingAnalyticsDates[]>([])
  const [presaves, setPresaves] = useState<MarketingAnalyticsDates[]>([])
  const [destinations, setDestinations] = useState<MarketingAnalyticsString[]>([])
  const [destinationsPresaves, setDestinationsPresaves] = useState<MarketingAnalyticsString[]>([])
  const [sourcesClicks, setSourcesClicks] = useState<MarketingAnalyticsString[]>([])
  const [sourcesViews, setSourcesViews] = useState<MarketingAnalyticsString[]>([])
  const [countryClicks, setCountryClicks] = useState<MarketingAnalyticsString[]>([])
  const [countryViews, setCountryViews] = useState<MarketingAnalyticsString[]>([])
  const [cityClicks, setCityClicks] = useState<MarketingAnalyticsString[]>([])
  const [cityViews, setCityViews] = useState<MarketingAnalyticsString[]>([])
  const [browserClicks, setBrowserClicks] = useState<MarketingAnalyticsString[]>([])
  const [browserViews, setBrowserViews] = useState<MarketingAnalyticsString[]>([])
  const [osClicks, setOsClicks] = useState<MarketingAnalyticsString[]>([])
  const [osViews, setOsViews] = useState<MarketingAnalyticsString[]>([])

  const currentDate = new Date()
  const lastSevenDays = new Date()
  lastSevenDays.setDate(currentDate.getDate() - 7)
  const lastTwentyEightDays = new Date()
  lastTwentyEightDays.setDate(currentDate.getDate() - 28)

  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: lastTwentyEightDays,
    endDate: currentDate,
  })

  const [services, setServices] = useState<string[]>([])

  useEffect(() => {
    getLinkData()
  }, [props.link.id, dateRange.startDate, dateRange.endDate, services])

  const getLinkData = () => {
    if (props.link.id && dateRange.startDate && dateRange.endDate) {
      setLoading(true)
      void getClicksPerId(props.link.id, dateRange.startDate, dateRange.endDate, services)
        .then(setClicks)
        .finally(() => setLoading(false))
      void getViewsPerId(props.link.id, dateRange.startDate, dateRange.endDate)
        .then(setViews)
        .finally(() => setLoading(false))
      void getPresavesPerId(props.link.id, dateRange.startDate, dateRange.endDate)
        .then(setPresaves)
        .finally(() => setLoading(false))
      void getDestinations(props.link.id, dateRange.startDate, dateRange.endDate)
        .then(setDestinations)
        .finally(() => setLoading(false))
      void getDestinationsPresaves(props.link.id, dateRange.startDate, dateRange.endDate)
        .then(setDestinationsPresaves)
        .finally(() => setLoading(false))
      void getViewsPerSource(props.link.id, dateRange.startDate, dateRange.endDate)
        .then(setSourcesViews)
        .finally(() => setLoading(false))
      void getClicksPerSource(props.link.id, dateRange.startDate, dateRange.endDate, services)
        .then(setSourcesClicks)
        .finally(() => setLoading(false))
      void getClicksPerCity(props.link.id, dateRange.startDate, dateRange.endDate, services)
        .then(setCityClicks)
        .finally(() => setLoading(false))
      void getViewsPerCity(props.link.id, dateRange.startDate, dateRange.endDate)
        .then(setCityViews)
        .finally(() => setLoading(false))
      void getClicksPerCountry(props.link.id, dateRange.startDate, dateRange.endDate, services)
        .then(setCountryClicks)
        .finally(() => setLoading(false))
      void getViewsPerCountry(props.link.id, dateRange.startDate, dateRange.endDate)
        .then(setCountryViews)
        .finally(() => setLoading(false))
      void getClicksPerBrowser(props.link.id, dateRange.startDate, dateRange.endDate, services)
        .then(setBrowserClicks)
        .finally(() => setLoading(false))
      void getViewsPerBrowser(props.link.id, dateRange.startDate, dateRange.endDate)
        .then(setBrowserViews)
        .finally(() => setLoading(false))
      void getClicksPerOs(props.link.id, dateRange.startDate, dateRange.endDate, services)
        .then(setOsClicks)
        .finally(() => setLoading(false))
      void getViewsPerOs(props.link.id, dateRange.startDate, dateRange.endDate)
        .then(setOsViews)
        .finally(() => setLoading(false))
      void getFans(props.link.id)
        .then(setFans)
        .finally(() => setLoading(false))
    }
  }

  return (
    <Stack
      sx={{
        height: 1,
        width: 1,
      }}
    >
      <Paper
        sx={{
          width: 1,
          height: 1,
          overflow: 'hidden',
        }}
        variant='outlined'
      >
        <Stack
          alignItems='flex-start'
          direction='row'
          height={1}
          width={1}
        >
          <Stack
            alignItems='stretch'
            boxSizing='border-box'
            direction='column'
            height={1}
            minWidth={0}
            padding={0}
            width={1}
          >
            <ViewLinkAppbar
              currentTab={currentTab}
              hideFans={props.link.type === 'Shortlink' || props.link.type === 'Biolink'}
              setCurrentTab={setCurrentTab}
            />
            <Stack alignItems='center' height={1} overflow='auto' width={1}>
              <Stack
                marginLeft='auto'
                marginRight='auto'
                maxWidth={1332}
                paddingBottom={10}
                paddingTop={1}
                paddingX={3}
                spacing={2}
                width={1}
              >
                <Stack direction='row' spacing={1} width={1}>
                  <TimeSelectAnalytics
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                  />
                  <Stack marginTop='16px!important'>
                    <SelectServices
                      destinations={destinations.map(item => item._id)}
                      services={services}
                      setServices={setServices}
                    />
                  </Stack>
                </Stack>
                {(() => {
                  switch (currentTab) {
                    case 'locations':
                      return <ViewLinkLocations
                        cityClicks={cityClicks}
                        cityViews={cityViews}
                        countryClicks={countryClicks}
                        countryViews={countryViews}
                      />
                    case 'devices':
                      return <ViewLinkDevices
                        browserClicks={browserClicks}
                        browserViews={browserViews}
                        osClicks={osClicks}
                        osViews={osViews}
                      />
                    case 'fans':
                      return <ViewLinkFans
                        marketingLinkFans={fans}
                      />
                      // eslint-disable-next-line sonarjs/no-duplicated-branches
                    default:
                      return <ViewLinkOverview
                        clicks={clicks}
                        destinations={destinations}
                        destinationsPresaves={destinationsPresaves}
                        presaves={presaves}
                        sourcesClicks={sourcesClicks}
                        sourcesViews={sourcesViews}
                        views={views}
                      />
                  }
                })()}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Paper>
    </Stack>
  )
}

export default LinkView
