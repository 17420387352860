import EventRoundedIcon from '@mui/icons-material/EventRounded'
import { Card, Stack, Typography } from '@mui/material'
import dayjs from 'dayjs'

const EarningsDistributionNextPayout = () => {
  const date = new Date().getDate() > 25
    ? new Date(new Date().setDate(25)).setMonth(new Date().getMonth() + 1)
    : new Date(new Date().setDate(25))

  return (
    <Stack height={1} width={1}>
      <Card
        elevation={0}
        sx={{
          height: 1,
          background: theme => theme.palette.background.elevatedCard,
        }}
      >
        <Stack height={1} justifyContent='space-between' padding={2} spacing={3}>
          <Stack direction='row' spacing={2} width={1}>
            <Stack spacing={2} width={1}>
              <Typography variant='body1'>
                Next payout
              </Typography>
              <Typography variant='h3'>
                {dayjs(date).format('MMMM, YYYY')}
              </Typography>
            </Stack>
            <Stack spacing={2}>
              <EventRoundedIcon color='info' sx={{ fontSize: 48 }} />
            </Stack>
          </Stack>
          <Typography variant='textLabel'>
            Estimated payout date:
            {' '}
            {dayjs(date).format('LL')}
          </Typography>
        </Stack>
      </Card>
    </Stack>
  )
}

export default EarningsDistributionNextPayout

