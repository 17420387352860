/* eslint-disable @typescript-eslint/no-explicit-any */
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { LoadingButton } from '@mui/lab'
import { Button, IconButton, Paper, Stack, Typography } from '@mui/material'
import type { ReactElement } from 'react'

type Props = {
  close: () => void
  finalAction: () => void
  title: string
  description: string
  final: boolean
  first?: boolean
  step: number
  goTo: (step: number) => void
  item?: ReactElement<any, any>
  loading: boolean
  finalText: string
}

const TourStep = (props: Props) => {
  const setupProject = () => {
    props.finalAction()
  }
  return (
    <Stack
      direction='column'
      spacing={2}
      sx={{
        borderRadius: 1,
        overflow: 'hidden',
      }}
      width={1}
    >
      {props.item &&
      <Stack alignItems='center' position='relative' width={1}>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='flex-end'
          position='absolute'
          right={16}
          top={16}
          zIndex={3}
        >
          <IconButton onClick={props.close} size='small'>
            <CloseRoundedIcon />
          </IconButton>
        </Stack>
        {props.item}
      </Stack>}
      <Paper elevation={0} sx={{ borderRadius: 0 }}>
        <Stack
          alignItems='center'
          padding={2}
          position='relative'
          spacing={2}
          sx={{
            marginTop: '15px!important',
          }}
          width={1}
        >
          <Typography
            lineHeight={1}
            textAlign='center'
            variant='h3'
          >
            {props.title}
          </Typography>
          <Typography sx={{ marginTop: '8px!important' }} textAlign='center' variant='body1'>
            {props.description}
          </Typography>
          {props.final &&
          <LoadingButton
            color='secondary'
            fullWidth
            loading={props.loading}
            onClick={() => setupProject()}
            variant='contained'
          >
            {props.finalText}
          </LoadingButton>}
          {!props.final &&
          <Stack direction='row' justifyContent='flex-end' spacing={1} width={1}>
            {!props.first &&
            <Button onClick={() => props.goTo(props.step - 2)} variant='text' >
              Previous
            </Button>}
            <Button onClick={() => props.goTo(props.step)} variant='subtle'>
              Continue
            </Button>
          </Stack>}
        </Stack>
      </Paper>
    </Stack>
  )
}

export default TourStep
