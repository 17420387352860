import { Grid, Stack } from '@mui/material'

import AnalyticsCreationsLifetime from 'src/components/pages/Analytics/AnalyticsDistribution/Overview/AnalyticsCreationsLifetime'
import AnalyticsDownloadsLifetime from 'src/components/pages/Analytics/AnalyticsDistribution/Overview/AnalyticsDistributionDownloads'
import AnalyticsDistributionLifetime from 'src/components/pages/Analytics/AnalyticsDistribution/Overview/AnalyticsDistributionLifetime'
import AnalyticsViewsLifetime from 'src/components/pages/Analytics/AnalyticsDistribution/Overview/AnalytivsViewsLifetime'
import TopReleasesChart from 'src/components/pages/Earnings/EarningsDistribution/Overview/TopReleasesChart'
import type { AnalyticsMonthlyChart } from 'src/models/Analytics'

type Props = {
  data: AnalyticsMonthlyChart[]
  selectedRecording: string
  topReleases: AnalyticsMonthlyChart[]
  periods: string[]
}

const AnalyticsDistributionOverview = (props: Props) =>
  <Stack spacing={3} width={1}>
    <Grid container spacing={1}>
      <Grid item lg={3} md={6} sm={12} xs={12}>
        <AnalyticsDistributionLifetime
          data={props.data}
          periods={props.periods}
          selectedRecording={props.selectedRecording}
        />
      </Grid>
      <Grid item lg={3} md={6} sm={12} xs={12}>
        <AnalyticsViewsLifetime
          data={props.data}
          periods={props.periods}
          selectedRecording={props.selectedRecording}
        />
      </Grid>
      <Grid item lg={3} md={6} sm={12} xs={12}>
        <AnalyticsDownloadsLifetime
          data={props.data}
          periods={props.periods}
          selectedRecording={props.selectedRecording}
        />
      </Grid>
      <Grid item lg={3} md={6} sm={12} xs={12}>
        <AnalyticsCreationsLifetime
          data={props.data}
          periods={props.periods}
          selectedRecording={props.selectedRecording}
        />
      </Grid>
    </Grid>
    {props.topReleases.length > 0 && props.selectedRecording.length === 0 &&
    <Stack width={1}>
      <TopReleasesChart
        analytics
        data={props.topReleases}
      />
    </Stack>}
  </Stack>

export default AnalyticsDistributionOverview
