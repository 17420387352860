import { Grid, Stack } from '@mui/material'

import EarningsDistributionLifetime from 'src/components/pages/Earnings/EarningsDistribution/Overview/EarningsDistributionLifetime'
import EarningsDistributionNextPayout from 'src/components/pages/Earnings/EarningsDistribution/Overview/EarningsDistributionNextPayout'
import EarningsStatementArea from 'src/components/pages/Earnings/EarningsDistribution/Overview/EarningsOverviewNewChart'
import MyEarningsDistributionLifetime from 'src/components/pages/Earnings/EarningsDistribution/Overview/MyEarningsLifetime'
import TopArtistsChart from 'src/components/pages/Earnings/EarningsDistribution/Overview/TopArtistsChart'
import TopReleasesChart from 'src/components/pages/Earnings/EarningsDistribution/Overview/TopReleasesChart'
import type { AnalyticsMonthlyChart, AnalyticsTopArtists } from 'src/models/Analytics'

type Props = {
  data: AnalyticsMonthlyChart[]
  selectedRecording: string
  topReleases: AnalyticsMonthlyChart[]
  netIncome: AnalyticsMonthlyChart[]
  isOwner: boolean
  topArtists: AnalyticsTopArtists[]
  periods: string[]
}

const EarningsDistributionOverview = (props: Props) =>
  <Stack spacing={3} width={1}>
    <EarningsStatementArea
      data={props.data}
      isOwner={props.isOwner}
      netIncome={props.netIncome}
    />
    <Grid container spacing={1} sx={{ marginLeft: '-8px!important' }}>
      <Grid item lg={4} md={12} sm={12} xs={12}>
        <EarningsDistributionLifetime
          data={props.data}
          periods={props.periods}
          selectedRecording={props.selectedRecording}
        />
      </Grid>
      <Grid item lg={4} md={12} sm={12} xs={12}>
        <MyEarningsDistributionLifetime
          grossData={props.data}
          isOwner={props.isOwner}
          netIncome={props.netIncome}
          periods={props.periods}
          selectedRecording={props.selectedRecording}
        />
      </Grid>
      <Grid item lg={4} md={12} sm={12} xs={12}>
        <EarningsDistributionNextPayout />
      </Grid>
    </Grid>
    {props.topReleases.length > 0 && props.selectedRecording.length === 0 &&
      <Stack width={1}>
        <TopReleasesChart
          data={props.topReleases}
        />
      </Stack>}
    {props.topReleases.length > 0 && props.selectedRecording.length === 0 && props.topArtists.length > 0 &&
      <Stack width={1}>
        <TopArtistsChart
          artists={props.topArtists}
          data={props.topReleases}
        />
      </Stack>}
  </Stack>

export default EarningsDistributionOverview
