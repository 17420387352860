/* eslint-disable react-hooks/exhaustive-deps */
import type { FC } from 'react'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import TagManager from 'react-gtm-module'

import type { EarlyAuth } from 'src/components/providers/AuthProvider'
import { useAuth } from 'src/components/providers/AuthProvider'

type GtmContextProps = {
  gtmPixel?: string
  setGtmPixel: (gtmTag: string) => void
}

export type InitProps = Omit<GtmContextProps,
'currentTime' | 'playing' | 'recording'> & {
  gtmPixel: GtmContextProps['gtmPixel'] | null | undefined
}

const initialValue = {
  gtmPixel: undefined,
} as InitProps

const GtmContext = createContext(initialValue as GtmContextProps)

export const useGtm = () => useContext(GtmContext)

export const GtmProvider: FC = props => {
  const [gtmPixel, setGtmPixel] = useState<InitProps['gtmPixel']>()
  const { currentOrganisation } = useAuth() as EarlyAuth

  useEffect(() => {
    if (currentOrganisation && !gtmPixel) {
      const tagmanagerArguments = {
        gtmId: process.env.REACT_APP_GTM ?? '',
        dataLayer: {
          userId: currentOrganisation.id,
        },
      }
      TagManager.initialize(tagmanagerArguments)
      setGtmPixel(process.env.REACT_APP_GTM ?? '')
    }
  }, [currentOrganisation?.id, gtmPixel])

  const value = useMemo<InitProps>(
    () => ({
      gtmPixel,
      setGtmPixel,
    }),
    [gtmPixel]
  ) as GtmContextProps

  return (
    <GtmContext.Provider value={value}>
      {props.children}
    </GtmContext.Provider>
  )
}
