/* eslint-disable id-length */
/* eslint-disable @typescript-eslint/no-magic-numbers */
import { Stack, Typography, useTheme } from '@mui/material'
import { useRef } from 'react'
import Chart from 'react-apexcharts'

import { useContainerDimensions } from 'src/components/ContainerDimensions'
import type { MarketingAnalyticsDates } from 'src/models/Marketing'

type Props = {
  clicks: MarketingAnalyticsDates[]
  views: MarketingAnalyticsDates[]
  presaves: MarketingAnalyticsDates[]
}

type DateTimeFormat =
  'category' | 'datetime' | 'numeric' | undefined

const TrafficChart = (props: Props) => {
  const dateObject: DateTimeFormat = 'datetime'
  const theme = useTheme()

  const presavesData = props.presaves
    .sort((a, b) => Number(new Date(a._id)) - Number(new Date(b._id)))
    .map(({ _id, count }) => ({ x: _id, y: count }))
  const clicksData = props.clicks
    .sort((a, b) => Number(new Date(a._id)) - Number(new Date(b._id)))
    .map(({ _id, count }) => ({ x: _id, y: count }))
  const viewsData = props.views
    .sort((a, b) => Number(new Date(a._id)) - Number(new Date(b._id)))
    .map(({ _id, count }) => ({ x: _id, y: count }))

  const seriesPriority = [
    {
      name: 'Clicks',
      data: clicksData,
    },
    {
      name: 'Views',
      data: viewsData,
    },
    {
      name: 'Pre-saves',
      data: presavesData,
    },
  ]

  const optionsSectionsOverdue = {
    options: {
      chart: {
        width: '100%',
        toolbar: {
          show: false,
        },
        stacked: false,
        stroke: {
          curve: 'smooth',
        },
        zoom: {
          enabled: false,
        },
        foreColor: theme.palette.text.primary,
      },
      tooltip: {
        theme: 'dark',
        x: {
          show: false,
        },
      },
      colors: [theme.palette.primary.main, theme.palette.secondary.main, theme.palette.success.main],
      fill: {
        type: 'gradient',
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        borderColor: theme.palette.divider,
      },
      yaxis: {
        min: 0,
        labels: {
          formatter: (value: number) => value.toFixed(0),
        },
        axisBorder: {
          color: theme.palette.divider,
        },
        axisTicks: {
          color: theme.palette.divider,
        },
      },
      xaxis: {
        type: dateObject,
        labels: {
          format: 'dd/MM',
        },
        tooltip: {
          enabled: false,
        },
        axisBorder: {
          color: theme.palette.divider,
        },
        axisTicks: {
          color: theme.palette.divider,
        },
      },
    },
  }

  const componentRef = useRef<HTMLDivElement>(null)
  const { width } = useContainerDimensions(componentRef)

  return (
    <>
      <Typography variant='h3'>
        Traffic
      </Typography>
      <Stack alignItems='center' ref={componentRef} width={1} >
        <Chart
          height={400}
          options={optionsSectionsOverdue.options}
          series={seriesPriority}
          type='area'
          width={width}
        />
      </Stack>
    </>
  )
}
export default TrafficChart

