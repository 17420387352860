export class FileItem {
  id?: string
  title = ''
  size = 0
  parent = ''
  hash = ''
  url = ''
  file?: File
  organisationId?: string
  projectId?: string
  taskboardId?: string
  taskId?: string
  createdAt = new Date()
  updates: FileUpdate[] = []
  description = ''

  constructor(dto: Partial<FileItem>) {
    Object.assign(this, dto)
  }
}

export type FileDto = Partial<FileItem>

export class FileUpdate {
  id = ''
  account: string
  message: string
  timestamp = new Date()

  constructor(dto: FileUpdate & { account: string, message: string }) {
    Object.assign(this, dto)
    this.account = dto.account
    this.message = dto.message
  }
}

export type FileUpdateDto = Partial<FileUpdate>
