/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable react/jsx-key */
/* eslint-disable sonarjs/no-small-switch */
/* eslint-disable react-hooks/exhaustive-deps */
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded'
import { Avatar, Button, Card, Divider, Grid, ListItemText, Skeleton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { createRef, useEffect, useState } from 'react'

import { getAnalyticsActivityArtist, getAnalyticsActivityArtistFree, getAnalyticsActivityLabel, getAnalyticsArtist, getAnalyticsArtistFree, getAnalyticsAudienceArtist, getAnalyticsAudienceArtistFree, getAnalyticsAudienceLabel, getAnalyticsCatalogArtist, getAnalyticsCatalogArtistFree, getAnalyticsCatalogLabel, getAnalyticsCurrentArtist, getAnalyticsCurrentArtistFree, getAnalyticsCurrentLabel, getAnalyticsLabel, getAnalyticsProfileArtist, getAnalyticsProfileArtistFree, getAnalyticsProfileLabel, getAnalyticsTopPlaylistsArtist, getAnalyticsTopPlaylistsArtistFree, getAnalyticsTopPlaylistsLabel } from 'src/api/analyticsCloud'
import ActivityRows from 'src/components/pages/Audience/Components/Activity/ActivityRows'
import AnalyticsAudience from 'src/components/pages/Audience/Components/Audience/AnalyticsAudience'
import AnalyticsProfileUi from 'src/components/pages/Audience/Components/Audience/AnalyticsCloudOverview'
import CatalogRows from 'src/components/pages/Audience/Components/Catalog/CatalogRows'
import AnalyticsTotalNavMobile from 'src/components/pages/Audience/Components/Navigation/AnalyticsTotalMobileNav'
import AnalyticsTotalNav from 'src/components/pages/Audience/Components/Navigation/AnalyticsTotalNav'
import AnalyticsTotalOnboarding from 'src/components/pages/Audience/Components/Onboarding/AnalyticsTotalOnboarding'
import AnalyticsPlatformDistribution from 'src/components/pages/Audience/Components/Overview/OverviewPlatform'
import TopPlaylistsRows from 'src/components/pages/Audience/Components/Playlists/TopPlaylists'
import AnalyticsModalCta from 'src/components/pages/Audience/Modals/AnalyticsModalCta'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { AnalyticsActivity, AnalyticsAudienceData, AnalyticsCatalog, AnalyticsCurrentData, AnalyticsHistoricData, AnalyticsProfileData } from 'src/models/Analytics'
import type { TopPlaylists } from 'src/models/Analytics'
import { Permissions } from 'src/models/Organisation'
import SPACING from 'src/styles/spacing'

const AnalyticsTotal = () => {
  const themeItem = useTheme()
  const mobile = useMediaQuery(themeItem.breakpoints.down('md'))
  const { currentOrganisation, currentAccountPermissions } = useAuth()
  const [loading, setLoading] = useState(false)
  const [loadingHistoric, setLoadingHistoric] = useState(false)
  const [loadingActivities, setLoadingActivities] = useState(false)
  const [currentTab, setCurrentTab] = useState('Overview')
  const [historicData, setHistoricData] = useState<AnalyticsHistoricData>()
  const [currentData, setCurrentData] = useState<AnalyticsCurrentData>()
  const [catalogData, setCatalogData] = useState<AnalyticsCatalog>()
  const [activityData, setActivityData] = useState<AnalyticsActivity>()
  const [profileData, setProfileData] = useState<AnalyticsProfileData>()
  const [audienceData, setAudienceData] = useState<AnalyticsAudienceData>()
  const [topPlaylistsData, setTopPlaylistsData] = useState<TopPlaylists>()
  const [billingModal, setBillingModalOpen] = useState(false)

  const refContainer = createRef<HTMLDivElement>()

  useEffect(() => {
    if (currentOrganisation?.analyticsOnboarded &&
      currentOrganisation.membershipTier !== 'FREE') {
      getData()
    } else if (currentOrganisation?.membershipTier === 'FREE') {
      getDataFree()
    }
  }, [currentOrganisation])

  useEffect(() => {
    if (refContainer.current) {
      refContainer.current.scrollTo(0, 0)
    }
  }, [currentTab])

  const getData = () => {
    setCurrentTab('Overview')
    setLoading(true)
    setLoadingHistoric(true)
    setLoadingActivities(true)
    if (currentOrganisation?.analyticsOnboarded &&
      currentOrganisation.analyticsType === 'artist' &&
      currentOrganisation.analyticsArtistId &&
      currentOrganisation.analyticsArtistId.length > 0) {
      void getAnalyticsArtist()
        .then(result =>
          setHistoricData(result))
        .finally(() => setLoading(false))
        .catch(() => setLoading(false))
      void getAnalyticsCurrentArtist()
        .then(resultCurrent =>
          setCurrentData(resultCurrent))
        .finally(() => setLoadingHistoric(false))
        .catch(() => setLoadingHistoric(false))
      void getAnalyticsTopPlaylistsArtist()
        .then(result =>
          setTopPlaylistsData(result))
        .finally(() => setLoading(false))
        .catch(() => setLoading(false))
      void getAnalyticsCatalogArtist()
        .then(resultCurrent =>
          setCatalogData(resultCurrent))
        .finally(() => setLoadingHistoric(false))
        .catch(() => setLoadingHistoric(false))
      void getAnalyticsActivityArtist()
        .then(result =>
          setActivityData(result))
        .finally(() => setLoadingActivities(false))
        .catch(() => setLoadingActivities(false))
      void getAnalyticsAudienceArtist()
        .then(result =>
          setAudienceData(result))
        .finally(() => setLoading(false))
        .catch(() => setLoading(false))
      void getAnalyticsProfileArtist()
        .then(result =>
          setProfileData(result))
        .finally(() => setLoading(false))
        .catch(() => setLoading(false))
    } else if (currentOrganisation?.analyticsOnboarded &&
      currentOrganisation.analyticsType === 'label' &&
      currentOrganisation.analyticsLabelId &&
      currentOrganisation.analyticsLabelId.length > 0) {
      void getAnalyticsLabel()
        .then(result =>
          setHistoricData(result))
        .finally(() => setLoading(false))
        .catch(() => setLoading(false))
      void getAnalyticsTopPlaylistsLabel()
        .then(result =>
          setTopPlaylistsData(result))
        .finally(() => setLoading(false))
        .catch(() => setLoading(false))
      void getAnalyticsCurrentLabel()
        .then(resultCurrent =>
          setCurrentData(resultCurrent))
        .finally(() => setLoadingHistoric(false))
        .catch(() => setLoadingHistoric(false))
      void getAnalyticsCatalogLabel()
        .then(resultCurrent =>
          setCatalogData(resultCurrent))
        .finally(() => setLoadingHistoric(false))
        .catch(() => setLoadingHistoric(false))
      void getAnalyticsActivityLabel()
        .then(result =>
          setActivityData(result))
        .finally(() => setLoadingActivities(false))
        .catch(() => setLoadingActivities(false))
      void getAnalyticsProfileLabel()
        .then(result =>
          setProfileData(result))
        .finally(() => setLoading(false))
        .catch(() => setLoading(false))
      void getAnalyticsAudienceLabel()
        .then(result =>
          setAudienceData(result))
        .finally(() => setLoading(false))
        .catch(() => setLoading(false))
    }
  }

  const getDataFree = () => {
    setCurrentTab('Overview')
    setLoading(true)
    setLoadingHistoric(true)
    setLoadingActivities(true)
    void getAnalyticsArtistFree()
      .then(result =>
        setHistoricData(result))
      .finally(() => setLoading(false))
      .catch(() => setLoading(false))
    void getAnalyticsTopPlaylistsArtistFree()
      .then(result =>
        setTopPlaylistsData(result))
      .finally(() => setLoading(false))
      .catch(() => setLoading(false))
    void getAnalyticsCurrentArtistFree()
      .then(resultCurrent =>
        setCurrentData(resultCurrent))
      .finally(() => setLoadingHistoric(false))
      .catch(() => setLoadingHistoric(false))
    void getAnalyticsCatalogArtistFree()
      .then(resultCurrent =>
        setCatalogData(resultCurrent))
      .finally(() => setLoadingHistoric(false))
      .catch(() => setLoadingHistoric(false))
    void getAnalyticsActivityArtistFree()
      .then(result =>
        setActivityData(result))
      .finally(() => setLoadingActivities(false))
      .catch(() => setLoadingActivities(false))
    void getAnalyticsProfileArtistFree()
      .then(result =>
        setProfileData(result))
      .finally(() => setLoading(false))
      .catch(() => setLoading(false))
    void getAnalyticsAudienceArtistFree()
      .then(result =>
        setAudienceData(result))
      .finally(() => setLoading(false))
      .catch(() => setLoading(false))
  }

  return (
    <>
      <AnalyticsModalCta
        close={() => setBillingModalOpen(false)}
        open={billingModal}
      />
      <Stack direction='row' height={1} id='analytics-container'>
        {currentOrganisation &&
        currentOrganisation.membershipTier !== 'FREE' &&
        !currentOrganisation.analyticsOnboarded &&
        <Stack
          alignItems='stretch'
          boxSizing='border-box'
          direction='column'
          height={1}
          minWidth={0}
          padding={0}
          width={1}
        >
          <Stack alignItems='center' height={1} overflow='auto' width={1}>
            <AnalyticsTotalOnboarding />
          </Stack>
        </Stack>}

        {currentOrganisation &&
        (currentOrganisation.analyticsOnboarded ||
        currentOrganisation.membershipTier === 'FREE') &&
        <Stack
          alignItems='stretch'
          boxSizing='border-box'
          direction={mobile ? 'column' : 'row'}
          height={1}
          minWidth={0}
          padding={0}
          width={1}
        >
          {mobile
            ? <AnalyticsTotalNavMobile
              currentData={currentData}
              currentTab={currentTab}
              profile
              profileData={profileData}
              setCurrentTab={setCurrentTab}
            />
            : <>
              <AnalyticsTotalNav
                currentData={currentData}
                currentTab={currentTab}
                profileData={profileData}
                setCurrentTab={setCurrentTab}
              />
              <Divider
                orientation='vertical'
                sx={{
                  height: 1,
                }}
              />
            </>}
          <Stack alignItems='center' height={1} overflow='auto' ref={refContainer} width={1}>
            <Stack
              marginLeft='auto'
              marginRight='auto'
              maxWidth={1332}
              padding={SPACING.CONTENT_PADDING}
              paddingBottom={10}
              width={1}
            >
              {currentOrganisation.membershipTier === 'FREE' &&
                <Stack alignItems='center' direction='column' marginBottom={2} spacing={1}>
                  <Card
                    elevation={0}
                    sx={{
                      background: theme => theme.palette.background.input,
                      width: 'fit-content',
                      overflow: 'inherit',
                    }}
                  >
                    <Stack alignItems='center' direction='row' padding={1.5} spacing={1}>
                      <Avatar
                        src='https://cdn.releese.io/weeknd.jpeg'
                        sx={{
                          width: 48,
                          height: 48,
                        }}
                      />
                      <ListItemText
                        primary='The Weeknd'
                        secondary='Sample data'
                      />
                    </Stack>
                  </Card>
                  <Button
                    disabled={!currentAccountPermissions?.includes(Permissions.MANAGE_BILLING)}
                    onClick={() => setBillingModalOpen(true)}
                    startIcon={<AutoAwesomeRoundedIcon />}
                    variant='contained'
                  >
                    Claim your profile
                  </Button>
                </Stack>}
              {!loading && !loadingHistoric &&
                <>
                  {currentTab === 'Overview' &&
                  <AnalyticsProfileUi
                    currentData={currentData}
                    historicData={historicData}
                    profileData={profileData}
                  />}
                  <AnalyticsPlatformDistribution
                    artworkUrl={currentOrganisation.logoUrl}
                    currentData={currentData}
                    currentTab={currentTab}
                    historicData={historicData}
                    loadingActivities={false}
                  />
                  {loadingActivities
                    ? <Stack sx={{ width: '100%', mb: 2 }} width={1}>
                      <Typography variant='h3'>
                        Recent activities
                      </Typography>
                      <Stack
                        spacing={1}
                        width='100%'
                      >
                        <Skeleton height={110} sx={{ transform: 'none ' }} width='100%' />
                        <Skeleton height={110} sx={{ transform: 'none ' }} width='100%' />
                        <Skeleton height={110} sx={{ transform: 'none ' }} width='100%' />
                        <Skeleton height={110} sx={{ transform: 'none ' }} width='100%' />
                        <Skeleton height={110} sx={{ transform: 'none ' }} width='100%' />
                        <Skeleton height={110} sx={{ transform: 'none ' }} width='100%' />
                      </Stack>
                    </Stack>
                    : activityData?.data &&
                      currentTab !== 'Catalog' &&
                      <ActivityRows
                        chartItems={activityData.data}
                        currentTab={currentTab}
                        overview={currentTab === 'Overview'}
                      />}
                  {topPlaylistsData?.data &&
                  currentTab !== 'Catalog' &&
                  topPlaylistsData.data.some(item => item.source === currentTab || currentTab === 'Overview') &&
                  <TopPlaylistsRows
                    currentTab={currentTab}
                    topPlaylistItems={topPlaylistsData}
                  />}
                  {audienceData &&
                  currentTab !== 'Catalog' &&
                  <AnalyticsAudience
                    artworkUrl={currentOrganisation.logoUrl}
                    audienceData={audienceData}
                    currentTab={currentTab}
                  />}
                  {catalogData?.links &&
                  currentTab === 'Catalog' &&
                  <CatalogRows
                    chartItems={catalogData.links}
                  />}
                </>}

              {(loading || loadingHistoric) &&
                <Stack spacing={3} width={1}>
                  <Typography variant='h2'>
                    <Skeleton />
                  </Typography>
                  <Grid container spacing={2} sx={{ marginLeft: '-16px!important' }}>
                    {Array.from({ length: 8 }, () =>
                      <Grid item lg={6} md={6} sm={6} xl={3} xs={12}>
                        <Skeleton variant='rounded' width='100%'>
                          <div style={{ paddingTop: '57%' }} />
                        </Skeleton>
                      </Grid>)}
                  </Grid>
                  <Skeleton height={400} variant='rounded' width='100%'>
                    <div />
                  </Skeleton>
                </Stack>}
            </Stack>
          </Stack>
        </Stack>}
      </Stack>
    </>
  )
}

export default AnalyticsTotal
