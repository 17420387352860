import CreditCardRoundedIcon from '@mui/icons-material/CreditCardRounded'
import ReceiptRoundedIcon from '@mui/icons-material/ReceiptRounded'
import { TabContext, TabList } from '@mui/lab'
import { Divider, Stack, Tab } from '@mui/material'
import { useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'

import BillingModal from 'src/components/pages/Account/Billing/BillingModal'
import Overview from 'src/components/pages/Settings/Billing/BillingTabs/Overview'
import PaymentHistory from 'src/components/pages/Settings/Billing/BillingTabs/PaymentHistory'
import { useAuth } from 'src/components/providers/AuthProvider'
import { NavLink } from 'src/components/SafeRouterLink'

const Billing = () => {
  const location = useLocation().pathname
  const { currentOrganisation } = useAuth()
  const [currentTab, setCurrentTab] = useState(location)
  const [billingModal, setBillingModalOpen] = useState(false)
  return (
    <TabContext value={currentTab}>
      <Stack
        direction='column'
        width={1}
      >
        <Stack
          direction='row'
          width={1}
        >
          {billingModal && currentOrganisation &&
          <BillingModal
            account={currentOrganisation}
            close={() => setBillingModalOpen(false)}
            open={billingModal}
          />}
          <TabList
            aria-label='Current billing tab'
            onChange={(event, value: string) => setCurrentTab(value)}
          >
            <Tab
              component={NavLink}
              icon={<CreditCardRoundedIcon />}
              label='Overview'
              to='/settings/billing/overview'
              value='/settings/billing/overview'
            />
            <Tab
              component={NavLink}
              icon={<ReceiptRoundedIcon />}
              label='Payment history'
              to='/settings/billing/history'
              value='/settings/billing/history'
            />
          </TabList>
        </Stack>
        <Divider />
      </Stack>
      <Stack height={1} overflow='auto' width={1}>
        <Routes>
          <Route element={<Overview setBillingModalOpen={setBillingModalOpen} />} path='/overview' />
          <Route element={<PaymentHistory />} path='/history' />
        </Routes>
      </Stack>
    </TabContext>

  )
}
export default Billing
