import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import { Avatar, Card, Chip, Divider, Grid, Paper, Stack, Typography, useTheme } from '@mui/material'
import moment from 'moment'

import type Project from 'src/models/Project'

type Props = {
  project?: Project
  marketing: string
  artist: string
  priorityTitle: string
  country: string
  fanbaseLocation: string
  similarArtists: string
  genre: string
}

const SubmitPitch = (props: Props) => {
  const themeItem = useTheme()
  const dateItem = props.project?.releaseDate.toISOString().slice(0, 10)
  const testItem = moment(dateItem).tz('America/New_York')
  return (
    <Card elevation={0}>
      <Stack padding={2} spacing={4} width={1}>
        <Stack spacing={1} width={1}>
          <Stack alignItems='center' direction='row' spacing={1}>
            <Typography variant='h3'>
              Overview
            </Typography>
          </Stack>

          <Grid container rowSpacing={2} width={1}>
            <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
              <Stack spacing={1}>
                {props.project?.artworkPreviewUrl
                  ? <Card elevation={0} sx={{ padding: 0 }}>
                    <img
                      alt='Artwork'
                      height='240px'
                      src={props.project.artworkPreviewUrl}
                      style={{
                        borderRadius: 16,
                        objectFit: 'cover',
                      }}
                      width='240px'
                    />
                  </Card>
                  : <Paper
                    elevation={0}
                    sx={{
                      height: 240,
                      width: 240,
                      backgroundColor: themeItem.palette.background.input,
                    }}
                  >
                    <MusicNoteRoundedIcon
                      sx={{
                        fontSize: 240,
                        color: themeItem.palette.action.disabled,
                      }}
                    />
                  </Paper>}
              </Stack>
            </Grid>

            <Grid item lg={6} md={6} sm={6} xl={6} xs={12}>
              <Stack alignItems='center' direction='row' justifyContent='space-between' spacing={1} width={0.8}>
                <Stack alignItems='center' direction='row' spacing={1} width={1}>
                  <Typography color={themeItem.palette.text.label} variant='body2'>
                    Release Title
                  </Typography>
                </Stack>
                <Stack width={1}>
                  <Typography variant='body2'>
                    {props.project?.title}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>

            <Grid item lg={6} md={6} sm={6} xl={6} xs={12}>
              <Stack alignItems='center' direction='row' justifyContent='space-between' spacing={1} width={0.8}>
                <Stack alignItems='center' direction='row' spacing={1} width={1}>
                  <Typography color={themeItem.palette.text.label} variant='body2'>
                    Primary Artists
                  </Typography>
                </Stack>
                <Stack direction='row' flexWrap='wrap' spacing={1} width={1}>
                  {props.project?.primaryArtists && props.project.primaryArtists.length > 0
                    ? props.project.primaryArtists.map(artist =>
                      <Chip
                        avatar={
                          <Avatar src={artist.images?.[0]?.url ? artist.images[0].url : ''} />
                        }
                        key={artist.name}
                        label={artist.name}
                        variant='subtle'
                      />)
                    : <Typography variant='body2'>
                      No artists found
                    </Typography>}
                </Stack>
              </Stack>
            </Grid>

            <Grid item lg={6} md={6} sm={6} xl={6} xs={12}>
              <Stack alignItems='center' direction='row' justifyContent='space-between' spacing={1} width={0.8}>
                <Stack alignItems='center' direction='row' spacing={1} width={1}>
                  <Typography color={themeItem.palette.text.label} variant='body2'>
                    Featured Artists
                  </Typography>
                </Stack>
                <Stack direction='row' flexWrap='wrap' spacing={1} width={1}>
                  {props.project?.featuredArtists && props.project.featuredArtists.length > 0 &&
                props.project.featuredArtists.map(featuredArtist =>
                  <Chip
                    avatar={
                      <Avatar src={featuredArtist.images?.[0]?.url ? featuredArtist.images[0].url : ''} />
                    }
                    key={featuredArtist.name}
                    label={featuredArtist.name}
                    variant='subtle'
                  />)}
                </Stack>
              </Stack>
            </Grid>

            <Grid item lg={6} md={6} sm={6} xl={6} xs={12}>
              <Stack alignItems='center' direction='row' justifyContent='space-between' spacing={1} width={0.8}>
                <Stack alignItems='center' direction='row' spacing={1} width={1}>
                  <Typography color={themeItem.palette.text.label} variant='body2'>
                    Genre
                  </Typography>
                </Stack>
                <Stack width={1}>
                  <Typography variant='body2'>
                    {props.project?.genre}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>

            <Grid item lg={6} md={6} sm={6} xl={6} xs={12}>
              <Stack alignItems='center' direction='row' justifyContent='space-between' spacing={1} width={0.8}>
                <Stack alignItems='center' direction='row' spacing={1} width={1}>
                  <Typography color={themeItem.palette.text.label} variant='body2'>
                    Subgenres
                  </Typography>
                </Stack>
                <Stack direction='row' flexWrap='wrap' spacing={1} width={1}>
                  {props.project?.subGenres && props.project.subGenres.length > 0 &&
                props.project.subGenres.map(subGenre =>
                  <Chip
                    key={subGenre}
                    label={subGenre}
                    variant='subtle'
                  />)}
                </Stack>
              </Stack>
            </Grid>

            <Grid item lg={6} md={6} sm={6} xl={6} xs={12}>
              <Stack alignItems='center' direction='row' justifyContent='space-between' spacing={1} width={0.8}>
                <Stack alignItems='center' direction='row' spacing={1} width={1}>
                  <Typography color={themeItem.palette.text.label} variant='body2'>
                    Release Date
                  </Typography>
                </Stack>
                <Stack width={1}>
                  <Typography variant='body2'>
                    {testItem.format('ll')}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Stack>

        <Divider sx={{ width: 1 }} />

        <Stack spacing={1} width={1} >
          <Typography color={themeItem.palette.text.label} variant='body2' >
            Priority track
          </Typography>
          <Typography variant='body2'>
            {props.priorityTitle}
          </Typography>
        </Stack>

        <Stack spacing={1} width={1} >
          <Typography color={themeItem.palette.text.label} variant='body2' >
            Similar artists
          </Typography>
          <Typography variant='body2'>
            {props.similarArtists}
          </Typography>
        </Stack>

        <Stack spacing={1} width={1} >
          <Typography color={themeItem.palette.text.label} variant='body2' >
            Genre
          </Typography>
          <Typography variant='body2'>
            {props.genre}
          </Typography>
        </Stack>

        <Stack spacing={1} width={1} >
          <Typography color={themeItem.palette.text.label} variant='body2' >
            Artist country
          </Typography>
          <Typography variant='body2'>
            {props.country}
          </Typography>
        </Stack>

        <Stack spacing={1} width={1} >
          <Typography color={themeItem.palette.text.label} variant='body2' >
            Fanbase country
          </Typography>
          <Typography variant='body2'>
            {props.fanbaseLocation}
          </Typography>
        </Stack>

        <Stack spacing={1} width={1} >
          <Typography color={themeItem.palette.text.label} variant='body2' >
            Artist details
          </Typography>
          <Typography variant='body2'>
            {props.artist}
          </Typography>
        </Stack>

        <Stack spacing={1} width={1} >
          <Typography color={themeItem.palette.text.label} variant='body2' >
            Marketing details
          </Typography>
          <Typography variant='body2'>
            {props.marketing}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  )
}

export default SubmitPitch
