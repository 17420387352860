import type { ThemeOptions } from '@mui/material/styles'

import MuiAccordionSummary from './components/MuiAccordionSummary'
import MuiAppBar from './components/MuiAppBar'
import MuiAutocomplete from './components/MuiAutocomplete'
import MuiButton from './components/MuiButton'
import MuiButtonGroup from './components/MuiButtonGroup'
import MuiCheckbox from './components/MuiCheckbox'
import MuiChip from './components/MuiChip'
import MuiDrawer from './components/MuiDrawer'
import MuiFab from './components/MuiFab'
import MuiFilledInput from './components/MuiFilledInput'
import MuiFormControlLabel from './components/MuiFormControlLabel'
import MuiFormHelperText from './components/MuiFormHelperText'
import MuiIconButton from './components/MuiIconButton'
import MuiInputAdornment from './components/MuiInputAdornment'
import MuiInputLabel from './components/MuiInputLabel'
import MuiLink from './components/MuiLink'
import MuiPaper from './components/MuiPaper'
import MuiSelect from './components/MuiSelect'
import MuiStepLabel from './components/MuiStepLabel'
import MuiSvgIcon from './components/MuiSvgIcon'
import MuiSwitch from './components/MuiSwitch'
import MuiTab from './components/MuiTab'
import MuiTextField from './components/MuiTextField'
import MuiToolbar from './components/MuiToolbar'
import MuiTypography from './components/MuiTypography'
import MuiDialog from 'src/styles/components/MuiDialog'
import MuiList from 'src/styles/components/MuiList'
import MuiStep from 'src/styles/components/MuiStep'
import MuiStepIcon from 'src/styles/components/MuiStepIconText'
import MuiTable from 'src/styles/components/MuiTable'
import MuiTooltip from 'src/styles/components/MuiTooltip'

const baseThemeComponents: ThemeOptions['components'] = {
  MuiAccordionSummary,
  MuiAppBar,
  MuiAutocomplete,
  MuiButton,
  MuiButtonGroup,
  MuiCheckbox,
  MuiChip,
  MuiDrawer,
  MuiFab,
  MuiFilledInput,
  MuiFormControlLabel,
  MuiFormHelperText,
  MuiInputLabel,
  MuiLink,
  MuiIconButton,
  MuiInputAdornment,
  MuiPaper,
  MuiSelect,
  MuiStep,
  MuiStepLabel,
  MuiSvgIcon,
  MuiSwitch,
  MuiTextField,
  MuiTab,
  MuiToolbar,
  MuiTypography,
  MuiList,
  MuiTable,
  MuiTooltip,
  MuiStepIcon,
  MuiDialog,
}

export default baseThemeComponents
