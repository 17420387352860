/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Card, Checkbox, FormControlLabel, FormGroup, Grid, Stack, TextField, Typography, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'

type Props = {
  handleDowngrade: () => void
  reason: string
  setReason: React.Dispatch<React.SetStateAction<string>>
  setAdditionalReason: React.Dispatch<React.SetStateAction<string[]>>
}

const priceText = 'Subscription fee was too high'
const flexibiltiyText = 'Lack of flexibility in pricing plans'
const hiddenText = 'Hidden fees or unclear charges'
const competitorText = 'Competitor offers better value for the price'
const featuresText = 'Expecting more features for the price'

const CostConfirm = (props: Props) => {
  const themeItem = useTheme()
  const [price, setPrice] = useState(false)
  const [flexibiltiy, setFlexibility] = useState(false)
  const [hidden, setHidden] = useState(false)
  const [competitor, setCompetitor] = useState(false)
  const [features, setFeatures] = useState(false)

  useEffect(() => {
    if (!price) {
      props.setAdditionalReason(old => old.filter(name => name.includes(priceText)))
    } else {
      props.setAdditionalReason(old => [...old, priceText])
    }
  }, [price])

  useEffect(() => {
    if (!flexibiltiy) {
      props.setAdditionalReason(old => old.filter(name => name.includes(flexibiltiyText)))
    } else {
      props.setAdditionalReason(old => [...old, flexibiltiyText])
    }
  }, [flexibiltiy])

  useEffect(() => {
    if (!hidden) {
      props.setAdditionalReason(old => old.filter(name => name.includes(hiddenText)))
    } else {
      props.setAdditionalReason(old => [...old, hiddenText])
    }
  }, [hidden])

  useEffect(() => {
    if (!competitor) {
      props.setAdditionalReason(old => old.filter(name => name.includes(competitorText)))
    } else {
      props.setAdditionalReason(old => [...old, competitorText])
    }
  }, [competitor])

  useEffect(() => {
    if (!features) {
      props.setAdditionalReason(old => old.filter(name => name.includes(featuresText)))
    } else {
      props.setAdditionalReason(old => [...old, featuresText])
    }
  }, [features])

  return (
    <Grid height={1} item width={1}>
      <Card elevation={0} sx={{ backgroundColor: theme => theme.palette.background.input }}>
        <Stack alignItems='flex-start' height={1} justifyContent='flex-start' padding={4} spacing={2} width={1}>
          <Stack>
            <Typography lineHeight={1.2} variant='h3'>
              Tell us more
            </Typography>
            <Typography color={themeItem.palette.text.secondary} variant='body1'>
              Were there specific aspects of our pricing structure that you found challenging?
            </Typography>
          </Stack>

          <FormGroup sx={{ paddingBottom: 2 }}>
            <FormControlLabel
              control={<Checkbox checked={price} onClick={() => setPrice(old => !old)} />}
              label={priceText}
            />
            <FormControlLabel
              control={<Checkbox checked={flexibiltiy} onClick={() => setFlexibility(old => !old)} />}
              label={flexibiltiyText}
            />
            <FormControlLabel
              control={<Checkbox checked={hidden} onClick={() => setHidden(old => !old)} />}
              label={hiddenText}
            />
            <FormControlLabel
              control={<Checkbox checked={competitor} onClick={() => setCompetitor(old => !old)} />}
              label={competitorText}
            />
            <FormControlLabel
              control={<Checkbox checked={features} onClick={() => setFeatures(old => !old)} />}
              label={featuresText}
            />
          </FormGroup>

          <TextField
            fullWidth
            label='Tell us why'
            minRows={4}
            multiline
            onChange={event => props.setReason(event.target.value)}
            placeholder='Tell us more, we might reach out and give
            you an Amazon gift card for more information'
            sx={{
              marginTop: '32px!important',
              '.MuiInputBase-root': {
                backgroundColor: theme => `${theme.palette.background.default}!important`,
              },
            }}
            value={props.reason}
          />
          <Button
            color='error'
            disabled={!price && !flexibiltiy && !hidden && !competitor && !features}
            onClick={props.handleDowngrade}
          >
            Cancel subscription
          </Button>
        </Stack>
      </Card>
    </Grid>
  )
}

export default CostConfirm
