/* eslint-disable no-duplicate-imports */
/* eslint-disable react/no-unstable-nested-components */
import { useTheme } from '@mui/material'
import type { ReactourStep, ReactourStepContentArgs } from 'reactour'
import Tour from 'reactour'

import TourHeader from 'src/components/pages/Tour/TourComponents/TourHeader'
import TourStep from 'src/components/pages/Tour/TourComponents/TourStep'
import { useAuth } from 'src/components/providers/AuthProvider'
import Image1 from 'src/images/Onboarding/Setup/setup-1.webp'

type Props = {
  open: boolean
  closeTour: () => void
  finalEvent: () => void
  loading: boolean
}

const SetupTour = (props: Props) => {
  const theme = useTheme()
  const { currentAccount } = useAuth()

  const steps: ReactourStep[] = [
    {
      content: ({ goTo, step }: ReactourStepContentArgs) =>
        <TourHeader
          description='We built Releese to do one thing - help music industry professionals like you achieve
          their goals. Our tools make the complicated stuff drag-and-drop easy, transparent, and collaborative.'
          goTo={goTo}
          step={step}
          title={`Welcome to Releese, ${currentAccount.firstName}`}
        />
      ,
      position: 'right',
      style: {
        width: 500,
        maxWidth: 500,
        backgroundColor: theme.palette.background.default,
      },
    },
    {
      content: ({ close, goTo, step }: ReactourStepContentArgs) =>
        <TourStep
          close={close}
          description='Releases contain everything you need to release music:
          Recordings, Splits, Artworks, Pre-Save pages, Analytics, Earnings, and a collaborative To-Do list.'
          final
          finalAction={props.finalEvent}
          finalText='Create my first release'
          goTo={goTo}
          item={<img
            alt='Dashboard'
            height='1562px'
            loading='lazy'
            src={Image1}
            style={{
              marginBottom: '-16px',
              width: '100%',
              height: 'auto',
              transform: 'translateZ(0)',
            }}
            width='2083px'
          />}
          loading={props.loading}
          step={step}
          title='Create your first release'
        />
      ,
      position: 'right',
      style: {
        width: 500,
        maxWidth: 500,
        backgroundColor: theme.palette.background.paper,
        padding: 0,
      },
    },
  ]

  return (
    <Tour
      accentColor={theme.palette.primary.main}
      closeWithMask={false}
      disableDotsNavigation
      disableFocusLock
      disableKeyboardNavigation
      isOpen={props.open}
      onRequestClose={props.closeTour}
      rounded={16}
      showButtons={false}
      showCloseButton={false}
      showNavigation={false}
      showNavigationNumber={false}
      showNumber={false}
      steps={steps}
    />
  )
}

export default SetupTour
