/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { faGoogle, faMicrosoft, faYahoo } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import { LoadingButton } from '@mui/lab'
import { Button, Card, Container, darken, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { getApp } from 'firebase/app'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { useState } from 'react'

import { useAuth } from 'src/components/providers/AuthProvider'
import ReleeseLogo from 'src/components/ReleeseLogo'
import { auth, parseError } from 'src/utils/firebase'

const VerifyEmailPage = () => {
  const { currentFirebaseUser } = useAuth()
  const themeItem = useTheme()
  const mobile = useMediaQuery(themeItem.breakpoints.down('md'))
  const functions = getFunctions(getApp())

  const [loading, setLoading] = useState(false)
  const [done, setDone] = useState(false)
  const [failed, setFailed] = useState(false)

  const verifyEmail = async () => {
    setLoading(true)
    const sendVerifycationEmail = httpsCallable(functions, 'verifyEmailFunction')
    await sendVerifycationEmail({ data: { email: currentFirebaseUser.email } })
      .then(() => {
        setDone(true)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
        setFailed(true)
      })
  }

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const handleLogout = () =>
    auth.signOut()
      .then(() => window.location.reload())
      .catch(parseError)

  return (
    <Container
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 1,
        height: '100%',
        width: '100%!important',
        padding: 0,
        margin: 0,
        paddingLeft: '0px!important',
        paddingRight: '0px!important',
        maxWidth: '100%!important',
        backgroundColor: theme => theme.palette.background.input,
      }}
    >
      <Stack
        alignItems='center'
        direction='row'
        height={1}
        justifyContent='center'
        width={1}
      >
        <Card
          elevation={0}
          sx={{
            padding: 2,
            textAlign: 'center',
            width: 1,
            marginY: 2,
            maxWidth: 'lg',
            background: 'transparent',
          }}
        >
          <Stack alignItems='flex-end' direction='row' justifyContent='center' paddingY={4} spacing={1} width={1}>
            <ReleeseLogo full />
          </Stack>
          <Stack spacing={2} width={1}>
            <Typography lineHeight={1.2} textAlign='center' variant='h1'>
              Please verify your email
            </Typography>
            <Stack alignItems='center' width={1}>
              <Typography textAlign='center' variant='body1'>
                You&apos;ve entered
                {' '}
                <span style={{ color: themeItem.palette.text.link }}>
                  {currentFirebaseUser?.email}
                </span>
                {' '}
                as the email address for your account.
              </Typography>
              <Typography color={themeItem.palette.text.secondary} textAlign='center' variant='body2'>
                Once you verify your email address, you can get started in Releese.
              </Typography>
            </Stack>
            <Stack
              alignItems='center'
              direction={mobile ? 'column' : 'row'}
              justifyContent='center'
              spacing={2}
              width={1}
            >
              <Button
                color='inherit'
                href='https://gmail.com/'
                startIcon={<FontAwesomeIcon
                  icon={faGoogle}
                />}
                sx={{
                  color: 'white',
                  backgroundColor: '#EA4335',
                  '& .MuiButton-startIcon': {
                    display: 'block!important',
                  },
                  '& svg': {
                    display: 'block!important',
                  },
                  ':hover': {
                    color: 'white',
                    backgroundColor: darken('#EA4335', 0.2),
                  },
                }}
              >
                Open Gmail
              </Button>
              <Button
                color='inherit'
                href='https://outlook.live.com/'
                startIcon={<FontAwesomeIcon
                  icon={faMicrosoft}
                />}
                sx={{
                  backgroundColor: '#0072C6',
                  color: 'white',
                  '& .MuiButton-startIcon': {
                    display: 'block!important',
                  },
                  '& svg': {
                    display: 'block!important',
                  },
                  ':hover': {
                    color: 'white',
                    backgroundColor: darken('#0072C6', 0.2),
                  },
                }}
              >
                Open Outlook
              </Button>
              <Button
                color='inherit'
                href='https://mail.yahoo.com/'
                startIcon={<FontAwesomeIcon
                  icon={faYahoo}
                />}
                sx={{
                  color: 'white',
                  backgroundColor: '#430297',
                  '& .MuiButton-startIcon': {
                    display: 'block!important',
                  },
                  '& svg': {
                    display: 'block!important',
                  },
                  ':hover': {
                    color: 'white',
                    backgroundColor: darken('#430297', 0.2),
                  },
                }}
              >
                Open Yahoo!
              </Button>
            </Stack>
            <Stack alignItems='center' direction='row' justifyContent='center' spacing={1} width={1}>
              <Typography textAlign='center' variant='body1'>
                Can&apos;t find the email?
              </Typography>
              <LoadingButton
                color={done
                  ? 'success'
                  : failed
                    ? 'error'
                    : 'secondary'}
                loading={loading}
                onClick={() => void verifyEmail()}
                size='small'
                startIcon={done
                  ? <CheckRoundedIcon />
                  : undefined}
                variant='text'
              >
                {done
                  ? 'Email sent!'
                  : failed
                    ? 'Failed to send'
                    : 'Resend email.'}
              </LoadingButton>
            </Stack>
            <Stack alignItems='center' direction='row' justifyContent='center' spacing={1} width={1}>
              <Button
                color='inherit'
                onClick={handleLogout}
                size='small'
                sx={{
                  width: 'fit-content',
                  color: theme => theme.palette.action.disabled,
                }}
                variant='text'
              >
                Log Out
              </Button>
            </Stack>
          </Stack>
        </Card>
      </Stack>
    </Container>
  )
}

export default VerifyEmailPage
