/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { Stack, Typography, useTheme } from '@mui/material'
import countries from 'i18n-iso-countries'
import data from 'i18n-iso-countries/langs/en.json'
import { useRef } from 'react'
import WorldMap from 'react-svg-worldmap'

import { useContainerDimensions } from 'src/components/ContainerDimensions'
import type { MarketingAnalyticsString } from 'src/models/Marketing'

type Props = {
  countryClicks: MarketingAnalyticsString[]
}

const ViewLocationsMap = (props: Props) => {
  const theme = useTheme()
  countries.registerLocale(data)
  const clicks = props.countryClicks.map(({ _id, count }) =>
    ({ country:
      _id &&
      _id.length > 0 &&
      countries.getAlpha2Code(_id, 'en') &&
      countries.getAlpha2Code(_id, 'en') !== undefined
        ? countries.getAlpha2Code(_id, 'en')
        : '', value: count }))

  const componentRef = useRef<HTMLDivElement>(null)
  const { width } = useContainerDimensions(componentRef)

  return (
    <>
      <Typography variant='h3'>
        Locations
      </Typography>
      <Stack alignItems='center' ref={componentRef} width={1}>
        <WorldMap
          backgroundColor='transparent'
          borderColor={theme.palette.divider}
          color={theme.palette.primary.main}
          data={clicks}
          size={width}
          strokeOpacity={1}
          valueSuffix='Clicks'
        />
      </Stack>
    </>
  )
}

export default ViewLocationsMap
