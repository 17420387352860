import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded'
import { Card, CardActionArea, Stack, Typography } from '@mui/material'

type Props = {
  activeSelection: number
  handleSelection: (value: number) => () => void
}

const MissingFunctionality = (props: Props) => {
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (props.activeSelection) {
    case 3:
      return <Card
        elevation={0}
        sx={{ borderColor: theme => `${theme.palette.warning.main}!important`, height: 1 }}
        variant='outlined'
      >
        <CardActionArea onClick={props.handleSelection(3)} sx={{ height: 1 }} >
          <Stack alignItems='center' height={1} padding={3} spacing={1}>
            <ConstructionRoundedIcon
              sx={{
                fontSize: 64,
                color: theme => theme.palette.warning.main,
              }}
            />
            <Typography textAlign='center' variant='h6'>
              Missing features
            </Typography>
          </Stack>
        </CardActionArea>
      </Card>
    default:
      return <Card elevation={0} sx={{ height: 1 }} variant='outlined'>
        <CardActionArea onClick={props.handleSelection(3)} sx={{ height: 1 }} >
          <Stack alignItems='center' height={1} padding={3} spacing={1}>
            <ConstructionRoundedIcon
              sx={{
                fontSize: 64,
                color: theme => theme.palette.warning.main,
              }}
            />
            <Typography textAlign='center' variant='h6'>
              Missing features
            </Typography>
          </Stack>
        </CardActionArea>
      </Card>
  }
}

export default MissingFunctionality
