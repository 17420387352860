/* eslint-disable max-lines */
/* eslint-disable sonarjs/no-small-switch */
/* eslint-disable no-case-declarations */
import AppleIcon from '@mui/icons-material/Apple'
import FacebookIcon from '@mui/icons-material/FacebookRounded'
import GoogleIcon from '@mui/icons-material/Google'
import { LoadingButton } from '@mui/lab'
import { Button, Checkbox, darken, Divider, FormControl, FormControlLabel, FormHelperText, lighten, Link, Stack, TextField, Typography, useTheme } from '@mui/material'
import type { FirebaseError } from 'firebase/app'
import { getApp } from 'firebase/app'
import type { MultiFactorError, MultiFactorResolver } from 'firebase/auth'
import { createUserWithEmailAndPassword, FacebookAuthProvider, getMultiFactorResolver, GoogleAuthProvider, OAuthProvider, PhoneAuthProvider, PhoneMultiFactorGenerator, RecaptchaVerifier, signInWithPopup } from 'firebase/auth'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { useEffect, useRef, useState } from 'react'
import OtpInput from 'react-otp-input'
import { useLocation, useNavigate } from 'react-router-dom'

import { useAuth } from 'src/components/providers/AuthProvider'
import { RouterLink } from 'src/components/SafeRouterLink'
import firebaseErrorMessages from 'src/data/firebaseAuthErrorCodes'
import { auth, parseError } from 'src/utils/firebase'

const OrDivider = <Stack alignItems='center' direction='row' justifyContent='space-between' spacing={1} width={1}>
  <Divider sx={{ flexGrow: 1 }} />
  <Typography variant='overline'>Or</Typography>
  <Divider sx={{ flexGrow: 1 }} />
</Stack>

const SignUp = () => {
  const themeHook = useTheme()
  const { search } = useLocation()
  const functions = getFunctions(getApp())
  const { refreshCurrentAccount } = useAuth()
  const [loginState, setLoginState] = useState('login')
  const [loadingMfa, setLoadingMFA] = useState(false)
  const [acceptTerms, setAcceptTerms]  = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState<string>()
  const [errorMessageTerms, setErrorMessageTerms] = useState<string>()
  const [loading, setLoading] = useState(false)
  const [verificationCode, setVerificationCode] = useState('')
  const [verificationId, setVerificationId] = useState('')
  const [resolverLogin, setResolverLogin] = useState<MultiFactorResolver>()
  const navigate = useNavigate()
  const emailItem = new URLSearchParams(search).get('email')

  const ref = useRef<HTMLDivElement | null>(null)
  const [captchaTest, setCaptchaTest] = useState<RecaptchaVerifier>()
  const [rendered, setRendered] = useState(false)

  useEffect(() => {
    if (ref.current && !captchaTest) {
      setCaptchaTest(new RecaptchaVerifier(ref.current, {
        size: 'invisible',
      }, auth))
    }
  }, [ref, captchaTest])

  useEffect(() => {
    if (captchaTest && !rendered) {
      void captchaTest.render()
        .then(() => {
          setRendered(true)
        })
    }
  }, [captchaTest, rendered])

  useEffect(() => {
    if (emailItem) {
      setEmail(emailItem)
    }
  }, [emailItem])

  const handleMultiError = (error: MultiFactorError) => {
    switch (error.code) {
      case 'auth/multi-factor-auth-required':
        const resolver = getMultiFactorResolver(auth, error)
        if (resolver.hints[0].factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
          const recaptchaVerifier = new RecaptchaVerifier(
            'recaptcha',
            {
              'size': 'invisible',
            },
            auth,
          )
          const phoneInfoOptions = {
            multiFactorHint: resolver.hints[0],
            session: resolver.session,
          }
          const phoneAuthProvider = new PhoneAuthProvider(auth)
          setResolverLogin(resolver)
          return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
            .then(setVerificationId)
            .then(() => setLoginState('mfa'))
        } else {
          setLoadingMFA(false)
          setErrorMessage(firebaseErrorMessages.find(errorCode => errorCode.code === error.code)?.message)
        }
        break
      default:
        setLoadingMFA(false)
        setErrorMessage(firebaseErrorMessages.find(errorCode => errorCode.code === error.code)?.message)
    }
  }

  const facebookLogin = () => {
    const facebookProvider = new FacebookAuthProvider()
    facebookProvider.addScope('email')
    void signInWithPopup(auth, facebookProvider)
      .then(refreshCurrentAccount)
      .catch((error: MultiFactorError) => {
        void handleMultiError(error)
      })
  }

  const appleLogin = () => {
    const appleProvider = new OAuthProvider('apple.com')
    appleProvider.addScope('email')
    void signInWithPopup(auth, appleProvider)
      .then(refreshCurrentAccount)
      .catch((error: MultiFactorError) => {
        void handleMultiError(error)
      })
  }

  const googleLogin = () => {
    const googleProvider = new GoogleAuthProvider()
    void signInWithPopup(auth, googleProvider)
      .then(refreshCurrentAccount)
      .catch((error: MultiFactorError) => {
        void handleMultiError(error)
      })
  }

  const handleCheckboxTerms = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAcceptTerms(event.target.checked)
  }

  const handleMfa = () => {
    setErrorMessage('')
    setLoadingMFA(true)
    const cred = PhoneAuthProvider.credential(verificationId, verificationCode)
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred)
    if (resolverLogin) {
      resolverLogin.resolveSignIn(multiFactorAssertion)
        .then(refreshCurrentAccount)
        .catch((error: MultiFactorError) => {
          void handleMultiError(error)
          setLoadingMFA(false)
        })
    }
  }

  const handleSubmit = async () => {
    if (captchaTest) {
      const valueCaptcha = await captchaTest.verify()
        .catch(() => {
          setLoading(false)
          setErrorMessage('Please complete the CAPTCHA to log in')
          return ''
        })
      if (valueCaptcha.length > 0) {
        if (acceptTerms) {
          setErrorMessage('')
          setErrorMessageTerms('')
          setLoading(true)
          await createUserWithEmailAndPassword(auth, email, password)
            .then(async () => {
              const sendVerifycationEmail = httpsCallable(functions, 'verifyEmailFunction')
              await sendVerifycationEmail({ data: { email } })
                .then(() => navigate('/', { replace: true }))
                .catch(() => null)
            })
            .catch((error: FirebaseError) => setErrorMessage(parseError(error)))
            .finally(() => setLoading(false))
        } else {
          setErrorMessage('')
          setErrorMessageTerms('Please accept the terms and conditions and our privacy policy.')
        }
      } else {
        setLoading(false)
        setErrorMessage('Please complete the CAPTCHA to log in')
      }
    }
  }

  const handleEnter = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      void handleSubmit()
    }
  }

  return <>
    <Stack alignContent='center' alignItems='flext-start' width={1}>
      <Typography textAlign='center' variant='h3'>
        Get Started with Releese
      </Typography>
      <Stack height={0} id='recaptcha' marginTop='0px!important' />
    </Stack>
    {(() => {
      switch (loginState) {
        case 'mfa':
          return <Stack alignItems='center' paddingBottom={2} spacing={2}>
            <Typography variant='body2'>
              Please enter the code that was sent your phone number, standard rates apply.
            </Typography>
            <form onSubmit={handleMfa}>
              <Stack alignItems='center' paddingBottom={2} spacing={3}>
                <OtpInput
                  focusStyle={{
                    borderColor: themeHook.palette.primary.main,
                    borderSize: '1px',
                    borderStyle: 'solid',
                    outline: 'none',
                  }}
                  hasErrored={!!errorMessage}
                  inputStyle={{
                    borderColor: themeHook.palette.border,
                    borderSize: '1px',
                    borderStyle: 'solid',
                    borderRadius: '8px',
                    width: '54px',
                    height: '54px',
                    fontSize: '16px',
                    color: '#000',
                    fontWeight: '400',
                  }}
                  isInputNum
                  numInputs={6}
                  onChange={(event: string) => setVerificationCode(event)}
                  separator={<span style={{ width: '8px' }} />}
                  shouldAutoFocus
                  value={verificationCode}
                />
                <LoadingButton
                  id='handle-login'
                  loading={loadingMfa}
                  onClick={handleMfa}
                  onSubmit={handleMfa}
                  type='submit'
                  variant='contained'
                >
                  Login
                </LoadingButton>
                {errorMessage &&
                <Typography color='error' variant='helperText'>{errorMessage}</Typography>}
              </Stack>
            </form>
          </Stack>
        default:
          return <Stack maxWidth={600} spacing={2} width={1}>
            <Stack alignItems='center' direction='column' spacing={1} width={1}>
              <Button
                fullWidth
                onClick={facebookLogin}
                startIcon={<FacebookIcon />}
                sx={{
                  backgroundColor: theme => theme.palette.facebook,
                  ':hover': {
                    backgroundColor: theme => darken(theme.palette.facebook, 0.2),
                  },
                }}
              >
                Continue with Facebook
              </Button>
              <Button
                fullWidth
                onClick={appleLogin}
                startIcon={<AppleIcon />}
                sx={{
                  backgroundColor: theme => theme.palette.apple,
                  ':hover': {
                    backgroundColor: theme => lighten(theme.palette.apple, 0.2),
                  },
                }}
              >
                Sign in with Apple
              </Button>
              <Button
                fullWidth
                onClick={googleLogin}
                startIcon={<GoogleIcon />}
                sx={{
                  backgroundColor: theme => theme.palette.google,
                  ':hover': {
                    backgroundColor: theme => darken(theme.palette.google, 0.2),
                  },
                }}
              >
                Continue with Google
              </Button>
            </Stack>
            {OrDivider}
            <Stack paddingBottom={1} paddingTop={1} spacing={6}>
              <TextField
                disabled={!!emailItem}
                error={!!errorMessage}
                label='Email'
                onChange={event => setEmail(event.target.value)}
                onKeyPress={handleEnter}
                placeholder='example@email.com'
                required
                type='email'
                value={email}
              />
              <TextField
                error={!!errorMessage}
                label='Password'
                onChange={event => setPassword(event.target.value)}
                onKeyPress={handleEnter}
                placeholder='Password'
                required
                type='password'
                value={password}
              />
              <TextField
                error={!!errorMessage}
                helperText={errorMessage}
                label='Confirm password'
                onChange={event => setConfirmPassword(event.target.value)}
                onKeyPress={handleEnter}
                placeholder='Password'
                required
                type='password'
                value={confirmPassword}
              />
            </Stack>
            <FormControl
              error={!!errorMessageTerms}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={acceptTerms}
                    onChange={handleCheckboxTerms}
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                  />
                }
                label={<Typography variant='helperText'>
                  {'I agree to the '}
                  <Link href='https://releese.io/legal/terms-and-conditions' target='_blank'>
                    Terms and conditions
                  </Link>
                  {' and the '}
                  <Link href='https://releese.io/legal/privacy-policy' target='_blank'>
                    Privacy policy.
                  </Link>
                </Typography>}
              />
              {errorMessageTerms &&
              <FormHelperText>{errorMessageTerms}</FormHelperText>}
            </FormControl>
            <LoadingButton
              disabled={password.length === 0  ||
              !acceptTerms ||
              confirmPassword.length === 0 ||
              email.length === 0 ||
              !captchaTest}
              loading={loading}
              onClick={() => void handleSubmit()}
              variant='contained'
            >
              Sign Up
            </LoadingButton>
            <Typography textAlign='left' variant='body2'>
              {'Already a member? '}
              <Link component={RouterLink} to={`/account/login${search}`}>
                Sign in
              </Link>
            </Typography>
          </Stack>
      }
    })()}
    <Stack ref={ref} />
  </>
}

export default SignUp
