/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Card, Checkbox, FormControlLabel, FormGroup, Grid, Stack, TextField, Typography, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'

type Props = {
  handleDowngrade: () => void
  reason: string
  setReason: React.Dispatch<React.SetStateAction<string>>
  setAdditionalReason: React.Dispatch<React.SetStateAction<string[]>>
}

const storeText = 'Missing a platform to distribute to'
const lofiText = 'Could not distribute nature sounds music'
const shortText = 'Could not distribute songs shorter than 1 minute'
const rightsText = 'Could not setup publishing or neighboring rights'
const presavesText = 'Could not get pre-save subscribers'
const aiText = 'Missing AI features'
const integrationsText = 'Missing integration'

const MissingFunctionalityConfirm = (props: Props) => {
  const themeItem = useTheme()
  const [store, setStore] = useState(false)
  const [lofi, setLofi] = useState(false)
  const [short, setShort] = useState(false)
  const [rights, setRights] = useState(false)
  const [presaves, setPresaves] = useState(false)
  const [ai, setAi] = useState(false)
  const [integrations, setIntegrations] = useState(false)

  useEffect(() => {
    if (!store) {
      props.setAdditionalReason(old => old.filter(name => name.includes(storeText)))
    } else {
      props.setAdditionalReason(old => [...old, storeText])
    }
  }, [store])

  useEffect(() => {
    if (!lofi) {
      props.setAdditionalReason(old => old.filter(name => name.includes(lofiText)))
    } else {
      props.setAdditionalReason(old => [...old, lofiText])
    }
  }, [lofi])

  useEffect(() => {
    if (!short) {
      props.setAdditionalReason(old => old.filter(name => name.includes(shortText)))
    } else {
      props.setAdditionalReason(old => [...old, shortText])
    }
  }, [short])

  useEffect(() => {
    if (!rights) {
      props.setAdditionalReason(old => old.filter(name => name.includes(rightsText)))
    } else {
      props.setAdditionalReason(old => [...old, rightsText])
    }
  }, [rights])

  useEffect(() => {
    if (!presaves) {
      props.setAdditionalReason(old => old.filter(name => name.includes(presavesText)))
    } else {
      props.setAdditionalReason(old => [...old, presavesText])
    }
  }, [presaves])

  useEffect(() => {
    if (!ai) {
      props.setAdditionalReason(old => old.filter(name => name.includes(aiText)))
    } else {
      props.setAdditionalReason(old => [...old, aiText])
    }
  }, [ai])

  useEffect(() => {
    if (!integrations) {
      props.setAdditionalReason(old => old.filter(name => name.includes(integrationsText)))
    } else {
      props.setAdditionalReason(old => [...old, integrationsText])
    }
  }, [integrations])

  return (
    <Grid height={1} item width={1}>
      <Card elevation={0} sx={{ backgroundColor: theme => theme.palette.background.input }}>
        <Stack alignItems='flex-start' height={1} justifyContent='flex-start' padding={4} spacing={2} width={1}>
          <Stack>
            <Typography lineHeight={1.2} variant='h3'>
              Tell us more
            </Typography>
            <Typography color={themeItem.palette.text.secondary} variant='body1'>
              What feature was missing?
            </Typography>
          </Stack>

          <FormGroup sx={{ paddingBottom: 2 }}>
            <FormControlLabel
              control={<Checkbox checked={store} onClick={() => setStore(old => !old)} />}
              label={storeText}
            />
            <FormControlLabel
              control={<Checkbox checked={lofi} onClick={() => setLofi(old => !old)} />}
              label={lofiText}
            />
            <FormControlLabel
              control={<Checkbox checked={short} onClick={() => setShort(old => !old)} />}
              label={shortText}
            />
            <FormControlLabel
              control={<Checkbox checked={rights} onClick={() => setRights(old => !old)} />}
              label={rightsText}
            />
            <FormControlLabel
              control={<Checkbox checked={presaves} onClick={() => setPresaves(old => !old)} />}
              label={presavesText}
            />
            <FormControlLabel
              control={<Checkbox checked={ai} onClick={() => setAi(old => !old)} />}
              label={aiText}
            />
            <FormControlLabel
              control={<Checkbox checked={integrations} onClick={() => setIntegrations(old => !old)} />}
              label={integrationsText}
            />
          </FormGroup>

          <TextField
            fullWidth
            label='Tell us why'
            minRows={4}
            multiline
            onChange={event => props.setReason(event.target.value)}
            placeholder='Tell us more, we might reach out and give
            you an Amazon gift card for more information'
            sx={{
              marginTop: '32px!important',
              '.MuiInputBase-root': {
                backgroundColor: theme => `${theme.palette.background.default}!important`,
              },
            }}
            value={props.reason}
          />
          <Button
            color='error'
            disabled={!store && !lofi && !short && !rights && !presaves && !ai && !integrations}
            onClick={props.handleDowngrade}
          >
            Cancel subscription
          </Button>
        </Stack>
      </Card>
    </Grid>
  )
}

export default MissingFunctionalityConfirm
