import { CssBaseline, ThemeProvider } from '@mui/material'
import { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import store from 'storejs'

import LoginRoute from './pages/Login/LoginRoute'
import Menu from './pages/Menu/Menu'
import LoggedInRouter from './providers/LoggedInRouter'
import { getSanctions } from 'src/api/organisation'
import InitialSetup from 'src/components/pages/Account/InitialSetup/InitialSetup'
import ManageUsers from 'src/components/pages/Login/ManageUsers'
import VerifyEmailPage from 'src/components/pages/Login/VerifyEmailPage'
import { NotAvailable } from 'src/components/pages/ReleeseNotAvailable'
import ShareProject from 'src/components/pages/Share/Share'
import ShareFile from 'src/components/pages/ShareFile/ShareFile'
import ShareFolder from 'src/components/pages/ShareFolder/ShareFolder'
import { UnderConstruction } from 'src/components/pages/UnderConstruction'
import Unsubscribe from 'src/components/pages/Unsubscribe/Unsubscribe'
import { AuthProvider } from 'src/components/providers/AuthProvider'
import ErrorBoundary from 'src/components/providers/ErrorBoundary'
import { FilesProvider } from 'src/components/providers/FilesUploadProvider'
import { GtmProvider } from 'src/components/providers/GtmProvider'
import LoggedOutRouter from 'src/components/providers/LoggedOutRouter'
import { MusicProvider } from 'src/components/providers/MusicProvider'
import PrivateRoute from 'src/components/providers/PrivateRouter'
import darkTheme from 'src/styles/dark.theme'
import lightTheme from 'src/styles/light.theme'

export type themeSelector = 'dark' | 'light'

const App = () => {
  const changeTheme = (theme: themeSelector) => {
    if (theme === 'light') {
      setCurrentTheme(lightTheme)
      store.set('theme', 'light')
      setCurrentThemeString('light')
    } else {
      setCurrentTheme(darkTheme)
      store.set('theme', 'dark')
      setCurrentThemeString('dark')
    }
  }

  const [location, setLocation] = useState('')

  const themeStorage = store.get('theme') as string | undefined
  const [currentThemeString, setCurrentThemeString] = useState(themeStorage !== undefined ? themeStorage : 'dark')
  const [currentTheme, setCurrentTheme] = useState(currentThemeString === 'light' ? lightTheme : darkTheme)

  useEffect(() => {
    void getSanctions()
      .then(result => {
        setLocation(result)
      })
  }, [])

  return (
    <ThemeProvider theme={currentTheme}>
      <ErrorBoundary>
        <CssBaseline />
        <AuthProvider>
          <GtmProvider>
            <MusicProvider>
              <FilesProvider>
                <Routes>
                  {(
                    location === 'BY' ||  // Belarus
                  location === 'RU' ||  // Russia
                  location === 'MM' ||  // Myanmar
                  location === 'KP' ||  // North Korea
                  location === 'IR' ||  // Iran
                  location === 'SD' ||  // Sudan
                  location === 'SO' ||  // Somalia
                  location === 'SY' ||  // Syria
                  location === 'LY'     // Lybia
                  ) &&
                  <Route element={<NotAvailable />} path='/*' />}
                  {process.env.REACT_APP_IN_CONSTRUCTION === 'true' &&
                  <Route element={<UnderConstruction />} path='/*' />}
                  <Route
                    element={<LoggedInRouter
                      elementItem={<LoginRoute />}
                      path='/account/*'
                    />}
                    path='/account/*'
                  />

                  <Route
                    element={<LoggedOutRouter
                      elementItem={<VerifyEmailPage />}
                      path='/verify'
                    />}
                    path='/verify'
                  />
                  <Route
                    element={<ManageUsers />}
                    path='/manageuser'
                  />
                  <Route
                    element={<Unsubscribe />}
                    path='/unsubscribe/:orgId'
                  />
                  <Route
                    element={<ShareProject />}
                    path='/share/:id'
                  />
                  <Route
                    element={<ShareFile />}
                    path='/share-file/:id/:hash'
                  />
                  <Route
                    element={<ShareFolder />}
                    path='/share-folder/:type/:id/:hash'
                  />
                  <Route
                    element={<PrivateRoute
                      elementItem={<InitialSetup />}
                      path='/InitialSetup/*'
                    />}
                    path='/InitialSetup/*'
                  />
                  <Route
                    element={<PrivateRoute
                      elementItem={<Menu changeTheme={changeTheme} currentTheme={currentThemeString} />}
                      path='/*'
                    />}
                    path='/*'
                  />
                </Routes>
              </FilesProvider>
            </MusicProvider>
          </GtmProvider>
        </AuthProvider>
      </ErrorBoundary>
    </ThemeProvider>
  )
}
export default App
