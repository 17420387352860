import PublishRoundedIcon from '@mui/icons-material/PublishRounded'
import { Backdrop, Button, Card, Stack, Typography } from '@mui/material'
import { useState } from 'react'

import DistributeModal from 'src/components/pages/Projects/DistributeModal/DistributeModal'
import DistributeModalFree from 'src/components/pages/Projects/DistributeModal/DistributeModalFree'
import DistributionBubbles from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/DistributionTab/DistributionBubbles'
import { useAuth } from 'src/components/providers/AuthProvider'
import { Permissions } from 'src/models/Organisation'
import type Project from 'src/models/Project'

type Props = {
  project: Project
  updateRecordingsSubtle: () => Promise<void>
  setProject: React.Dispatch<React.SetStateAction<Project | undefined>>
}

const DistributeProject = (props: Props) => {
  const { currentOrganisation, currentAccountPermissions } = useAuth()
  const [distributeModalOpen, setDistributeModalOpen] = useState(false)
  return (
    <>
      {distributeModalOpen &&
          currentOrganisation?.membershipTier === 'FREE' &&
          currentAccountPermissions?.includes(Permissions.DISTRIBUTE_RELEASES) &&
          <DistributeModalFree
            close={() => setDistributeModalOpen(false)}
            open={distributeModalOpen}
          />}
      {distributeModalOpen &&
          currentOrganisation?.membershipTier !== 'FREE' &&
          currentAccountPermissions?.includes(Permissions.DISTRIBUTE_RELEASES) &&
          <DistributeModal
            close={() => setDistributeModalOpen(false)}
            open={distributeModalOpen}
            project={props.project}
            setProject={props.setProject}
            updateRecordingsSubtle={props.updateRecordingsSubtle}
          />}
      <Stack
        direction='column'
        height={1}
        overflow='hidden'
        position='relative'
        width={1}
      >
        <Stack height={1} position='absolute' sx={{ bgcolor: theme => theme.palette.background.input }} width={1}>
          <DistributionBubbles />
        </Stack>
        <Backdrop
          open
          sx={{
            position: 'absolute',
            zIndex: theme => theme.zIndex.drawer - 2,
          }}
        >
          <Stack alignItems='center' height={1} justifyContent='center' width={1}>
            <Stack alignItems='center' maxWidth='sm' padding={2} width={1}>
              <Card sx={{ marginTop: -8 }}>
                <Stack alignItems='center' padding={2} spacing={2} width={1}>
                  <PublishRoundedIcon
                    sx={{
                      color: theme => theme.palette.primary.main,
                      fontSize: 64,
                    }}
                  />
                  <Stack alignItems='center' width={1}>
                    <Typography textAlign='center' variant='h3'>
                      Get Your Music Heard
                    </Typography>
                    <Typography textAlign='center' variant='body1'>
                      Leverage the power of Releese and reach new audiences worldwide
                    </Typography>
                  </Stack>
                  <Button
                    color='primary'
                    onClick={() => setDistributeModalOpen(true)}
                  >
                    Distribute Your Release
                  </Button>
                </Stack>
              </Card>
            </Stack>
          </Stack>
        </Backdrop>
      </Stack>
    </>
  )
}

export default DistributeProject
