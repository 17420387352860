/* eslint-disable no-useless-escape */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable complexity */
import { Autocomplete, Checkbox, FormControlLabel, FormGroup, Grid, TextField } from '@mui/material'
import dayjs from 'dayjs'
import { useState } from 'react'

import type { EditRecordingFormProps } from 'src/components/pages/Projects/EditProject/EditProjectTabs/RecordingsTab/RecordingsListItem'
import { languageData } from 'src/data/languagesData'

const RecordingContentTab = (props: EditRecordingFormProps) => {
  const [recordingFields, setRecordingFields] = useState(props.recording)

  const duration = new Date(0).setSeconds(props.recording.duration)

  const save = async () => {
    await props.onSave(recordingFields)
  }

  const updateString = (field: 'language' | 'lyrics', value: string) => {
    const recording = Object.assign({}, recordingFields)
    recording[field] = value
    setRecordingFields(recording)
    props.saveProgress(recording)
  }

  const updateBoolean = (field: 'containsSamples' | 'isExplicit' | 'isInstrumental', value: boolean) => {
    const recording = Object.assign({}, recordingFields)
    recording[field] = value
    setRecordingFields(recording)
    props.saveProgress(recording)
    void props.onSave(recording)
  }

  const updateTags = (value: string[]) => {
    const recording = Object.assign({}, recordingFields)
    recording.tags = value
    setRecordingFields(recording)
    props.saveProgress(recording)
    void props.onSave(recording)
  }

  const updateBPM = (value: number) => {
    const recording = Object.assign({}, recordingFields)
    recording.bpm = value
    setRecordingFields(recording)
    props.saveProgress(recording)
  }

  const checkLanguage = (!recordingFields.language || recordingFields.language.length === 0) && props.distributing

  return (
    <form
      onSubmit={event => {
        event.preventDefault()
        void save()
      }}
    >
      <Grid
        container
        spacing={3}
      >

        <Grid item lg={3} md={3} sm={6} xl={3} xs={6}>
          <TextField
            autoComplete='new-password'
            disabled={props.project?.distributed}
            fullWidth
            inputProps={{ inputMode: 'numeric', pattern: '^(19|20)\d{2}$' }}
            label='BPM'
            onBlur={() => void save()}
            onChange={event => updateBPM(Number(event.target.value))}
            type='number'
            value={recordingFields.bpm}
          />
        </Grid>
        <Grid item lg={3} md={3} sm={6} xl={3} xs={6}>
          <TextField
            autoComplete='new-password'
            disabled={props.project?.distributed}
            fullWidth
            label='Duration'
            onChange={event => event}
            value={dayjs(duration).format('mm:ss')}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
          <Autocomplete
            disabled={props.project?.distributed}
            freeSolo
            multiple
            onChange={(event, value) => updateTags(value)}
            options={['']}
            renderInput={params =>
              <TextField
                {...params}
                autoComplete='new-password'
                fullWidth
                label='Recording Tags'
                placeholder='Tags'
              />}
            value={recordingFields.tags}
          />
        </Grid>
        <Grid item xs={12}>
          <FormGroup>
            <FormControlLabel
              aria-label='Is Explicit?'
              checked={recordingFields.isExplicit}
              control={<Checkbox checked={recordingFields.isExplicit} disabled={props.project?.distributed} />}
              disabled={props.project?.distributed}
              label='Is Explicit?'
              onChange={() => updateBoolean('isExplicit', !recordingFields.isExplicit)}
            />
            <FormControlLabel
              aria-label='Is Instrument?'
              checked={recordingFields.isInstrumental}
              control={<Checkbox checked={recordingFields.isInstrumental} disabled={props.project?.distributed} />}
              disabled={props.project?.distributed}
              label='Is Instrumental?'
              onChange={() => updateBoolean('isInstrumental', !recordingFields.isInstrumental)}
            />
            <FormControlLabel
              aria-label='Contains Samples?'
              checked={recordingFields.containsSamples}
              control={<Checkbox checked={recordingFields.containsSamples} disabled={props.project?.distributed} />}
              disabled={props.project?.distributed}
              label='Contains Samples?'
              onChange={() => updateBoolean('containsSamples', !recordingFields.containsSamples)}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            disabled={props.project?.distributed}
            getOptionLabel={option => option.language}
            onChange={(event, value) => updateString('language', value?.code ?? '')}
            options={languageData}
            renderInput={params =>
              <TextField
                {...params}
                autoComplete='new-password'
                error={checkLanguage}
                fullWidth
                label='Language'
                onBlur={() => void save()}
                placeholder='Language'
              />}
            value={languageData.find(item => item.code === recordingFields.language)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            autoComplete='new-password'
            disabled={props.project?.distributed}
            fullWidth
            label='Lyrics'
            minRows={9}
            multiline
            onBlur={() => void save()}
            onChange={event => updateString('lyrics', event.target.value)}
            placeholder='Lyrics'
            value={recordingFields.lyrics}
          />
        </Grid>
      </Grid>
    </form>)
}

export default RecordingContentTab
