/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable max-lines */
/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable complexity */
import AddPhotoAlternateRoundedIcon from '@mui/icons-material/AddPhotoAlternateRounded'
import CampaignRoundedIcon from '@mui/icons-material/CampaignRounded'
import DataObjectRoundedIcon from '@mui/icons-material/DataObjectRounded'
import ImageRoundedIcon from '@mui/icons-material/ImageRounded'
import InfoRoundedIcon from '@mui/icons-material/InfoRounded'
import LockRoundedIcon from '@mui/icons-material/LockRounded'
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import PublishRoundedIcon from '@mui/icons-material/PublishRounded'
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded'
import { Grid, LinearProgress, Stack, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import StatusModalDistribution from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/DetailsTab/StatusModal'
import ProjectProgressItem from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/DetailsTab/TodoItemProject'
import SocialAssetModal from 'src/components/pages/SocialAssets/SocialAssetModal'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { Distribution } from 'src/models/Distribution'
import type Project from 'src/models/Project'
import type Recording from 'src/models/Recording'

type Props = {
  project?: Project
  recordings: Recording[]
  setCurrentTab: React.Dispatch<React.SetStateAction<string>>
  pitch: () => void
  distribute: () => void
  distribution?: Distribution
}

const normalise = (value: number) => value * 100 / 4
const normaliseDistribute = (value: number) => value * 100 / 2

const ProjectProgress = (props: Props) => {
  const navigate = useNavigate()
  const { currentOrganisation } = useAuth()
  const themeItem = useTheme()
  const mobile = useMediaQuery(themeItem.breakpoints.down('md'))

  const [open, setOpen] = useState(false)
  const [shareModal, setShareModal] = useState(false)

  const hasRecordings = props.recordings.length > 0
  const splitsLocked = props.recordings.length > 0 &&
    props.recordings.every(item =>
      item.masterRightsHolders.every(holder =>
        holder.status === 'Locked')) &&
    props.recordings.every(item =>
      item.masterRightsHolders.length > 0)
  const artworkSet = props.project && props.project.projectArtworkUrl.length > 0
  const albumMetadataSet = props.project &&
    props.project.title.length > 0 &&
    props.project.genre.length > 0 &&
    props.project.subGenres.length > 0 &&
    props.project.cLine.length > 0 &&
    props.project.recordLabel.length > 0 &&
    props.project.pLine.length > 0 &&
    props.project.catalogNumber.length > 0
  const isDistributed = props.project?.distributed
  const isPitched = props.project?.pitched

  const progress =
    (hasRecordings ? 1 : 0) +
    (splitsLocked ? 1 : 0) +
    (artworkSet ? 1 : 0) +
    (albumMetadataSet ? 1 : 0)

  const progressDistribute =
    (isDistributed ? 1 : 0) +
    (isPitched ? 1 : 0)

  const gotoRecordings = () => {
    navigate(`/project/${props.project?.id}`)
    navigate(`/project/${props.project?.id}?goto=recordings`)
    props.setCurrentTab('2')
  }

  const scrollToElement = (id: string) => {
    const container = document.getElementById(id)
    if (container) {
      container.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const scrollToMetadata = () => {
    scrollToElement('metadataEdit')
  }

  const scrollToArtwork = () => {
    scrollToElement('artworkEdit')
  }

  return (
    <>
      <Stack height={1} spacing={4} width={1}>
        {!props.distribution &&
        <Stack height={1} spacing={1} width={1}>
          <Stack alignItems='center' direction='row' spacing={1} width={1}>
            <Typography lineHeight={1} variant='h4'>
              Set up Release
            </Typography>
            <Tooltip title='Complete these 4 steps to unlock distribution. You can click the steps to navigate to the
          right page.'
            >
              <InfoRoundedIcon color='action' fontSize='small' />
            </Tooltip>
          </Stack>
          <Stack width={1}>
            <LinearProgress
              color='success'
              sx={{
                height: 8,
                borderRadius: '8px 8px 0px 0px',
              }}
              value={normalise(progress)}
              variant='determinate'
            />
            <Stack
              direction='column'
              sx={{
                overflow: 'hidden',
                borderRadius: '0px 0px 8px 8px',
              }}
              width={1}
            >
              <Grid container>
                <ProjectProgressItem
                  action={gotoRecordings}
                  completed={hasRecordings}
                  disabled={false}
                  icon={<MusicNoteRoundedIcon
                    color={hasRecordings ? 'disabled' : 'primary'}
                    fontSize='small'
                  />}
                  lg={3}
                  md={3}
                  secondaryText='Submit your WAV files in the recordings tab.'
                  sm={6}
                  text='Upload Recordings'
                  xl={3}
                  xs={6}
                />
                <ProjectProgressItem
                  action={gotoRecordings}
                  completed={splitsLocked}
                  disabled={false}
                  icon={<LockRoundedIcon
                    color={splitsLocked ? 'disabled' : 'success'}
                    fontSize='small'
                  />}
                  lg={3}
                  md={3}
                  secondaryText='Click the lock next to your splits to confirm them.'
                  sm={6}
                  text='Lock Splits'
                  xl={3}
                  xs={6}
                />
                <ProjectProgressItem
                  action={scrollToArtwork}
                  completed={artworkSet ?? false}
                  disabled={false}
                  icon={<ImageRoundedIcon
                    color={artworkSet ? 'disabled' : 'secondary'}
                    fontSize='small'
                  />}
                  lg={3}
                  md={3}
                  secondaryText='Submit your artwork in JPG or PNG format.'
                  sm={6}
                  text='Upload Artwork'
                  xl={3}
                  xs={6}
                />
                <ProjectProgressItem
                  action={scrollToMetadata}
                  completed={albumMetadataSet ?? false}
                  disabled={false}
                  icon={<DataObjectRoundedIcon
                    color={albumMetadataSet ? 'disabled' : 'warning'}
                    fontSize='small'
                  />}
                  lg={3}
                  md={3}
                  secondaryText='Leave the UPC and ISRC code fields empty to autogenerate.'
                  sm={6}
                  text='Set Metadata'
                  xl={3}
                  xs={6}
                />
              </Grid>
            </Stack>
          </Stack>
        </Stack>}
        {(isDistributed === false || isPitched === false) &&
        <Stack spacing={1} width={1}>
          <Stack alignItems='center' direction='row' spacing={1} width={1}>
            <Typography lineHeight={1} variant='h4'>
              Get Heard
            </Typography>
            <Tooltip title='Once your release is set up, these steps will unlock. Once these 2 steps are complete, your
            project will be completed.'
            >
              <InfoRoundedIcon color='action' fontSize='small' />
            </Tooltip>
          </Stack>
          <Stack
            height={1}
            sx={{
              filter: normalise(progress) !== 100
                ? 'grayscale(100%)'
                : undefined,
            }}
            width={1}
          >
            <LinearProgress
              color='success'
              sx={{
                height: 8,
                borderRadius: '8px 8px 0px 0px',
              }}
              value={normaliseDistribute(progressDistribute)}
              variant='determinate'
            />
            <Stack
              direction='column'
              sx={{
                overflow: 'hidden',
                borderRadius: '0px 0px 8px 8px',
              }}
              width={1}
            >
              <Grid container height={1}>
                <ProjectProgressItem
                  action={props.distribute}
                  completed={isDistributed ?? false}
                  disabled={normalise(progress) !== 100 || props.project?.organisation?.id !== currentOrganisation?.id}
                  icon={<PublishRoundedIcon
                    color='info'
                    fontSize='small'
                  />}
                  label='Distribution is only available to release owners.'
                  lg={6}
                  md={6}
                  secondaryText='Get your music in stores, plan 14 days before your release date.'
                  sm={6}
                  text='Distribute Release'
                  xl={6}
                  xs={6}
                />
                <ProjectProgressItem
                  action={props.pitch}
                  completed={isPitched ?? false}
                  disabled={normalise(progress) !== 100 || props.project?.organisation?.id !== currentOrganisation?.id}
                  icon={<CampaignRoundedIcon
                    color='info'
                    fontSize='small'
                  />}
                  label='Pitching is only available to release owners.'
                  lg={6}
                  md={6}
                  secondaryText='Get heard by editors at the largest streaming platforms.'
                  sm={6}
                  text='Pitch to Editors'
                  xl={6}
                  xs={6}
                />
              </Grid>
            </Stack>
          </Stack>
        </Stack>}

        {props.distribution &&
        <Stack height={1} spacing={1} width={1}>
          <Stack alignItems='center' direction='row' spacing={1} width={1}>
            <Typography lineHeight={1} variant='h4'>
              Your Distribution
            </Typography>
            <Tooltip title='Your release has been distributed and pitched, you can come back here for support.'>
              <InfoRoundedIcon color='action' fontSize='small' />
            </Tooltip>
          </Stack>
          <Stack
            direction={mobile ? 'column' : 'row'}
            spacing={2}
            width={1}
          >
            <Stack width={1}>
              <LinearProgress
                color={props.distribution.status === 'Under review'
                  ? 'primary'
                  : props.distribution.status === 'Changes requested'
                    ? 'warning'
                    : props.distribution.status === 'Distributed'
                      ? 'success'
                      : 'error'}
                sx={{
                  height: 8,
                  borderRadius: '8px 8px 0px 0px',
                }}
                value={100}
                variant={props.distribution.status === 'Under review'
                  ? 'indeterminate'
                  : 'determinate'}
              />
              <Stack
                direction='column'
                height={1}
                sx={{
                  overflow: 'hidden',
                  borderRadius: '0px 0px 8px 8px',
                }}
                width={1}
              >
                <Grid container height={1}>
                  <ProjectProgressItem
                    action={() => setOpen(true)}
                    completed={false}
                    disableIcon
                    disabled={false}
                    icon={null}
                    lg={12}
                    md={12}
                    secondaryText={props.distribution.status === 'Under review'
                      ? 'Your release is under review by our moderators. We will contact you when it is approved.'
                      : props.distribution.status === 'Changes requested'
                        ? 'Some changes are requested on your release, please check your email for instructions.'
                        : props.distribution.status === 'Distributed'
                          ? 'Your release has been sent out to stores.'
                          : 'Your release has been taken down from stores.'}
                    sm={12}
                    text={props.distribution.status}
                    xl={12}
                    xs={12}
                  />
                </Grid>
              </Stack>
            </Stack>
            <Stack
              height={1}
              sx={{
                filter: props.distribution.status !== 'Distributed' ||
              props.project?.organisation?.id !== currentOrganisation?.id
                  ? 'grayscale(100%)'
                  : undefined,
              }}
              width={1}
            >
              <Stack
                direction='column'
                height={1}
                sx={{
                  overflow: 'hidden',
                  borderRadius: '8px',
                  borderRight: `8px ${themeItem.palette.primary.main} solid`,
                }}
                width={1}
              >
                <Tooltip title='Support is only available to the release owner.'>
                  <Grid container height={1}>
                    <ProjectProgressItem
                      action={() => {
                        navigate(`/project/${props.project?.id}?goto=distribution&tab=support`)
                      }}
                      completed={false}
                      disableIcon
                      disabled={props.distribution.status !== 'Distributed' ||
                    props.project?.organisation?.id !== currentOrganisation?.id}
                      icon={<SupportAgentRoundedIcon
                        color='primary'
                        fontSize='small'
                      />}
                      lg={12}
                      md={12}
                      secondaryText='Get support directly from your dashboard.'
                      sm={12}
                      text='Get Support'
                      xl={12}
                      xs={12}
                    />
                  </Grid>
                </Tooltip>
              </Stack>
            </Stack>
            <Stack
              direction='column'
              height={1}
              sx={{
                overflow: 'hidden',
                borderRadius: '8px',
                borderRight: `8px ${themeItem.palette.secondary.main} solid`,
              }}
              width={1}
            >
              <Grid container height={1}>
                <ProjectProgressItem
                  action={() => setShareModal(true)}
                  completed={false}
                  disableIcon
                  disabled={false}
                  icon={<AddPhotoAlternateRoundedIcon
                    color='secondary'
                    fontSize='small'
                  />}
                  lg={12}
                  md={12}
                  secondaryText='Generate a story or post automatically'
                  sm={12}
                  text='Generate Assets'
                  xl={12}
                  xs={12}
                />
              </Grid>
            </Stack>
          </Stack>
        </Stack>}
      </Stack>
      {props.distribution &&
      <StatusModalDistribution
        close={() => setOpen(false)}
        open={open}
      />}
      {props.project &&
      <SocialAssetModal
        close={() => setShareModal(false)}
        imageMainUrl={props.project.artworkPreviewUrl}
        imageSecondaryUrl=''
        open={shareModal}
        platform='Spotify'
        project={props.project}
        text='Out now'
      />}
    </>
  )
}
export default ProjectProgress
