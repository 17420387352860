/* eslint-disable unicorn/no-unsafe-regex */
/* eslint-disable no-useless-escape */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable unicorn/no-array-reduce */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { Stack } from '@mui/material'
import _ from 'lodash'

import MarketingCloudChart from 'src/components/pages/Links/ViewLink/ViewLinkComponents/LocationsComponents/MarketingCloudChart'
import SourcesChart from 'src/components/pages/Links/ViewLink/ViewLinkComponents/OverviewComponents/SourcesChart'
import TrafficChart from 'src/components/pages/Links/ViewLink/ViewLinkComponents/OverviewComponents/TrafficChart'
import type { MarketingAnalyticsDates, MarketingAnalyticsString } from 'src/models/Marketing'

type Props = {
  clicks: MarketingAnalyticsDates[]
  views: MarketingAnalyticsDates[]
  presaves: MarketingAnalyticsDates[]
  destinations: MarketingAnalyticsString[]
  destinationsPresaves: MarketingAnalyticsString[]
  sourcesViews: MarketingAnalyticsString[]
  sourcesClicks: MarketingAnalyticsString[]
}

const ViewLinkOverview = (props: Props) => {
  const regexItem = /([\da-z][\da-z\-]{0,61}[\da-z]\.)+[\da-z][\da-z\-]*[\da-z]/

  const sourceViews = props.sourcesViews.map(sourceItem => ({
    _id: regexItem.exec(sourceItem._id)?.[0]?.toString() ?? 'Unset',
    count: sourceItem.count ?? 0,
  }))

  const sourceClicks = props.sourcesClicks.map(sourceItem => ({
    _id: regexItem.exec(sourceItem._id)?.[0]?.toString() ?? 'Unset',
    count: sourceItem.count ?? 0,
  }))

  const mappedViews = sourceViews.reduce((b: MarketingAnalyticsString[], a) => {
    const ind = b.findIndex(event => event._id === a._id)
    if (ind > -1) {
      b[ind].count = +b[ind].count + +a.count
    } else {
      a.count = +a.count || 0
      b.push(a)
    }
    return b
  }, [])

  const mappedClicks = sourceClicks.reduce((b: MarketingAnalyticsString[], a) => {
    const ind = b.findIndex(event => event._id === a._id)
    if (ind > -1) {
      b[ind].count = +b[ind].count + +a.count
    } else {
      a.count = +a.count || 0
      b.push(a)
    }
    return b
  }, [])

  return (
    <Stack spacing={3} width={1}>
      <TrafficChart
        clicks={props.clicks}
        presaves={props.presaves}
        views={props.views}
      />
      <SourcesChart
        destinations={props.destinations}
        destinationsPresaves={props.destinationsPresaves}
      />
      <MarketingCloudChart
        clicks={mappedClicks}
        sources
        views={mappedViews}
      />
    </Stack>
  )
}

export default ViewLinkOverview
