import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, Divider, Grid, IconButton, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router'

import { createMarketingLink, firstLink } from 'src/api/links'
import BillingModal from 'src/components/pages/Account/Billing/BillingModal'
import PresaveLinkCard from 'src/components/pages/Links/LinksList/LinksListModal/LinksListComponents/PresaveLinkCard'
import ShortLinkCard from 'src/components/pages/Links/LinksList/LinksListModal/LinksListComponents/ShortLinkCard'
import SmartLinkCard from 'src/components/pages/Links/LinksList/LinksListModal/LinksListComponents/SmartLinkCard'
import { useAuth } from 'src/components/providers/AuthProvider'
import MarketingCloudItem from 'src/images/marketingCloud.jpg'
import { LINK_TYPE, MarketingLink } from 'src/models/Marketing'
import type Project from 'src/models/Project'

const premiumFeatures = [
  'Gain new followers with Spotify, Apple Music and Deezer Pre-saves',
  'Exponentially grow your day-1 streams with Pre-saves Subscriptions',
  'Collect fan emails and notify them when you release new music',
  'Use your own domain',
]

type Props = {
  close: () => void
  open: boolean
  project?: Project
}

const NewLinkModal = (props: Props) => {
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))
  const [billingModal, setBillingModalOpen] = useState(false)

  const { currentOrganisation, updateCurrentOrganisation, refreshCurrentOrganisation } = useAuth()
  const navigate = useNavigate()
  const [type, setType] = useState(0)
  const [title, setTitle] = useState(props.project?.title ?? '')
  const [scanLink, setScanLink] = useState(props.project?.UPC ?? '')
  const [loading, setLoading] = useState(false)

  const notRightTier = currentOrganisation?.membershipTier === 'FREE'

  const newMarketingLink = async () => {
    setLoading(true)

    if (!currentOrganisation?.firstLandingPage) {
      setLoading(true)
      await updateCurrentOrganisation({ ...currentOrganisation, firstLandingPage: true })
        .then(() => setLoading(true))
        .then(refreshCurrentOrganisation)
        .finally(() => setLoading(true))

      await firstLink()
    }

    await createMarketingLink(new MarketingLink({
      type: LINK_TYPE[type],
      title,
      domain: 'rls.ee',
      upc: props.project?.UPC ?? '',
      isrc: props.project?.UPC ?? '',
      projectId: props.project?.id ?? '',
      artworkUrl: props.project?.artworkPreviewUrl ?? '',
      artists: props.project?.primaryArtists.map(item => item.name) ?? [],
      releaseDate: props.project?.releaseDate ?? new Date(new Date().setDate(new Date().getDate() + 7)),
      scanLinkPrivate: scanLink,
      useOrgDefaultSocialIcons: true,
      theme: 'Subtle',
      useDefaultPixels: currentOrganisation?.membershipTier === 'CREATOR_PRO' ||
        currentOrganisation?.membershipTier === 'CREATOR_PRO_YEARLY' ||
        currentOrganisation?.membershipTier === 'ENTERPRISE' ||
        currentOrganisation?.membershipTier === 'ENTERPRISE_YEARLY',
    }))
      .then(marketingLinkId => {
        if (props.project) {
          navigate(`/project/${props.project.id}?goto=marketing&marketingEditId=${marketingLinkId}&edit=true`)
        } else {
          navigate(`/links?marketingEditId=${marketingLinkId}&edit=true`)
        }
      })
      .then(() => props.close())
      .finally(() => setLoading(false))
  }

  return (
    <>
      {billingModal && currentOrganisation &&
      <BillingModal
        account={currentOrganisation}
        close={() => setBillingModalOpen(false)}
        open={billingModal}
      />}
      <Dialog
        BackdropProps={{
          timeout: 500,
        }}
        closeAfterTransition
        fullScreen={fullScreen}
        fullWidth
        maxWidth={notRightTier ? 'lg' : 'sm'}
        onClose={props.close}
        open={props.open}
        sx={{
          justifyContent: 'center',
        }}
      >
        <form
          onSubmit={event => {
            event.preventDefault()
            void newMarketingLink()
          }}
        >
          <Stack height={1} paddingX={3} paddingY={3} spacing={4} width={1}>
            <Stack
              alignItems='center'
              direction='row'
              justifyContent='space-between'
              width={1}
            >
              <Typography variant='h3'>
                Create a Smart Link
              </Typography>
              <IconButton onClick={() => props.close()}>
                <CloseIcon color='disabled' />
              </IconButton>
            </Stack>

            <Stack direction={fullScreen ? 'column' : 'row'} height={1} spacing={4} width={1}>
              <Stack direction='column' spacing={4} width={1}>
                <Stack direction='row' justifyContent='center' spacing={1} width={1} >
                  <Grid container width={1}>
                    <Grid item lg={4} md={6} sm={6} xl={4} xs={6}>
                      <SmartLinkCard
                        selected={type}
                        setSelected={setType}
                      />
                    </Grid>

                    <Grid item lg={4} md={6} sm={6} xl={4} xs={6}>
                      <PresaveLinkCard
                        selected={type}
                        setSelected={setType}
                      />
                    </Grid>

                    <Grid item lg={4} md={6} sm={6} xl={4} xs={6}>
                      <ShortLinkCard
                        selected={type}
                        setSelected={setType}
                      />
                    </Grid>

                  </Grid>
                </Stack>

                <Stack spacing={2} width={1}>
                  <Stack>
                    <TextField
                      autoComplete='new-password'
                      label='Title'
                      onChange={event => setTitle(event.target.value)}
                      placeholder='Landing page title'
                      value={title}
                    />
                  </Stack>

                  {type === 0 &&
                  <Stack alignItems='center' direction='row' justifyContent='center' spacing={1} width={1}>
                    <Divider sx={{ width: '46%' }} />
                    <Typography
                      sx={{
                        flexShrink: 0,
                      }}
                      variant='textLabel'
                    >
                      OR
                    </Typography>
                    <Divider sx={{ width: '46%' }} />
                  </Stack>}
                  {type === 0 &&
                  <Stack>
                    <TextField
                      autoComplete='new-password'
                      label='Scan source'
                      onChange={event => setScanLink(event.target.value)}
                      placeholder='ISRC, UPC, or store link'
                      value={scanLink}
                    />
                  </Stack>}
                </Stack>

                <Stack direction='row' justifyContent='flex-end' spacing={1} width={1}>
                  <Button
                    onClick={props.close}
                    variant='text'
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    disabled={title.length === 0 && scanLink.length === 0}
                    loading={loading}
                    type='submit'
                    variant='contained'
                  >
                    Create
                  </LoadingButton>
                </Stack>
              </Stack>

              {notRightTier &&
              <Stack
                height='auto'
                padding={fullScreen ? 2 : 3}
                spacing={2}
                sx={{
                  borderRadius: 2,
                  background: 'black',
                }}
                width={1}
              >
                <Stack justifyContent='center' width={1}>
                  <Typography
                    color={themeItem.palette.primary.contrastText}
                    lineHeight={1.3}
                    variant='h3'
                  >
                    Grow your audience 2x faster with Releese
                  </Typography>
                  <Typography
                    color={themeItem.palette.primary.contrastText}
                    maxWidth={300}
                    variant='body2'
                  >
                    Transform your fan acquisition strategy with a premium subscription. Cancel anytime.
                  </Typography>
                </Stack>
                <Stack
                  alignItems='center'
                  direction={fullScreen ? 'column' : 'row'}
                  height={1}
                  spacing={2}
                  width={1}
                >
                  <Stack direction='column' spacing={2} width={fullScreen ? '100%' : '50%'}>
                    {premiumFeatures.map(item =>
                      <Stack alignItems='center' direction='row' key={item} spacing={1} width={1}>
                        <CheckCircleRoundedIcon color='success' fontSize='medium' />
                        <Typography
                          color={themeItem.palette.primary.contrastText}
                          textAlign='left'
                          variant='body2'
                        >
                          {item}
                        </Typography>
                      </Stack>)}
                  </Stack>
                  <img
                    alt='Releese Marketing Cloud'
                    height='auto'
                    src={MarketingCloudItem}
                    style={{
                      borderRadius: '16px',
                      objectFit: 'cover',
                      width: fullScreen ? '100%' : '50%',
                    }}
                    width='100%'
                  />
                </Stack>
                <Stack width={1}>
                  <Button
                    onClick={() => setBillingModalOpen(true)}
                    rounded
                    startIcon={<AutoAwesomeRoundedIcon />}
                    sx={{
                      marginTop: 1,
                      flexShrink: 0,
                      color: 'white!important',
                      background: '#FFA500',
                      ':hover': {
                        background: '#c47f00',
                      },
                    }}
                  >
                    Upgrade
                  </Button>
                </Stack>
              </Stack>}
            </Stack>
          </Stack>
        </form>
      </Dialog>
    </>
  )
}
export default NewLinkModal
