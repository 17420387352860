/* eslint-disable no-restricted-imports */
/* eslint-disable unicorn/no-unsafe-regex */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable complexity */
/* eslint-disable react-hooks/exhaustive-deps */
import AddShoppingCartRoundedIcon from '@mui/icons-material/AddShoppingCartRounded'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import CloseIcon from '@mui/icons-material/Close'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import CopyrightRoundedIcon from '@mui/icons-material/CopyrightRounded'
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import PublicRoundedIcon from '@mui/icons-material/PublicRounded'
import PublishRoundedIcon from '@mui/icons-material/PublishRounded'
import SaveRoundedIcon from '@mui/icons-material/SaveRounded'
import StoreRoundedIcon from '@mui/icons-material/StoreRounded'
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded'
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab'
import { AppBar, Button, Dialog, Divider, Fab, IconButton, MenuItem, Select, Slide, Stack, Tab, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import ReactDevicePreview from 'react-device-preview'
import { usePalette } from 'react-palette'
import { v4 } from 'uuid'

import { createDistribution, getPublishingList } from 'src/api/distribution'
import { createMarketingLink, getUniqueLink } from 'src/api/links'
import { getBasicOrganisation } from 'src/api/organisation'
import { getRecordingsByProjectId } from 'src/api/recordings'
import EditLinkRightTab from 'src/components/pages/Links/EditLink/EditLinkRightTab'
import AddOnsDistribute from 'src/components/pages/Projects/DistributeModal/DistributeModalComponents/AddOns'
import CompleteDistribute from 'src/components/pages/Projects/DistributeModal/DistributeModalComponents/DistributeComplete'
import PreSaveAddOn from 'src/components/pages/Projects/DistributeModal/DistributeModalComponents/PresaveAddOn'
import PublishingAddOnTab from 'src/components/pages/Projects/DistributeModal/DistributeModalComponents/PublishingAddOn'
import RecordingsDistribute from 'src/components/pages/Projects/DistributeModal/DistributeModalComponents/Recordings'
import StoresDistribute from 'src/components/pages/Projects/DistributeModal/DistributeModalComponents/StoresDistribute'
import SubmitDistribute from 'src/components/pages/Projects/DistributeModal/DistributeModalComponents/Submit'
import TerritoriesDistribute from 'src/components/pages/Projects/DistributeModal/DistributeModalComponents/Territories'
import DistributionRulesModal from 'src/components/pages/Projects/DistributeModal/DistributionRulesModal'
import DistributionTermsModal from 'src/components/pages/Projects/DistributeModal/DistributionTermsModal'
import { isrcValidation } from 'src/components/pages/Projects/EditProject/EditProjectTabs/RecordingsTab/RecordingTabs/PublishingTab/RecordingPublishingTab'
import { generateTitleRecording } from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/RecordingsTab/Components/EditRecordingComponent'
import { useAuth } from 'src/components/providers/AuthProvider'
import { continentsData } from 'src/data/distributionCountries'
import { defaultSelectAllPlatforms } from 'src/data/storesData'
import { upcValidation } from 'src/data/upcValidation'
import type { ArtistObject, AtmosSubmission, SingleWriter } from 'src/models/Distribution'
import { type WriterInformation, Distribution } from 'src/models/Distribution'
import { type SocialList, MarketingLink } from 'src/models/Marketing'
import type { BasicOrganisation } from 'src/models/Organisation'
import type Project from 'src/models/Project'
import type Recording from 'src/models/Recording'
import * as darkTheme from 'src/styles/dark.theme'
import * as lightTheme from 'src/styles/light.theme'

type Props = {
  close: () => void
  open: boolean
  project: Project
  updateRecordingsSubtle: () => Promise<void>
  setProject: React.Dispatch<React.SetStateAction<Project | undefined>>
}

type ErrorMessageServer = {
  response: {
    data: {
      message: string
    }
  }
}

const checkCname = /(?:[\dA-Za-z]{2,61}\.){2}[\dA-Za-z]{2,61}/

const DistributeModal = (props: Props) => {
  const { currentOrganisation, currentFirebaseUser } = useAuth()
  const themeColors = useTheme()
  const matches = useMediaQuery(themeColors.breakpoints.down('md'))
  const matchesLarge = useMediaQuery(themeColors.breakpoints.down('lg'))
  const fullScreen = useMediaQuery(themeColors.breakpoints.down('sm'))

  const [termsModal, setTermsModal] = useState(false)
  const [_, setLoading] = useState(false)
  const [agreeTerms, setAgreeTerms] = useState(false)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [currentTab, setCurrentTab] = useState(1)
  const [stores, setStores] = useState(defaultSelectAllPlatforms)
  const [recordings, setRecordings] = useState<Recording[]>()
  const [error, setError] = useState('')
  const [beatportGenre, setBeatportGenre] = useState('')
  const [territories, setTerritories] = useState(continentsData.flatMap(({ countries }) => countries))
  const projectFields = props.project

  const [appleDigitalAddOn, setAppleDigitalAddOn] = useState(false)
  const [appleEmail, setAppleEmail] = useState('')

  const [atmosAddOn, setAtmosAddOn] = useState(false)
  const [atmosFiles, setAtmosFiles] = useState<AtmosSubmission[]>([])

  const [presaveAddOn, setPresaveAddOn] = useState(true)
  const [previewModal, setPreviewModal] = useState(false)
  const [organisation, setOrganisation] = useState<BasicOrganisation | null>(null)
  const [smartlinkDomain, setSmartLinkDomain] = useState('rls.ee')
  const [smartlinkUrl, setSmartLinkUrl] = useState(v4())
  const [smartlinkSubdomain, setSmartLinkSubdomain] = useState('presave')
  const [presaveSubscribe, setPresaveSubscribe] = useState(true)
  const [showAllReleases, setShowAllReleases] = useState(true)
  const [uniqueLinkBoolean, setUniqueLinkBoolean] = useState(true)
  const [presaveDefaultSocialIcons, setPresaveDefaultSocialIcons] = useState(true)
  const [presaveSocialIcons, setPresaveSocialIcons] = useState<SocialList[]>([])
  const [presaveTemplate, setPresaveTemplate] = useState('Subtle')
  const [presaveTheme, setPresaveTheme] = useState('Light')
  const [presaveButtonStyles, setPresaveButtonStyles] = useState('color')
  const [presaveFollowArtists, setPresaveFollowArtists] = useState<ArtistObject[]>(props.project.primaryArtists
    .filter(item => item.uri.length > 0) ?? [])
  const [bgColor, setBgColor] = useState('#ffffff')
  const [buttonShape, setButtonShape] = useState('rounded')
  const [presaveFont, setPresaveFont] = useState('default')
  const [presaveDescription, setPresaveDescription] = useState('Pre-save my upcoming release now!')
  const [colors, setColors] = useState<string[]>([])
  const [presaveActive, setPresaveActive] = useState(true)
  const primaryColors = usePalette(props.project.artworkPreviewUrl)

  const [publishingAddOn, setPublishingAddOn] = useState(false)
  const [neighboringAddOn, setNeighboringAddOn] = useState(true)
  const [pastWriters, setPastWriters] = useState<SingleWriter[]>([])
  const [writerInfo, setWriterInfo] = useState<WriterInformation[]>([])

  const [appleMotionAddOn, setAppleMotionAddOn] = useState(false)
  const [folderLink, setFolderLink] = useState('')

  useEffect(() => {
    void getPublishingList()
      .then(setPastWriters)
      .catch(() => null)
  }, [])

  useEffect(() => {
    if (recordings) {
      setWriterInfo(recordings?.map(recording => ({
        recordingId: recording.id,
        recordingTitle: recording.title,
        composerInfo: recording.writtenBy.map(writerItem => ({
          writer: writerItem,
          ipi: pastWriters.find(item => item.writer === writerItem)?.ipi ?? '',
          pro: pastWriters.find(item => item.writer === writerItem)?.pro ?? '',
          percent: undefined,
        })),
      })))
    }
  }, [recordings, pastWriters])

  const checkPubOwnership = recordings?.map(recording => Number(recording.publisherRightsHolders.map(holder =>
    Number(holder.ownership.toFixed(2))).reduce((a, b) => a + b, 0).toFixed(2)) === 100)
    .every(element => element) ?? false
  const checkPubLocked = recordings?.map(recording => recording.publisherRightsHolders.map(holder =>
    holder.status === 'Locked').every(element => element))
    .every(element => element) ?? false
  const checkPubCover = recordings?.map(recording => recording.trackType !== 'Cover')
    .every(element => element) ?? false
  const checkProInfo = writerInfo?.map(recording => Number(recording.composerInfo.map(holder =>
    Number((holder.percent ?? 0).toFixed(2))).reduce((a, b) => a + b, 0).toFixed(2)) === 100).every(element => element)

  const checkPub = checkPubOwnership && checkPubLocked && checkPubCover

  useEffect(() => {
    setPublishingAddOn(checkPub)
  }, [checkPub])

  const activeTheme = presaveTheme === 'Light'
    ? lightTheme
    : darkTheme

  useEffect(() => {
    setColors([
      primaryColors.data.darkMuted ?? '#ffffff',
      primaryColors.data.darkVibrant ?? themeColors.palette.secondary.main,
      primaryColors.data.muted ?? themeColors.palette.error.main,
      primaryColors.data.vibrant ?? themeColors.palette.success.main,
      primaryColors.data.lightMuted ?? themeColors.palette.info.main,
      primaryColors.data.lightVibrant ?? themeColors.palette.warning.main,
    ])
  }, [primaryColors.loading,
    primaryColors.data])

  useEffect(() => {
    if (colors[0]) {
      setBgColor(colors[0])
    }
  }, [props.project.artworkPreviewUrl, colors])

  const checkTitle = recordings?.map(recording => recording.title && recording.title.length > 0)
    .every(element => element === true)

  const checkTitleVersionErrors = recordings?.map(recording =>
    !recording.title.includes('(') && !recording.title.includes(')'))
    .every(element => element)

  const checkTitleRemixVersionErrors = recordings?.map(recording =>
    !recording.title.toLowerCase().includes('remix') &&
    !recording.subTitle.toLowerCase().includes('remix'))
    .every(element => element)

  const checkPrimaryArtists = recordings?.map(recording => recording.primaryArtists.length > 0)
    .every(element => element)
  const checkCoverData = recordings?.map(recording => recording.trackType === 'Cover'
    // eslint-disable-next-line no-unneeded-ternary, @typescript-eslint/prefer-optional-chain
    ? recording.coverItem && recording.coverItem.name && recording.coverItem.name.length > 0
      ? true
      : false
    : true)
    .every(element => element)
  const checkRemixData = recordings?.map(recording => recording.trackType === 'Remix'
    // eslint-disable-next-line no-unneeded-ternary, @typescript-eslint/prefer-optional-chain
    ? recording.remixArtists.length > 0
      ? true
      : false
    : true)
    .every(element => element)
  const checkUniqueArtists = recordings?.map(recording =>
    !recording.primaryArtists.map(item => item.name).some(artist =>
      recording.featuredArtists.map(item => item.name).includes(artist)) &&
    !recording.featuredArtists.map(item => item.name).some(artist =>
      recording.remixArtists.map(item => item.name).includes(artist)))
    .every(element => element)
  const checkType = recordings?.map(recording => recording.trackType && recording.trackType.length > 0)
    .every(element => element)
  const checkWrittenBy = recordings?.map(recording =>
    recording.writtenBy.map(writer => writer.split(' ').length > 1).every(element => element) &&
    recording.writtenBy.length > 0 &&
    recording.writtenBy.length < 15)
    .every(element => element)
  const checkComposedBy = recordings?.map(recording =>
    recording.composedBy.map(composer => composer.split(' ').length > 1).every(element => element) &&
    recording.composedBy.length > 0 &&
    recording.composedBy.length < 15)
    .every(element => element)
  const checkProducedBy = recordings?.map(recording =>
    recording.producedBy.length > 0 &&
    recording.producedBy.length < 15)
    .every(element => element)
  const checkLanguage = recordings?.map(recording => recording.language && recording.language.length > 0)
    .every(element => element === true)
  const checkMasterOwnership = recordings?.map(recording => Number(recording.masterRightsHolders.map(holder =>
    Number(holder.ownership.toFixed(2))).reduce((a, b) => a + b, 0).toFixed(2)) === 100)
    .every(element => element)
  const checkMasterLocked = recordings?.map(recording => recording.masterRightsHolders.map(holder =>
    holder.status === 'Locked').every(element => element))
    .every(element => element)
  const checkISRC = !!recordings &&
    recordings.map(recording => recording.ISRC.length === 0 ||
      (recording.ISRC.length > 0 &&
        isrcValidation.test(recording.ISRC)))
      .every(element => element) &&
    new Set(recordings.map(item => item.ISRC).filter(item => item.length > 0)).size ===
      recordings.map(item => item.ISRC).filter(item => item.length > 0).length

  const startYearRegex = /^[12]\d{3}/
  const endYearRegex = /[12]\d{3}$/
  const endsWithNumber = /\d$/
  const startsWithNumber = /^\d/
  const matchVolume = /Vol\. \d+/

  const errorTitlesRecordings =
    recordings?.map(item => !endsWithNumber.test(item.title.trim()) ||
    endYearRegex.test(item.title.trim()) ||
    matchVolume.test(item.title.trim()))
      .every(element => element) ?? false

  const recordingTitlesStartWithNumbers = !!recordings &&
    recordings.map(item => !startsWithNumber.test(item.title.trim()) || startYearRegex.test(item.title.trim()))
      .every(element => element)

  const submitError = !errorTitlesRecordings || !recordingTitlesStartWithNumbers

  const checkUPC = !projectFields?.UPC ||
    projectFields?.UPC.length === 0 ||
    (upcValidation(Number(projectFields?.UPC)) &&
    projectFields?.UPC && !projectFields.UPC.includes('558451'))

  const allTitles = recordings?.map(item => generateTitleRecording(undefined, item)) ?? []
  const duplicateTitleError = new Set(allTitles).size === allTitles.length

  const checkRecordings = checkTitle && checkPrimaryArtists && checkType &&
    checkWrittenBy && checkComposedBy && checkProducedBy && checkLanguage &&
    checkMasterOwnership && checkISRC && checkMasterLocked && checkCoverData && checkRemixData && checkUniqueArtists &&
    checkTitleVersionErrors && duplicateTitleError && checkTitleRemixVersionErrors

  const errorHandling = !projectFields?.title ||
    territories.length === 0 || stores.length === 0 || recordings?.length === 0 || !projectFields.genre ||
    !projectFields.recordLabel || !projectFields.cLine || !projectFields.pLine ||
    !projectFields.projectArtworkUrl || !props.project?.releaseDate ||
    !checkRecordings || !checkUPC || !errorTitlesRecordings || (publishingAddOn && !checkProInfo) ||
    !recordingTitlesStartWithNumbers

  useEffect(() => {
    if (props.project) {
      setLoading(true)
      getRecordingsByProjectId(props.project.id)
        .then(recordingItems => {
          const newArray = recordingItems.sort((a, b) =>
            props.project.recordings.indexOf(a.id) - props.project.recordings.indexOf(b.id))
          setRecordings(newArray)
        }).finally(() => {
          setLoading(false)
        })
    }
  }, [])

  useEffect(() => {
    if (recordings) {
      setAtmosFiles(recordings.map(recording => ({
        recordingId: recording.id,
        recordingTitle: recording.title,
        atmosFileLink: '',
      })))
    }
  }, [recordings])

  useEffect(() => {
    if (smartlinkSubdomain.length > 0 && !checkCname.test(smartlinkDomain)) {
      void getUniqueLink(smartlinkSubdomain,
        smartlinkDomain,
        smartlinkUrl,
        'new')
        .then(setUniqueLinkBoolean)
    } else {
      const test = smartlinkDomain.split('.')
      void getUniqueLink(test[0],
        test[1],
        smartlinkUrl,
        'new')
        .then(setUniqueLinkBoolean)
    }
  }, [smartlinkSubdomain, smartlinkDomain, smartlinkUrl])

  useEffect(() => {
    if (currentOrganisation?.id) {
      void getBasicOrganisation(currentOrganisation.id)
        .then(org => setOrganisation(org))
    }
  }, [currentOrganisation?.id])

  const missingSubdomain = smartlinkSubdomain.length === 0 && !checkCname.test(smartlinkDomain)
  const missingUrl = smartlinkUrl.length === 0

  const distribute = async () => {
    if (projectFields && recordings && currentFirebaseUser.emailVerified) {
      setLoadingSubmit(true)
      await createDistribution(new Distribution({
        title: projectFields.title,
        primaryArtists: projectFields.primaryArtists,
        featuredArtists: projectFields.featuredArtists,
        genre: projectFields.genre,
        subGenres: projectFields.subGenres,
        labelName: projectFields.recordLabel,
        releaseDate: projectFields.releaseDate,
        cLine: projectFields.cLine,
        pLine: projectFields.pLine,
        catalogNumber: projectFields.catalogNumber,
        artworkUrl: projectFields.projectArtworkUrl,
        UPC: projectFields.UPC,
        beatportGenre,
        territories,
        platforms: stores,
        recordings,
        projectId: projectFields.id,
        publishing: publishingAddOn,
        neighboring: neighboringAddOn,
        writerInfo,
        status: 'Under review',
        appleDigitalMasters: appleDigitalAddOn && appleEmail.length > 0,
        appleDigitalMasterEmail: appleEmail.length > 0
          ? appleEmail
          : undefined,
        atmos: atmosAddOn && atmosFiles.every(item => item.atmosFileLink.length > 0),
        atmosFiles: atmosFiles.every(item => item.atmosFileLink.length > 0)
          ? atmosFiles
          : [],
        appleMotion: appleMotionAddOn && folderLink.length > 0,
        appleMotionFolderLink: appleMotionAddOn && folderLink.length > 0
          ? folderLink
          : undefined,
      }))
        .then(async () => {
          if (presaveAddOn) {
            await createMarketingLink(new MarketingLink({
              type: 'Presave',
              title: props.project.title,
              domain: smartlinkDomain,
              subdomain: smartlinkSubdomain,
              url: smartlinkUrl,
              upc: props.project.UPC,
              isrc: props.project.UPC,
              projectId: props.project.id,
              active: presaveActive,
              socialList: presaveSocialIcons,
              artworkUrl: props.project.artworkPreviewUrl,
              artists: props.project.primaryArtists.map(item => item.name),
              releaseDate: new Date(props.project.releaseDate),
              foreverPresave: presaveSubscribe,
              colorStyle: presaveButtonStyles,
              useOrgDefaultSocialIcons: presaveDefaultSocialIcons,
              theme: presaveTemplate,
              backgroundColor: bgColor,
              showAllReleases,
              palette: presaveTheme,
              fontStyle: presaveFont,
              buttonStyle: buttonShape,
              spotifyFollowArtists: presaveFollowArtists,
              useDefaultPixels: currentOrganisation?.membershipTier === 'CREATOR_PRO' ||
                currentOrganisation?.membershipTier === 'CREATOR_PRO_YEARLY' ||
                currentOrganisation?.membershipTier === 'ENTERPRISE' ||
                currentOrganisation?.membershipTier === 'ENTERPRISE_YEARLY',
            }))
          }
        })
        .then(() => {
          setCurrentTab(currentTab + 1)
          setLoadingSubmit(false)
        })
        .catch(async (error_: ErrorMessageServer) => {
          if (presaveAddOn) {
            await createMarketingLink(new MarketingLink({
              type: 'Presave',
              title: props.project.title,
              domain: smartlinkDomain,
              subdomain: smartlinkSubdomain,
              url: smartlinkUrl,
              upc: props.project.UPC,
              isrc: props.project.UPC,
              socialList: presaveSocialIcons,
              active: presaveActive,
              projectId: props.project.id,
              artworkUrl: props.project.artworkPreviewUrl,
              artists: props.project.primaryArtists.map(item => item.name),
              releaseDate: new Date(props.project.releaseDate),
              foreverPresave: presaveSubscribe,
              showAllReleases,
              useOrgDefaultSocialIcons: presaveDefaultSocialIcons,
              theme: presaveTemplate,
              backgroundColor: bgColor,
              palette: presaveTheme,
              colorStyle: presaveButtonStyles,
              fontStyle: presaveFont,
              spotifyFollowArtists: presaveFollowArtists,
              buttonStyle: buttonShape,
              useDefaultPixels: currentOrganisation?.membershipTier === 'CREATOR_PRO' ||
                currentOrganisation?.membershipTier === 'CREATOR_PRO_YEARLY' ||
                currentOrganisation?.membershipTier === 'ENTERPRISE' ||
                currentOrganisation?.membershipTier === 'ENTERPRISE_YEARLY',
            }))
          }
          if (error_.response?.data?.message && error_.response?.data?.message?.length > 0) {
            setError(error_.response.data.message)
            setLoadingSubmit(false)
          } else {
            setCurrentTab(currentTab + 1)
            setLoadingSubmit(false)
          }
        })
    }
  }

  const updateRecordingsSubtle = async () => {
    void props.updateRecordingsSubtle()
    await getRecordingsByProjectId(props.project.id)
      // eslint-disable-next-line sonarjs/no-identical-functions
      .then(recordingItems => {
        const newArray = recordingItems.sort((a, b) =>
          props.project.recordings.indexOf(a.id) - props.project.recordings.indexOf(b.id))
        setRecordings(newArray)
      }).finally(() => {
        setLoading(false)
      })
  }

  return (
    <>
      <Dialog
        aria-describedby='modal-modal-description'
        aria-labelledby='modal-modal-title'
        fullScreen={fullScreen}
        fullWidth
        maxWidth='sm'
        onClose={() => setPreviewModal(false)}
        open={previewModal}
        sx={{
          justifyContent: 'center',
        }}
      >
        <Stack alignItems='center' justifyContent='flex-start' width={1}>
          <Stack
            alignItems='center'
            direction='row'
            justifyContent='space-between'
            paddingX={3}
            paddingY={3}
            width={1}
          >
            <Typography variant='h4'>
              Preview
            </Typography>
            <IconButton onClick={() => setPreviewModal(false)}>
              <CloseIcon color='disabled' />
            </IconButton>
          </Stack>
          <Divider sx={{ width: 1 }} />
          <Stack
            alignItems='flex-start'
            direction='row'
            flexShrink={0}
            height='100%'
            paddingBottom={3}
            paddingX={3}
            position='relative'
            right={0}
            width={387}
          >
            <Stack
              height={665}
              marginTop={4}
              width={340}
            >
              <ReactDevicePreview
                device='iphonex'
                scale='0.8'
              >
                {presaveTemplate === 'Subtle' &&
                <Stack
                  sx={{
                    position: 'absolute',
                    height: '100%',
                    width: '100%',
                    background: `url(${props.project.artworkPreviewUrl})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    filter: 'blur(60px)',
                    zIndex: 2,
                    transform: 'scale(1.5)',
                    opacity: 0.6,
                    minWidth: 1920,
                    left: 'calc(50% - 960px)',
                    backgroundPositionX: 'center',
                    backgroundPositionY: 'center',
                    backgroundRepeat: 'no-repeat',
                  }}
                />}
                <Stack
                  alignItems='center'
                  flexGrow={0}
                  flexShrink={0}
                  height={750}
                  position='relative'
                  sx={{
                    background: presaveTemplate === 'Subtle'
                      ? activeTheme.default.palette.background.default
                      : bgColor,
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    '::-webkit-scrollbar': {
                      display: 'none',
                    },
                  }}
                  top={0}
                  width={1}
                >
                  <EditLinkRightTab
                    artworkPreview={props.project.artworkPreviewUrl}
                    organisation={organisation}
                    smartLinkData={new MarketingLink({
                      type: 'Presave',
                      title: props.project.title,
                      artists: props.project.primaryArtists.map(item => item.name),
                      foreverPresave: presaveSubscribe,
                      useOrgDefaultSocialIcons: presaveDefaultSocialIcons,
                      releaseDate: props.project.releaseDate,
                      palette: presaveTheme,
                      theme: presaveTemplate,
                      colorStyle: presaveButtonStyles,
                      descriptionText: presaveDescription,
                      fontStyle: presaveFont,
                      buttonStyle: buttonShape,
                      domain: smartlinkDomain,
                    })}
                  />
                </Stack>
              </ReactDevicePreview>
            </Stack>
          </Stack>
        </Stack>
      </Dialog>

      <Dialog
        BackdropProps={{
          timeout: 500,
        }}
        closeAfterTransition
        fullScreen
        onClose={props.close}
        open={props.open}
        sx={{
          '& .MuiPaper-root': {
            transform: 'none!important',
          },
          '.MuiDialog-paper': {
            height: '100%',
          },
        }}
      >
        <Slide direction='up' in={props.open}>
          <Stack
            height={1}
            overflow='auto'
            sx={{
              background: theme => theme.palette.background.input,
            }}
          >
            <TabContext value={currentTab.toString()}>

              <Stack
                paddingX={2}
                top={2}
                width={1}
              >
                <AppBar
                  position='fixed'
                  sx={{
                    background: theme => theme.palette.background.default,
                    borderRadius: 2,
                    zIndex: '99999!important',
                    top: 16,
                    marginLeft: 2,
                    marginRight: 2,
                    paddingX: 2,
                    width: 'calc(100% - 32px)',
                  }}
                  variant='outlined'
                >
                  <Toolbar
                    sx={{
                      justifyContent: 'space-between',
                      paddingX: '8px!important',
                    }}
                  >
                    <Stack
                      alignItems='center'
                      direction='row'
                      spacing={2}
                      width='calc(100% - 40px)'
                    >
                      {!matches &&
                      <Typography variant='h3'>
                        Distribution
                      </Typography>}

                      {currentTab !== 7 && matches &&
                      <Select
                        onChange={event => setCurrentTab(Number(event.target.value))}
                        value={currentTab.toString()}
                      >
                        <MenuItem disabled={loadingSubmit || error.length > 0} value='1'>
                          Recordings
                        </MenuItem>
                        <MenuItem disabled={loadingSubmit || error.length > 0} value='2'>
                          Territories
                        </MenuItem>
                        <MenuItem disabled={loadingSubmit || error.length > 0} value='3'>
                          Stores
                        </MenuItem>
                        <MenuItem disabled={loadingSubmit || error.length > 0} value='4'>
                          Add-ons
                        </MenuItem>
                        <MenuItem disabled={loadingSubmit || error.length > 0 || !presaveAddOn} value='5'>
                          Pre-save
                        </MenuItem>
                        <MenuItem disabled={loadingSubmit || error.length > 0 || !publishingAddOn} value='6'>
                          Publishing
                        </MenuItem>
                        <MenuItem disabled={loadingSubmit || error.length > 0} value='7'>
                          Submit
                        </MenuItem>
                      </Select>}

                      {currentTab !== 8 && !matches &&
                      <TabList
                        aria-label='project view'
                        onChange={(event, value: string) => setCurrentTab(Number(value))}
                        sx={{
                          minHeight: '32px',
                          '.MuiTab-root': {
                            borderRadius: 16,
                          },
                          '.MuiTabs-indicator': {
                            visibility: 'hidden',
                          },
                        }}
                        variant='scrollable'
                      >
                        <Tab
                          disabled={loadingSubmit || error.length > 0}
                          icon={<MusicNoteRoundedIcon />}
                          label='Recordings'
                          sx={{ minHeight: 32 }}
                          value='1'
                        />
                        <Tab
                          disabled={loadingSubmit || error.length > 0}
                          icon={<PublicRoundedIcon />}
                          label='Territories'
                          sx={{ minHeight: 32 }}
                          value='2'
                        />
                        <Tab
                          disabled={loadingSubmit || error.length > 0}
                          icon={<StoreRoundedIcon />}
                          label='Stores'
                          sx={{ minHeight: 32 }}
                          value='3'
                        />
                        <Tab
                          disabled={loadingSubmit || error.length > 0}
                          icon={<AddShoppingCartRoundedIcon />}
                          label='Add-ons'
                          sx={{ minHeight: 32 }}
                          value='4'
                        />
                        <Tab
                          disabled={loadingSubmit || error.length > 0 || !presaveAddOn}
                          icon={<SaveRoundedIcon />}
                          label='Pre-save'
                          sx={{ minHeight: 32 }}
                          value='5'
                        />
                        <Tab
                          disabled={loadingSubmit || error.length > 0 || !publishingAddOn}
                          icon={<CopyrightRoundedIcon />}
                          label='Publishing'
                          sx={{ minHeight: 32 }}
                          value='6'
                        />
                        <Tab
                          disabled={loadingSubmit || error.length > 0}
                          icon={<CheckCircleOutlineRoundedIcon />}
                          label='Submit'
                          sx={{ minHeight: 32 }}
                          value='7'
                        />
                      </TabList>}
                    </Stack>
                    {currentTab !== 8 &&
                    <IconButton
                      aria-label='close'
                      disabled={loadingSubmit || error.length > 0}
                      onClick={props.close}
                    >
                      <CloseRoundedIcon />
                    </IconButton>}
                  </Toolbar>
                </AppBar>
              </Stack>
              <Stack
                alignItems='center'
                height={1}
                overflow='auto'
                paddingTop={11}
                position='relative'
                width={1}
              >
                <Stack
                  maxWidth='lg'
                  paddingBottom={8}
                  paddingX={1}
                  width={1}
                >
                  <TabPanel sx={{ width: 1, height: 1, paddingX: 1 }} value='1'>
                    {recordings &&
                    <RecordingsDistribute
                      close={props.close}
                      project={props.project}
                      projectId={props.project.id ?? ''}
                      recordings={recordings}
                      updateRecordings={updateRecordingsSubtle}
                      updateRecordingsSubtle={updateRecordingsSubtle}
                    />}
                  </TabPanel>
                  <TabPanel sx={{ width: 1, height: 1, paddingX: 1 }} value='2'>
                    <TerritoriesDistribute
                      setStores={setStores}
                      setTerritories={setTerritories}
                      stores={stores}
                      territories={territories}
                    />
                  </TabPanel>
                  <TabPanel sx={{ width: 1, height: 1, paddingX: 1 }} value='3'>
                    <StoresDistribute
                      beatportGenre={beatportGenre}
                      setBeatportGenre={setBeatportGenre}
                      setStores={setStores}
                      stores={stores}
                      territories={territories}
                    />
                  </TabPanel>
                  <TabPanel sx={{ width: 1, height: 1, paddingX: 1 }} value='4'>
                    {recordings &&
                    <AddOnsDistribute
                      appleDigitalAddOn={appleDigitalAddOn}
                      appleEmail={appleEmail}
                      appleMotionAddOn={appleMotionAddOn}
                      atmosAddOn={atmosAddOn}
                      atmosFiles={atmosFiles}
                      checkPubCover={checkPubCover}
                      checkPubSplits={checkPubLocked && checkPubOwnership}
                      folderLink={folderLink}
                      neighboringAddOn={neighboringAddOn}
                      presaveAddOn={presaveAddOn}
                      project={projectFields}
                      pubDisabled={!checkPub}
                      publishingAddOn={publishingAddOn}
                      recordings={recordings}
                      setAppleDigitalAddOn={setAppleDigitalAddOn}
                      setAppleEmail={setAppleEmail}
                      setAppleMotionAddOn={setAppleMotionAddOn}
                      setAtmosAddOn={setAtmosAddOn}
                      setAtmosFiles={setAtmosFiles}
                      setFolderLink={setFolderLink}
                      setNeighboringAddOn={setNeighboringAddOn}
                      setPresaveAddOn={setPresaveAddOn}
                      setPublishingAddOn={setPublishingAddOn}
                    />}
                  </TabPanel>
                  <TabPanel sx={{ width: 1, height: 1, paddingX: 1 }} value='5'>
                    <Stack direction='row' height={1} spacing={2} width={1}>
                      <Stack direction='row' height={1} overflow='visible' position='relative' width={1}>
                        <PreSaveAddOn
                          artworkPreviewUrl={props.project.artworkPreviewUrl}
                          bgColor={bgColor}
                          buttonShape={buttonShape}
                          buttonStyle={presaveButtonStyles}
                          colors={colors}
                          description={presaveDescription}
                          domain={smartlinkDomain}
                          missingSubdomain={missingSubdomain}
                          missingUrl={missingUrl}
                          palette={presaveTheme}
                          presaveActive={presaveActive}
                          presaveDefaultSocialIcons={presaveDefaultSocialIcons}
                          presaveFollowArtists={presaveFollowArtists}
                          presaveFont={presaveFont}
                          setBgColor={setBgColor}
                          setButtonShape={setButtonShape}
                          setButtonStyle={setPresaveButtonStyles}
                          setDescription={setPresaveDescription}
                          setDomain={setSmartLinkDomain}
                          setPalette={setPresaveTheme}
                          setPresaveActive={setPresaveActive}
                          setPresaveDefaultSocialIcons={setPresaveDefaultSocialIcons}
                          setPresaveFollowArtists={setPresaveFollowArtists}
                          setPresaveFont={setPresaveFont}
                          setShowAllReleases={setShowAllReleases}
                          setSocialList={setPresaveSocialIcons}
                          setSubdomain={setSmartLinkSubdomain}
                          setSubscribe={setPresaveSubscribe}
                          setTheme={setPresaveTemplate}
                          setUrl={setSmartLinkUrl}
                          showAllReleases={showAllReleases}
                          socialList={presaveSocialIcons}
                          subdomain={smartlinkSubdomain}
                          subscribe={presaveSubscribe}
                          theme={presaveTemplate}
                          uniqueLinkBoolean={uniqueLinkBoolean}
                          url={smartlinkUrl}
                        />
                      </Stack>
                      {!matchesLarge &&
                      <Stack
                        alignItems='flex-start'
                        direction='row'
                        flexShrink={0}
                        height='100%'
                        position='relative'
                        right={0}
                        width={340}
                      >
                        <Stack
                          height={665}
                          position='fixed'
                          width={340}
                        >
                          <ReactDevicePreview
                            device='iphonex'
                            scale='0.8'
                          >
                            {presaveTemplate === 'Subtle' &&
                            <Stack
                              sx={{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                background: `url(${props.project.artworkPreviewUrl})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                filter: 'blur(60px)',
                                zIndex: 2,
                                transform: 'scale(1.5)',
                                opacity: 0.6,
                                minWidth: 1920,
                                left: 'calc(50% - 960px)',
                                backgroundPositionX: 'center',
                                backgroundPositionY: 'center',
                                backgroundRepeat: 'no-repeat',
                              }}
                            />}
                            <Stack
                              alignItems='center'
                              flexGrow={0}
                              height={750}
                              position='relative'
                              sx={{
                                background: presaveTemplate === 'Subtle'
                                  ? activeTheme.default.palette.background.default
                                  : bgColor,
                                overflowY: 'auto',
                                overflowX: 'hidden',
                                '::-webkit-scrollbar': {
                                  display: 'none',
                                },
                              }}
                              top={0}
                              width={1}
                            >
                              <EditLinkRightTab
                                artworkPreview={props.project.artworkPreviewUrl}
                                organisation={organisation}
                                smartLinkData={new MarketingLink({
                                  type: 'Presave',
                                  title: props.project.title,
                                  artists: props.project.primaryArtists.map(item => item.name),
                                  foreverPresave: presaveSubscribe,
                                  useOrgDefaultSocialIcons: presaveDefaultSocialIcons,
                                  releaseDate: props.project.releaseDate,
                                  palette: presaveTheme,
                                  theme: presaveTemplate,
                                  colorStyle: presaveButtonStyles,
                                  descriptionText: presaveDescription,
                                  fontStyle: presaveFont,
                                  buttonStyle: buttonShape,
                                  domain: smartlinkDomain,
                                })}
                              />
                            </Stack>
                          </ReactDevicePreview>
                        </Stack>
                      </Stack>}
                    </Stack>
                  </TabPanel>
                  {recordings &&
                  <TabPanel sx={{ width: 1, height: 1, paddingX: 1 }} value='6'>
                    <PublishingAddOnTab
                      setWriterInfo={setWriterInfo}
                      writerInfo={writerInfo}
                    />
                  </TabPanel>}
                  <TabPanel sx={{ width: 1, height: 1, paddingX: 1 }} value='7'>
                    {recordings &&
                    <SubmitDistribute
                      errorHandling={errorHandling}
                      errorMessage={error}
                      loading={loadingSubmit}
                      project={projectFields}
                      recordingTitlesStartWithNumbers={submitError}
                      recordings={recordings}
                      setProject={props.setProject}
                      stores={stores}
                      territories={territories}
                    />}
                  </TabPanel>
                  <TabPanel sx={{ width: 1, height: 1, paddingX: 1 }} value='8'>
                    <CompleteDistribute
                      close={props.close}
                      project={props.project}
                    />
                  </TabPanel>
                </Stack>
              </Stack>
            </TabContext>
            {currentTab !== 8 &&
            <AppBar
              position='fixed'
              sx={{
                top: 'auto',
                bottom: 0,
                background: theme => theme.palette.background.default,
              }}
            >
              <Divider sx={{ width: 1 }} />
              <Toolbar
                sx={{
                  justifyContent: 'flex-end',
                  paddingX: 1,
                  paddingTop: 1,
                  paddingBottom: 1,
                }}
              >
                <Stack
                  direction={matches ? 'column' : 'row'}
                  spacing={1}
                  width={1}
                >
                  <Stack direction='row' justifyContent='flex-end' spacing={1} width={1}>
                    <Button
                      disabled={currentTab === 1 || loadingSubmit || error.length > 0}
                      onClick={() => {
                        if (currentTab === 7 && !publishingAddOn) {
                          if (!presaveAddOn) {
                            setCurrentTab(currentTab - 3)
                          } else {
                            setCurrentTab(currentTab - 2)
                          }
                        } else if (currentTab === 6 && !presaveAddOn) {
                          setCurrentTab(currentTab - 2)
                        } else {
                          setCurrentTab(currentTab - 1)
                        }
                      }}
                      startIcon={<ArrowBackIosNewRoundedIcon />}
                      variant='subtle'
                    >
                      Previous
                    </Button>

                    <Button
                      disabled={currentTab === 7 || loadingSubmit || error.length > 0}
                      endIcon={<ArrowForwardIosRoundedIcon />}
                      onClick={() => {
                        if (currentTab === 4 && !presaveAddOn) {
                          if (!publishingAddOn) {
                            setCurrentTab(currentTab + 3)
                          } else {
                            setCurrentTab(currentTab + 2)
                          }
                        } else if (currentTab === 5 && !publishingAddOn) {
                          setCurrentTab(currentTab + 2)
                        } else {
                          setCurrentTab(currentTab + 1)
                        }
                      }}
                    >
                      Continue
                    </Button>
                    <LoadingButton
                      disabled={errorHandling ||
                      currentTab !== 7 ||
                      error.length > 0 ||
                      !currentFirebaseUser.emailVerified ||
                      (presaveAddOn && !uniqueLinkBoolean)}
                      endIcon={<PublishRoundedIcon />}
                      loading={loadingSubmit}
                      onClick={() => setTermsModal(true)}
                      variant='contained'
                    >
                      Distribute
                    </LoadingButton>
                  </Stack>
                </Stack>
              </Toolbar>
            </AppBar>}
          </Stack>
        </Slide>
      </Dialog>
      {matches && !previewModal && currentTab === 5 &&
      <Fab
        color='default'
        onClick={() => setPreviewModal(true)}
        rounded
        sx={{
          position: 'fixed',
          bottom: 80,
          zIndex: 9998,
          marginX: 'auto',
          width: 'fit-content',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
        variant='extended'
      >
        <VisibilityRoundedIcon sx={{ mr: 1 }} />
        Preview
      </Fab>}
      {currentOrganisation?.acceptedRules
        ? <DistributionTermsModal
          agreeTerms={agreeTerms}
          close={() => setTermsModal(false)}
          distribute={distribute}
          loadingSubmit={loadingSubmit}
          open={termsModal}
          publishing={publishingAddOn}
          setAgreeTerms={setAgreeTerms}
        />
        : <DistributionRulesModal
          close={() => setTermsModal(false)}
          open={termsModal}
        />}
    </>
  )
}

export default DistributeModal
