/* eslint-disable react/no-danger */
/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/cognitive-complexity */
import { Box, Divider, Paper, Stack, useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { getEmbeddedItem, getTaskboard, getTaskboardGroups, getWorkspaces } from 'src/api/taskboards'
import NotesClient from 'src/components/pages/Notes/NotesClient'
import PageNotFound from 'src/components/pages/PageNotFound'
import TaskMenu from 'src/components/pages/Task/TaskMenu/TaskMenu'
import TaskBoardLoading from 'src/components/pages/Task/TaskViews/TaskboardLoading'
import TaskBoardView from 'src/components/pages/Task/TaskViews/TaskboardView'
import FailedIframeView from 'src/components/pages/Task/TaskViews/TaskViewComponents/FailIframe'
import MyCalendar from 'src/components/pages/Task/TaskViews/TaskViewTypes/MyCalendar'
import MyTasks from 'src/components/pages/Task/TaskViews/TaskViewTypes/MyTasks'
import TaskboardViewEmpty from 'src/components/pages/Task/TaskViews/TaskViewTypes/TaskboardViewEmpty'
import TaskboardTour from 'src/components/pages/Tour/TaskboardTour'
import { useAuth } from 'src/components/providers/AuthProvider'
import { TasksboardProvider } from 'src/components/providers/TasksboardProvider'
import { useWorkspace } from 'src/components/providers/WorkspaceProvider'
import type { EmbedItemWorkspace, TaskBoard, TaskboardGroup, Workspace } from 'src/models/Taskboard'
import SPACING from 'src/styles/spacing'

const Taskboard = () => {
  const { currentOrganisation, refreshCurrentOrganisation, updateCurrentOrganisation } = useAuth()
  const theme = useTheme()
  const { workspaceUpdates } = useWorkspace()
  const matches = useMediaQuery(theme.breakpoints.down('md'))
  const { taskboardId } = useParams<{ taskboardId?: string }>()
  const [loading, setLoading] = useState(false)
  const [activeTaskboard, setActiveTaskBoard] = useState<TaskBoard>()
  const [boardGroups, setBoardGroups] = useState<TaskboardGroup[]>()
  const [error, setError] = useState(false)
  const [firstDone, setFirstDone] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [loadingTour, setLoadingTour] = useState(false)
  const [activeEmbed, setActiveEmbed] = useState<EmbedItemWorkspace>()

  const [workspaces, setWorkspaces] = useState<Workspace[]>([])
  const [activeWorkspace, setActiveWorkspace] = useState<Workspace>()

  const location = useLocation()
  const isPageDocument = location.pathname.includes('doc/')
  const isPageEmbed = location.pathname.includes('embed/')

  const [setupTourOpen, setSetupTourOpen] = useState(currentOrganisation?.tutorialTasks)

  useEffect(() => {
    refreshBoards()
  }, [workspaceUpdates])

  useEffect(() => {
    refreshBoards()
    setActiveTaskBoard(undefined)
  }, [currentOrganisation?.id, activeWorkspace?.id])

  const refreshBoards = () => {
    void getTaskboardGroups(activeWorkspace?.id)
      .then(setBoardGroups)
    void getWorkspaces()
      .then(setWorkspaces)
  }

  const getBoardStart = async () => {
    if (taskboardId &&
       taskboardId !== 'releaseschedule' &&
       taskboardId !== 'mytasks' &&
       taskboardId !== 'mycalendar' &&
       !isPageDocument &&
       !isPageEmbed) {
      setLoading(true)
      await getTaskboard(taskboardId)
        .then(data => {
          setActiveTaskBoard(data)
          setError(false)
          setLoading(false)
          setFirstDone(true)
        })
        .catch(() => setError(true))
    }
  }

  useEffect(() => {
    if (isPageEmbed && taskboardId) {
      setLoading(true)
      void getEmbeddedItem(taskboardId)
        .then(setActiveEmbed)
        .finally(() => setLoading(false))
    } else {
      setActiveEmbed(undefined)
    }
  }, [isPageEmbed, taskboardId, currentOrganisation?.id])

  useEffect(() => {
    void getBoardStart()
  }, [taskboardId, currentOrganisation?.id])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-boolean-literal-compare
    if (firstDone === true &&
      taskboardId &&
      taskboardId !== 'releaseschedule' &&
      taskboardId !== 'mytasks' &&
      taskboardId !== 'mycalendar' &&
      !isPageDocument &&
      !isPageEmbed) {
      getTaskBoardMethod()
    }
  }, [refresh])

  const getTaskBoardMethod = () => {
    if (taskboardId && !isPageDocument && !isPageEmbed) {
      void getTaskboard(taskboardId)
        .then(data => {
          setActiveTaskBoard(data)
          setError(false)
        })
        .catch(() => setError(true))
    }
  }

  return (
    <>
      <TaskboardTour
        closeTour={async () => {
          setLoadingTour(true)
          await updateCurrentOrganisation({ ...currentOrganisation, tutorialTasks: false })
            .then(() => setSetupTourOpen(false))
            .then(refreshCurrentOrganisation)
            .finally(() => {
              setLoadingTour(false)
              setSetupTourOpen(false)
            })
        }}
        finalEvent={async () => {
          setLoadingTour(true)
          await updateCurrentOrganisation({ ...currentOrganisation, tutorialTasks: false })
            .then(() => setSetupTourOpen(false))
            .then(refreshCurrentOrganisation)
            .finally(() => {
              setLoadingTour(false)
              setSetupTourOpen(false)
            })
        }}
        loading={loadingTour}
        open={setupTourOpen ?? false}
      />
      <Stack
        sx={{
          paddingBottom: SPACING.RESPONSIVE_CONTOUR,
          paddingRight: SPACING.RESPONSIVE_CONTOUR,
          height: 1,
          width: 1,
        }}
      >
        <Paper
          elevation={0}
          sx={{
            width: 1,
            height: 1,
            overflow: 'hidden',
            borderRadius: {
              xl: 1,
              lg: 1,
              md: 1,
              sm: 0,
              xs: 0,
            },
          }}
        >
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            width: 1,
            height: 1,
          }}
          >
            {(!matches || !taskboardId) &&
            <TaskMenu
              activeWorkspace={activeWorkspace}
              boardGroups={boardGroups}
              refreshBoards={refreshBoards}
              setActiveWorkspace={setActiveWorkspace}
              workspaces={workspaces}
            />}

            {!matches &&
            <Divider flexItem orientation='vertical' />}

            {taskboardId === 'mytasks'
              ? <MyTasks />
              : taskboardId === 'mycalendar'
                ? <MyCalendar />
                : isPageDocument && taskboardId && !loading
                  ? <Stack height={1} overflow='auto' width={1}>
                    {isPageDocument && taskboardId
                      ? <NotesClient
                        key={taskboardId}
                        slug={taskboardId}
                      />
                      : null}
                  </Stack>
                  : isPageEmbed && taskboardId
                    ? loading
                      ? <Stack />
                      : <Stack height={1} overflow='auto' width={1}>
                        {isPageEmbed &&
                        taskboardId &&
                        activeEmbed &&
                          activeEmbed.content.startsWith('<iframe')
                          ? <div
                            className='projectIframe'
                            dangerouslySetInnerHTML={{ __html: activeEmbed.content }}
                            style={{
                              width: '100%',
                              height: '100%',
                            }}
                          />
                          : activeEmbed?.content.startsWith('https://')
                            ? <div
                              className='projectIframe'
                              dangerouslySetInnerHTML={{
                                __html:
                `<iframe src=${activeEmbed?.content}></iframe>`,
                              }}
                              style={{
                                width: '100%',
                                height: '100%',
                              }}
                            />
                            : <FailedIframeView />}
                      </Stack>
                    : !error
                      ? activeTaskboard
                        ? loading
                          ? <TaskBoardLoading />
                          : taskboardId &&
                            <TasksboardProvider taskboardId={taskboardId}>
                              <TaskBoardView
                                firstDone={firstDone}
                                getTaskBoardMethod={getTaskBoardMethod}
                                refreshBoards={refreshBoards}
                                setRefresh={setRefresh}
                                taskboard={activeTaskboard}
                              />
                            </TasksboardProvider>
                        : !matches
                          ? <TaskboardViewEmpty />
                          : null
                      : !matches
                        ? <Stack
                          alignItems='flex-start'
                          direction='row'
                          height={1}
                          width={1}
                        >
                          <PageNotFound />
                        </Stack>
                        : null}
          </Box>
        </Paper>
      </Stack>
    </>
  )
}
export default Taskboard
