/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable no-duplicate-imports */
/* eslint-disable react/no-unstable-nested-components */
import { useTheme } from '@mui/material'
import type { ReactourStep, ReactourStepContentArgs } from 'reactour'
import Tour from 'reactour'

import TourStep from 'src/components/pages/Tour/TourComponents/TourStep'
import Image1 from 'src/images/Onboarding/Project/project-1.webp'
import Image3 from 'src/images/Onboarding/Project/project-2.webp'
import Image2 from 'src/images/Onboarding/Project/project-3.webp'

type Props = {
  open: boolean
  closeTour: () => void
  finalEvent: () => void
  loading: boolean
}

const ProjectTour = (props: Props) => {
  const theme = useTheme()

  const steps: ReactourStep[] = [
    {
      content: ({ close, goTo, step }: ReactourStepContentArgs) =>
        <TourStep
          close={close}
          description='Releases include everything you need to release music
          successfully: Plan, distribute, monetize, and promote, all from Releese.'
          final={false}
          finalAction={props.finalEvent}
          finalText='Got it'
          first
          goTo={goTo}
          item={<img
            alt='Dashboard'
            height='1562px'
            loading='lazy'
            src={Image1}
            style={{
              marginBottom: '-16px',
              width: '100%',
              height: 'auto',
              transform: 'translateZ(0)',
            }}
            width='2083px'
          />}
          loading={props.loading}
          step={step}
          title='Keep things organized'
        />
      ,
      position: 'right',
      selector: '#a',
      style: {
        width: 500,
        maxWidth: 500,
        backgroundColor: 'transparent',
        padding: 0,
      },
    },
    {
      content: ({ close, goTo, step }: ReactourStepContentArgs) =>
        <TourStep
          close={close}
          description='Share your files, metadata, analytics, and split royalties with as many people as you need for
          free.'
          final={false}
          finalAction={props.finalEvent}
          finalText='Got it'
          goTo={goTo}
          item={<img
            alt='Dashboard'
            height='1562px'
            loading='lazy'
            src={Image2}
            style={{
              marginBottom: '-16px',
              width: '100%',
              height: 'auto',
              transform: 'translateZ(0)',
            }}
            width='2083px'
          />}
          loading={props.loading}
          step={step}
          title='Work with collaborators'
        />
      ,
      position: 'right',
      selector: '#a',
      style: {
        width: 500,
        maxWidth: 500,
        backgroundColor: 'transparent',
        padding: 0,
      },
    },
    {
      content: ({ close, goTo, step }: ReactourStepContentArgs) =>
        <TourStep
          close={close}
          description='Distribute releases to all major stores, write and pitch your project to editors with the help
          of AI, and automatically create Instagram-worthy stories in a few clicks.'
          final
          finalAction={props.finalEvent}
          finalText='Got it'
          goTo={goTo}
          item={<img
            alt='Dashboard'
            height='1562px'
            loading='lazy'
            src={Image3}
            style={{
              marginBottom: '-16px',
              width: '100%',
              height: 'auto',
              transform: 'translateZ(0)',
            }}
            width='2083px'
          />}
          loading={props.loading}
          step={step}
          title='Streamline your release process'
        />
      ,
      position: 'right',
      selector: '#a',
      style: {
        width: 500,
        maxWidth: 500,
        backgroundColor: 'transparent',
        padding: 0,
      },
    },
  ]

  return (
    <Tour
      accentColor={theme.palette.primary.main}
      closeWithMask={false}
      disableDotsNavigation
      disableFocusLock
      disableKeyboardNavigation
      isOpen={props.open}
      onRequestClose={props.closeTour}
      rounded={16}
      showButtons={false}
      showCloseButton={false}
      showNavigation={false}
      showNavigationNumber={false}
      showNumber={false}
      steps={steps}
    />
  )
}

export default ProjectTour
