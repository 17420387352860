import { Dialog, useMediaQuery, useTheme } from '@mui/material'

import NewProjectModalComponent from 'src/components/pages/Projects/EditProject/NewProjectModalComponent'
import { DropzoneProvider } from 'src/components/pages/Projects/FullscreenDropzone'

type Props = {
  close: () => void
  open: boolean
  date?: Date
}

const NewProjectModal = (props: Props) => {
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='xs'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
      }}
    >
      <DropzoneProvider>
        <NewProjectModalComponent
          close={props.close}
          date={props.date}
        />
      </DropzoneProvider>
    </Dialog>
  )
}

export default NewProjectModal
