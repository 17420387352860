/* eslint-disable max-len */
/* eslint-disable id-length */
/* eslint-disable no-multi-str */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import PaidRoundedIcon from '@mui/icons-material/PaidRounded'
import { Card, Stack, Typography } from '@mui/material'

import { convertMonthToString } from 'src/components/pages/Earnings/EarningsDistribution/Overview/EarningsOverviewNewChart'
import type { AnalyticsMonthlyChart } from 'src/models/Analytics'

type Props = {
  netIncome: AnalyticsMonthlyChart[]
  grossData: AnalyticsMonthlyChart[]
  selectedRecording: string
  isOwner: boolean
  periods: string[]
}

const MyEarningsDistributionLifetime = (props: Props) => {
  const arrayGross = props.grossData.filter(item => props.periods.length === 0 || props.periods.includes(item._id))
    .map(item => ({
      x: `${item._id.toUpperCase()}`,
      y: item.amount,
    })).sort((a, b) => Number(new Date(a.x)) - Number(new Date(b.x)))

  const arrayNet = props.netIncome
    .map(item => {
      const dateItem = new Date(item._id)
      const newDate = new Date(dateItem.setMonth(dateItem.getMonth() - 2))
      const stringItem =
        `${(newDate.getMonth() + 1).toString().padStart(2, '0')}-${convertMonthToString((newDate.getMonth() + 1).toString())}-${newDate.getFullYear()}`
      const amountItem = arrayGross.find(arrayItem => arrayItem.x === stringItem)?.y ?? 0

      return {
        x: stringItem,
        y: props.isOwner
          ? amountItem - Number(item.amount)
          : item.amount,
      }
    })
    .sort((a, b) => Number(new Date(a.x)) - Number(new Date(b.x)))

  const arrayTotal = arrayGross.map(item => {
    const amountItem = props.isOwner
      ? arrayNet.find(arrayItem => arrayItem.x === item.x)?.y ?? item.y
      : arrayNet.find(arrayItem => arrayItem.x === item.x)?.y ?? 0
    return {
      x: item.x,
      y: amountItem,
    }
  })

  const totalEarnings = arrayTotal
    .filter(item => props.periods.length === 0 || props.periods.includes(item.x))
    .map(item => item.y)
    .reduce((partialSum, a) => partialSum + a, 0) ?? 0

  return (
    <Stack height={1} width={1}>
      <Card
        elevation={0}
        sx={{
          height: 1,
          background: theme => theme.palette.background.elevatedCard,
        }}
      >
        <Stack height={1} justifyContent='space-between' padding={2} spacing={3}>
          <Stack direction='row' spacing={2} width={1}>
            <Stack spacing={2} width={1}>
              <Typography variant='body1'>
                Net income
              </Typography>
              <Typography variant='h3'>
                {new Intl.NumberFormat('en-US',
                  { style: 'currency', currency: 'USD' }).format(totalEarnings)}
              </Typography>
            </Stack>
            <Stack spacing={2}>
              <PaidRoundedIcon color='success' sx={{ fontSize: 48 }} />
            </Stack>
          </Stack>
          <Typography variant='textLabel'>
            {props.selectedRecording.length > 0
              ? 'Your share of distribution earnings since the release of this recording'
              : 'Your share of distribution earnings'}
          </Typography>
        </Stack>
      </Card>
    </Stack>
  )
}

export default MyEarningsDistributionLifetime

